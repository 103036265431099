import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import dayjs from 'dayjs';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Divider,
    Button
} from '@mui/material';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FileBrowser, FileList, ChonkyActions, } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import CloseIcon from '@mui/icons-material/Close';

// api calling download pdf file
import TemplateFolderApiCall from '../ReportApiCall/TemplateFolderApiCall';
import DownloadTemplateApiCall from '../ReportApiCall/DownloadTemplateApiCall';




export default function GeneratedReports(props) {

    const { openReportDialog, setOpenReportDialog } = props;


    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    const [currentFiles, setCurrentFiles] = useState([]);
    const [directoryStack, setDirectoryStack] = useState([]);
    const [dirName, setDirName] = useState("");
    const [fileName, setFileName] = useState("")


    const currentDate = dayjs();
    const formattedDate = currentDate.format('YYYY-MM-DD');
    // Formttedated
    useEffect(() => {
        TemplateFolderApiCall(formattedDate, setCurrentFiles);
        // Disable Chonky debug logs
        localStorage.debug = 'chonky:*,-chonky:debug';
        setOpenReportDialog(true);

    }, []);


    const handleFileAction = (action) => {
        setOpenReportDialog(true);
        if (action.id === ChonkyActions.OpenFiles.id) {
            const file = action.payload.targetFile;
            if (file && file.isDir) {
                setDirectoryStack(stack => [...stack, currentFiles]);
                setCurrentFiles(file.children || []);
                setDirName(file.name);
            }
            else {

                const path = `${dirName}/${file.name}`;

                DownloadTemplateApiCall(path, file.name);
            }
        }
    };

    const handleBackClick = () => {
        if (directoryStack.length > 0) {
            const lastDirectory = directoryStack.pop();
            setCurrentFiles(lastDirectory);
        }
    };

    const handleOnClose = () => {
        setOpenReportDialog(false);
    }

    return (
        <>
            <Dialog open={openReportDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 1 }}>
                    Generated Reports
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ px: 5 }}>
                    <Box>

                        <div>
                            <div style={{ height: 10 }}>
                                {directoryStack.length > 0 && (
                                    <Grid container>
                                        <Grid item>
                                            <IconButton onClick={handleBackClick} color='primary' aria-label="go back">
                                                <ArrowBackIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={9} lg={9} sx={{ px: 1, py: "12px" }}>
                                            <Typography style={{ textAlign: 'left' }}>{dirName}</Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>

                            <Divider />
                            <div style={{ height: 500, paddingTop: "10px" }}>
                                <FileBrowser
                                    files={currentFiles}
                                    iconComponent={ChonkyIconFA}
                                    view={'grid'}
                                    onFileAction={handleFileAction}

                                >
                                    <FileList />
                                </FileBrowser>
                            </div>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}