import axios from 'axios';

// API CALL CODE HERE
const AddNewVendorApiCall = async (vendorName, vendorAddress, vendorContact, helpDeskEmail, escalationLevel1Mail, escalationLevel2Mail, vendorCity, promisedUptime, resolutionTime, setAddVendorStatus, setAddVendorMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "create_new_vendor_for_msp.php" : "create_new_vendor.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "vendor_data": {
      "vendor_name": vendorName,
      "vendor_address": vendorAddress,
      "vendor_contact": vendorContact,
      "helpdesk_email": helpDeskEmail,
      "escalation_level1_mail": escalationLevel1Mail,
      "escalation_level2_mail": escalationLevel2Mail,
      "vendor_city": vendorCity,
      "promised_uptime": promisedUptime,
      "resolution_time": resolutionTime
    }
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1,
    "vendor_data": {
      "vendor_name": vendorName,
      "vendor_address": vendorAddress,
      "vendor_contact": vendorContact,
      "helpdesk_email": helpDeskEmail,
      "escalation_level1_mail": escalationLevel1Mail,
      "escalation_level2_mail": escalationLevel2Mail,
      "vendor_city": vendorCity,
      "promised_uptime": promisedUptime,
      "resolution_time": resolutionTime
    }
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setAddVendorStatus(1);
      setAddVendorMessage(response.data.message)
    }
    else {
      setAddVendorStatus(-1);
      setAddVendorMessage(response.data.message)
    }
  }).catch(() => {
    setAddVendorStatus(-1);
  })
}

export default AddNewVendorApiCall;
