import axios from 'axios';

const GetSiteGroupDetailsApiCall = async (setGroups) => {

    const path = localStorage.getItem("msp_status") === "1" ? "get_site_groups_details_for_msp.php" : "get_site_groups_details.php";
    const url = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id")
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id")
    });
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    return axios.post(url, payload).then((response) => {
        if (response.data.code === 1) {
            const groupNames = response.data.groups_data.map(group => group.group_name);
            setGroups(response.data.groups_data);

        }
        else {
            setGroups([]);
        }
    }).catch(() => {

        setGroups([]);
    });
}

export default GetSiteGroupDetailsApiCall