import axios from 'axios';

export const UpdateImAlertSettingsAPICall = async (wConfig, teamsConfig, setErrorCode, setUpdateStatus, setErrorMessage, setSeverity) => {
  const path = localStorage.getItem("msp_status") === "1" ? "push_im_alert_settings_for_msp.php" : "push_im_alert_settings.php";
  const alerMailtBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "whats_app_config": wConfig,
    "teams_config": teamsConfig
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "whats_app_config": wConfig,
    "teams_config": teamsConfig,
    "msp_status": 1,
  });

  // console.log(regPayload);
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(alerMailtBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setErrorCode(1)
      setSeverity("success")
    }
    else if (response.data.code === -1) {
      setErrorCode(-1)
      setSeverity("warning")
    } else {
      setErrorCode(-2)
      setSeverity("error")
    }
    setUpdateStatus(true)
    setErrorMessage(response.data.message)
  }).catch((e)=>{
    console.log(e);
    setUpdateStatus(true)
    setErrorMessage("Unknow error")
    setSeverity("error")
  })
}


