import axios from 'axios';

// API CALL CODE HERE

const BestVendorsApiCall = async (buState, setBestVendors, setWorstVendors, monthYear, generateVendorReportCSV) => {

    const path = localStorage.getItem("msp_status") === "1" ? "get_best_vendor_report_for_msp.php" : "get_best_vendor_report.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "bu_id": buState,
        "month": monthYear
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "month": monthYear,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
   
    return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setBestVendors(response.data.vendor_data);
            setWorstVendors([...response.data.vendor_data].reverse());
            generateVendorReportCSV(response.data.vendor_data);
        }
        else {
            setBestVendors([]);
            setWorstVendors([]);
        }
    })
}

export default BestVendorsApiCall;