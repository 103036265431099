import axios from 'axios';
import dayjs from 'dayjs';

// API CALL CODE HERE
export const PushSingleISPInfo = async (link, circuitId, publicIp, internalIp, defaultGateway, provisionedSpeed, price, renewalDate, locationId, location, city, state, country, managerName, managerEmail, selectedVendorId, vendorName, vendorEmail, promiseUptime, resolutionTime, warning, critical, setAddLinkStatus, setAddLinkMessage) => {

    if (!renewalDate) {
        renewalDate = new Date(); // Set renewalDate to current date
    }

    const path = localStorage.getItem("msp_status") === "1" ? "push_single_link_details_for_msp.php" : "push_single_link_details.php";
    const baseUrlToPushISPInfo = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "isp_data": {
            "cust_id": localStorage.getItem("cust_id"),
            "circuit_id": circuitId,
            "location_id": locationId,
            "location_name": location,
            "public_ip": publicIp,
            "internal_ip": internalIp,
            "vendor_id": selectedVendorId,
            "vendor_name": vendorName,
            "vendor_email": vendorEmail,
            "default_gateway": defaultGateway,
            "promised_uptime": promiseUptime,
            "resolution_time": resolutionTime,
            "link_type": link,
            "provisioned_speed": provisionedSpeed,
            "price": price,
            "city": city,
            "state": state,
            "country": country,
            "manager_name": managerName,
            "manager_email": managerEmail,
            "renewal_date": dayjs(renewalDate).format('YYYY-MM-DD'),
            "warning_th_value": warning,
            "critical_th_value": critical
        }
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "isp_data": {
            "cust_id": localStorage.getItem("reg_cust_id"),
            "circuit_id": circuitId,
            "location_id": locationId,
            "location_name": location,
            "public_ip": publicIp,
            "internal_ip": internalIp,
            "vendor_id": selectedVendorId,
            "vendor_name": vendorName,
            "vendor_email": vendorEmail,
            "default_gateway": defaultGateway,
            "promised_uptime": promiseUptime,
            "resolution_time": resolutionTime,
            "link_type": link,
            "provisioned_speed": provisionedSpeed,
            "price": price,
            "city": city,
            "state": state,
            "country": country,
            "manager_name": managerName,
            "manager_email": managerEmail,
            "renewal_date": dayjs(renewalDate).format('YYYY-MM-DD'),
            "warning_th_value": warning,
            "critical_th_value": critical
        }
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrlToPushISPInfo, payload).then((response) => {
        if (response.data.code === 1) {
            setAddLinkStatus(1);
            setAddLinkMessage(response.data.message)
        }
        else if (response.data.code === 2) {
            setAddLinkStatus(2);
            setAddLinkMessage(response.data.message)
        }
        else if (response.data.code === -1) {
            setAddLinkStatus(-1);
            setAddLinkMessage(response.data.message)

        }
    })
}

export const GetSingleISPInfo = async (id, setIspWanId, setCircuitId, setVendorName, setVendorEmail, setDefaulGateway, setPublicIp, setInternalIp, setPromisedUptime, setResolutionTime, setLink, setProvisionedSpeed, setCity, setPrice, setVendorId, setValue, setRenewalDate, setWarning, setCritical) => {

    const path = localStorage.getItem("msp_status") === "1" ? "get_single_isp_info_for_msp.php" : "get_single_isp_info.php";
    const baseUrlToGetSingleISPInfo = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "isp_wan_id": id
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "isp_wan_id": id
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrlToGetSingleISPInfo, payload).then((response) => {
        if (response.data.code === 1) {
            setIspWanId(response.data.isp_data.isp_wan_id);
            setCircuitId(response.data.isp_data.circuit_id);
            setVendorName(response.data.isp_data.vendor);
            setVendorEmail(response.data.isp_data.vendor_email);
            setDefaulGateway(response.data.isp_data.default_gateway);
            setPublicIp(response.data.isp_data.public_ip);
            setInternalIp(response.data.isp_data.internal_ip);
            setPromisedUptime(response.data.isp_data.promised_uptime);
            setResolutionTime(response.data.isp_data.resolution_time);
            setLink(response.data.isp_data.link_type);
            setProvisionedSpeed(response.data.isp_data.provisioned_speed === null ? " " : response.data.isp_data.provisioned_speed);
            setCity(response.data.isp_data.city);
            setPrice(response.data.isp_data.price);
            setVendorId(response.data.isp_data.vendor_id);
            setValue({ "location": response.data.isp_data.location });
            setRenewalDate(dayjs(new Date(response.data.isp_data.renewal_date)));
            setWarning(response.data.isp_data.util_warn_th);
            setCritical(response.data.isp_data.util_critical_th)


        }
    })
}

// API CALL UPDATE  CODE HERE
export const UpdateSingleISPInfo = async (ispWanId, publicIp, internalIp, circuitId, location, selectedVendorId, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, city, price, renewalDate, setUpdateStatus, setEditMessage) => {

    const path = localStorage.getItem("msp_status") === "1" ? "update_single_isp_wan_info_for_msp.php" : "update_single_isp_wan_info.php";
    const baseUrlToUpdateISPInfo = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "isp_data": {
            "cust_id": localStorage.getItem("cust_id"),
            "isp_wan_id": ispWanId,
            "circuit_id": circuitId,
            "location": location,
            "public_ip": publicIp,
            "internal_ip": internalIp,
            "vendor_id": selectedVendorId,
            "vendor_name": vendorName,
            "vendor_email": vendorEmail,
            "default_gateway": defaultGateway,
            "promised_uptime": promiseUptime,
            "resolution_time": resolutionTime,
            "link_type": link,
            "provisioned_speed": provisionedSpeed,
            "price": price,
            "city": city,
            "renewal_date": dayjs(renewalDate).format('YYYY-MM-DD')
        }
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "isp_data": {
            "cust_id": localStorage.getItem("cust_id"),
            "isp_wan_id": ispWanId,
            "circuit_id": circuitId,
            "location": location,
            "public_ip": publicIp,
            "internal_ip": internalIp,
            "vendor_id": selectedVendorId,
            "vendor_name": vendorName,
            "vendor_email": vendorEmail,
            "default_gateway": defaultGateway,
            "promised_uptime": promiseUptime,
            "resolution_time": resolutionTime,
            "link_type": link,
            "provisioned_speed": provisionedSpeed,
            "price": price,
            "city": city,
            "renewal_date": dayjs(renewalDate).format('YYYY-MM-DD')
        }
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrlToUpdateISPInfo, payload).then((response) => {
        if (response.data.code === 1) {
            setUpdateStatus(1);
            setEditMessage(response.data.message)
        }
        else {
            setUpdateStatus(-1);
            setEditMessage(response.data.message)

        }
        return response.data;
    })
}
