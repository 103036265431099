import axios from 'axios';

export const EdgeDeviceCustumTagApiCall = async (data, setEdCustumTagStatus,setEdCustumTagMessage) => {
    
    const path = "push_custom_tags.php"; 
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
        "data":
       { "entity": data.entity, 
        "tag_name": data.name,
        "is_mandatory": data.isMandatory,
        "default_value": data.defaultValue || null}
    });


     await axios.post(baseUrl, payload).then((response) =>{
        console.log("response.data",response.data)
        if (response.data.code === 1) {
            setEdCustumTagStatus(response.data.code);
            setEdCustumTagMessage(response.data.message);
        } else {
            setEdCustumTagStatus(response.data.code); 
            setEdCustumTagMessage(response.data.message);
        }
     })
    
};
    