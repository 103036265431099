import React, { useState, useEffect } from 'react'
import {
    Grid,
    TextField,
    Button,
    Alert,
    Typography,
    Divider,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import AddSingleLocationRestApi from './AddSingleLocationRestApi';
import GetUserDetailsApiCall from '../../ZoneComponents/Components/GetUserDetailsApiCall';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';

export default function AddSingleLocationComponent() {

    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();
    const { buName, setBuName } = useGlobalState();

    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    const [locationName, setLocationName] = useState("");
    const [businessHrStart, setBusinessHrStart] = useState("9");
    const [businessHrEnd, setBusinessHrEnd] = useState("18");
    const [remarks, setRemarks] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [status, setStatus] = useState(0);

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [escMail, setEscMail] = useState("");
    const [managerId, setManagerId] = useState(-1);
    const [disableManagerFields, setDisableManagerFields] = useState(false);

    const [managerName, setManagerName] = useState("");
    const [managerContact, setManagerContact] = useState("");
    const [managerEmail, setManagerEmail] = useState("");

    const [latency, setLatency] = useState("150");
    const [jitter, setJitter] = useState("30");
    const [packetLoss, setPacketLoss] = useState("0");
    const [threshold, setThreshold] = useState(0);

    const [esc1Name, setEsc1Name] = useState("");
    const [esc1Contact, setEsc1Contact] = useState("");
    const [esc1Email, setEsc1Email] = useState("");
    const [esc1Threshold, setEsc1Threshold] = useState(60);

    const [esc2Name, setEsc2Name] = useState("");
    const [esc2Contact, setEsc2Contact] = useState("");
    const [esc2Email, setEsc2Email] = useState("");
    const [esc2Threshold, setEsc2Threshold] = useState(120);

    const [esc3Name, setEsc3Name] = useState("");
    const [esc3Contact, setEsc3Contact] = useState("");
    const [esc3Email, setEsc3Email] = useState("");
    const [esc3Threshold, setEsc3Threshold] = useState(240);

    const [showEsc1Comps, setShowEsc1Comps] = useState(false);
    const [showEsc2Comps, setShowEsc2Comps] = useState(false);
    const [showEsc3Comps, setShowEsc3Comps] = useState(false);


    const [criticality, setCriticality] = useState(-1);
    const [criticalityHelperText, setCriticalityHelperText] = useState("");

    const [locationNameHelperText, setLocationNameHelperText] = useState("");
    const [cityHelperText, setCityHelperText] = useState("");
    const [stateHelperText, setStateHelperText] = useState("");
    const [countryHelperText, setCountryHelperText] = useState("");

    const [businessHrStartHelperText, setBusinessHrStartHelperText] = useState();
    const [businessHrEndHelperText, setBusinessHrEndHelperText] = useState();
    const [latencyHelperText, setLatencyHelperText] = useState("");
    const [jitterHelperText, setJitterHelperText] = useState("");
    const [packetLossHelperText, setPacketLossHelperText] = useState("");
    const [ManagerNameHelperText, setManagerNameHelperText] = useState("");
    const [ManagerContactHelperText, setManagerContactHelperText] = useState("");
    const [ManagerEmailHelperText, setManagerEmailHelperText] = useState("");
    const [AddSiteMessage, setAddSiteMessage] = useState("")
    const [error, setError] = useState(false);
    const [eSCName1HelperText, setESCName1HelperText] = useState("");
    const [eSCName2HelperText, setESCName2HelperText] = useState("");
    const [eSCName3HelperText, setESCName3HelperText] = useState("");

    const [eSContact1HelperText, setESContact1HelperText] = useState("");
    const [eSContact2HelperText, setESContact2HelperText] = useState("");
    const [eSContact3HelperText, setESContact3HelperText] = useState("");

    const [eSEmail1HelperText, setESEmail1HelperText] = useState("");
    const [eSEmail2HelperText, setESEmail2HelperText] = useState("");
    const [eSEmail3HelperText, setESEmail3HelperText] = useState("");

    const [eSThreshold1HelperText, setESThreshold1HelperText] = useState("");
    const [eSThreshold2HelperText, setESThreshold2HelperText] = useState("");
    const [eSThreshold3HelperText, setESThreshold3HelperText] = useState("");
    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
    const [openWarnDialog, setOpenWarnDialog] = useState(false);

    const buStatus = parseInt(localStorage.getItem("bu_status"), 10);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [warning, setWarning] = useState("80");
    const [warningHelperText, setWarningHelperText] = useState("")
    const [critical, setCritical] = useState("100");
    const [criticalHelperText, setCriticalHelperText] = useState("")

    const locationRegex = /^[a-zA-Z][a-zA-Z0-9\s,_.-]*[a-zA-Z\d]$/;
    const placeRegex = /^[a-zA-Z0-9\s]+$/;
    const businessHrRegex = /^(?:[0-9]|1[0-9]|2[0-4])$/;
    const latencyRegex = /^\d+(\.\d+)?$/;
    const packetLossRegex = /^(?:\d{1,2}(?:\.\d+)?|\d{1,2}|100(?:\.0+)?)$/;
    const thresholdRegex = /^[1-9]\d*$/;
    const managernameRegex = /^[a-zA-Z][a-zA-Z0-9(),-_\s]*[A-Za-z\d]$/;
    const managercontactRegex = /^[0-9]{10}$/;
    const manageremailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const numericPattern = /^(100(\.0{1,2})?|[0-9]?[0-9](\.[0-9]{1,2})?)$/;

    const _GetUserDetailsApiCall = async () => {
        GetUserDetailsApiCall(setUsers);
    }

    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        _GetUserDetailsApiCall();
    }, [globalState, buState])


    const onWarningClose = () => {
        navigate('/dashboard/add-single-location');
    }

    const handleOnCancel = () => {
        navigate('/dashboard/view-isp');
    }

    const isMandatoryFieldUpdated = (businessHrStart !== "") && (businessHrEnd !== "") && (latency !== "") && (jitter !== "")
        && (packetLoss !== "") && (city !== "") && (state !== "") && (country !== "") && (locationName !== "");

    const _AddSingleLocationRestApi = async () => {
        if (!locationName.match(locationRegex)) {
            setLocationNameHelperText("Location Should be Valid");
            return;
        }

        if (!businessHrStart.match(businessHrRegex)) {
            setBusinessHrStartHelperText("Provide hours between 0-24");
            return;
        }

        if (!businessHrEnd.match(businessHrRegex)) {
            setBusinessHrEndHelperText("Provide hours between 0-24");
            return;
        }


        if (!latency.match(latencyRegex)) {
            setBusinessHrEndHelperText("Enter Latency Correctly");
            return;
        }

        if (!jitter.match(latencyRegex)) {
            setJitterHelperText("Enter Jitter Correctly");
            return;
        }

        if (!packetLoss.match(packetLossRegex)) {
            setPacketLossHelperText("Enter Packet loss Correctly");
            return;
        }

        if (criticality === -1) {
            setError(true);
            setCriticalityHelperText("Select Criticality");
            return;
        }

        AddSingleLocationRestApi(locationName, businessHrStart, businessHrEnd, remarks, city, state, country, managerId, managerName, managerContact, managerEmail, esc1Name, esc1Email, esc1Contact, esc1Threshold, esc2Name, esc2Email, esc2Contact, esc2Threshold, esc3Name, esc3Email, esc3Contact, esc3Threshold, latency, jitter, packetLoss, criticality, warning, critical, setStatus, setAddSiteMessage, buState);
        setError(false);
        setCriticalityHelperText("");
    }
    const validateNumber = (value) => numericPattern.test(value);
    const handleOnSubmit = () => {

        if (localStorage.getItem("msp_status") === "1" && localStorage.getItem("cust_id") === localStorage.getItem("reg_cust_id")) {
            setOpenSnackBar(true);
        }
        else if (buStatus === 1 && buState !== -1) {
            setConfirmOpen(true);
        }
        else {
            _AddSingleLocationRestApi();
        }
        
        if (!validateNumber(warning)) {
            setWarningHelperText("Warning Threshold must be a valid number");
            return;
        }

        if (!validateNumber(critical)) {
            setCriticalHelperText("Critical Threshold must be a valid number");
            // return;
        }
    }

    const handleConfirm = () => {
        _AddSingleLocationRestApi();
        setConfirmOpen(false);
    }
    const handleCancel = () => {
        setConfirmOpen(false);
    }

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const onChangeTextField = (e) => {
        switch (e.target.id) {
            case "location_name":
                if (!e.target.value.match(locationRegex)) {
                    setLocationNameHelperText("Location Should be Valid");
                }
                else {
                    setLocationNameHelperText("");

                }
                setLocationName(e.target.value);
                break;

            case "city":
                if (!e.target.value.match(placeRegex)) {
                    setCityHelperText("City Should be Valid");
                }
                else {
                    setCityHelperText("");

                }
                setCity(e.target.value);
                break;

            case "state": if (!e.target.value.match(placeRegex)) {
                setStateHelperText("State Should be Valid");
            }
            else {
                setStateHelperText("");

            }
                setState(e.target.value);
                break;

            case "country": if (!e.target.value.match(placeRegex)) {
                setCountryHelperText("Country Should be Valid");
            }
            else {
                setCountryHelperText("");

            }
                setCountry(e.target.value);
                break;

            case "business_hr_start": if (!e.target.value.match(businessHrRegex)) {
                setBusinessHrStartHelperText("Provide hours between 0-24");
            }
            else {
                setBusinessHrStartHelperText("");

            }
                setBusinessHrStart(e.target.value);
                break;

            case "business_hr_end": if (!e.target.value.match(businessHrRegex)) {
                setBusinessHrEndHelperText("Provide hours between 0-24");
            }
            else {
                setBusinessHrEndHelperText("");

            }
                setBusinessHrEnd(e.target.value);
                break;

            case "manager_name":
                if (!e.target.value.match(managernameRegex)) {
                    setManagerNameHelperText("Manager Name Should be Valid");
                }
                else {
                    setManagerNameHelperText("");

                }
                setManagerName(e.target.value);
                break;

            case "esc1_name":
                if (!e.target.value.match(managernameRegex)) {
                    setESCName1HelperText("Escalation Name 1 Should be Valid");
                }
                else {
                    setESCName1HelperText("");

                }
                setEsc1Name(e.target.value);
                break;

            case "esc2_name":
                if (!e.target.value.match(managernameRegex)) {
                    setESCName2HelperText("Escalation Name 2 Should be Valid");
                }
                else {
                    setESCName2HelperText("");

                }
                setEsc2Name(e.target.value);
                break;
            case "esc3_name":
                if (!e.target.value.match(managernameRegex)) {
                    setESCName3HelperText("Escalation Name 3 Should be Valid");
                }
                else {
                    setESCName3HelperText("");

                }
                setEsc3Name(e.target.value);
                break;

            case "manager_contact":
                if (!e.target.value.match(managercontactRegex)) {
                    setManagerContactHelperText("Manager Contact Should be Valid");
                }
                else {
                    setManagerContactHelperText("");

                }
                setManagerContact(e.target.value);
                break;

            case "esc1_contact":
                if (!e.target.value.match(managercontactRegex)) {
                    setESContact1HelperText("Escalation Contact 1 Should be Valid");
                }
                else {
                    setESContact1HelperText("");

                }
                setEsc1Contact(e.target.value);
                break;

            case "esc2_contact":
                if (!e.target.value.match(managercontactRegex)) {
                    setESContact2HelperText("Escalation Contact 2 Should be Valid");
                }
                else {
                    setESContact2HelperText("");

                }
                setEsc2Contact(e.target.value);
                break;

            case "esc3_contact":
                if (!e.target.value.match(managercontactRegex)) {
                    setESContact3HelperText("Escalation Contact 3 Should be Valid");
                }
                else {
                    setESContact3HelperText("");

                }
                setEsc3Contact(e.target.value);
                break;

            case "manager_email":
                if (!e.target.value.match(manageremailRegex)) {
                    setManagerEmailHelperText("Manager Email Should be Valid");
                }
                else {
                    setManagerEmailHelperText("");

                }
                setManagerEmail(e.target.value);
                break;


            case "esc1_email":
                if (!e.target.value.match(manageremailRegex)) {
                    setESEmail1HelperText("Escalation Email 1 Should be Valid");
                }
                else {
                    setESEmail1HelperText("");

                }
                setEsc1Email(e.target.value);
                break;

            case "esc2_email":
                if (!e.target.value.match(manageremailRegex)) {
                    setESEmail2HelperText("Escalation Email 2 Should be Valid");
                }
                else {
                    setESEmail2HelperText("");

                }
                setEsc2Email(e.target.value);
                break;

            case "esc3_email":
                if (!e.target.value.match(manageremailRegex)) {
                    setESEmail3HelperText("Escalation Email 3 Should be Valid");
                }
                else {
                    setESEmail3HelperText("");

                }
                setEsc3Email(e.target.value);
                break;
            case "latency": if (!e.target.value.match(latencyRegex)) {
                setLatencyHelperText("Enter Latency Correctly");
            }
            else {
                setLatencyHelperText("");

            }
                setLatency(e.target.value);
                break;

            case "jitter": if (!e.target.value.match(latencyRegex)) {
                setJitterHelperText("Enter Jitter Correctly");
            }
            else {
                setJitterHelperText("");

            }
                setJitter(e.target.value);
                break;

            case "esc1_threshold": if (!e.target.value.match(thresholdRegex)) {
                setESThreshold1HelperText("Must be greater than Zero");
            }
            else {
                setESThreshold1HelperText("");

            }
                setEsc1Threshold(e.target.value);
                break;

            case "esc2_threshold": if (!e.target.value.match(thresholdRegex)) {
                setESThreshold2HelperText("Must be greater than Zero");
            }
            else {
                setESThreshold2HelperText("");

            }
                setEsc2Threshold(e.target.value);
                break;

            case "esc3_threshold": if (!e.target.value.match(thresholdRegex)) {
                setESThreshold3HelperText("Must be greater than Zero");
            }
            else {
                setESThreshold3HelperText("");

            }
                setEsc3Threshold(e.target.value);
                break;

            case "packet_loss": if (!e.target.value.match(packetLossRegex)) {
                setPacketLossHelperText("Enter Packet Loss Correctly");
            }
            else {
                setPacketLossHelperText("");

            }
                setPacketLoss(e.target.value);
                break;

            // default: break;
            default: setCriticality(e.target.value);
                setCriticalityHelperText("");
                setError(false);
                break;
        }

    }

    return (
        <div style={{ padding: "50px" }}>
            <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                    Please select a customer.
                </Alert>
            </Snackbar>
            <h2>Add Site</h2>
            {status === 1 && <Alert severity="success" onClose={() => { setStatus(0) }}>{AddSiteMessage}</Alert>}
            {status === -1 && <Alert severity="error" onClose={() => { setStatus(0) }}>Error:{AddSiteMessage}</Alert>}
            {status === 2 && <Alert severity="warning" onClose={() => { setStatus(0) }}>{AddSiteMessage}</Alert>}
            <br />
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h5'>
                        Basic Information
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Site Name"
                        id="location_name"
                        value={locationName}
                        helperText={locationNameHelperText}
                        error={locationNameHelperText}
                        variant="outlined"
                        style={{ width: "400px" }}
                        onChange={onChangeTextField}
                        required />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Business Hour Start (0-24)"
                        id="business_hr_start"
                        value={businessHrStart}
                        helperText={businessHrStartHelperText}
                        error={businessHrStartHelperText}
                        variant="outlined"
                        style={{ width: "400px" }}
                        onChange={onChangeTextField} required />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Business Hour End (0-24)"
                        id="business_hr_end"
                        value={businessHrEnd}
                        helperText={businessHrEndHelperText}
                        error={businessHrEndHelperText}
                        variant="outlined"
                        style={{ width: "400px" }}
                        onChange={onChangeTextField} required />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="City"
                        id="city"
                        value={city}
                        helperText={cityHelperText}
                        error={cityHelperText}
                        variant="outlined"
                        style={{ width: "400px" }}
                        onChange={onChangeTextField} required />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="State"
                        id="state"
                        value={state}
                        helperText={stateHelperText}
                        error={stateHelperText}
                        variant="outlined"
                        style={{ width: "400px" }}
                        onChange={onChangeTextField} required />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Country"
                        id="country"
                        helperText={countryHelperText}
                        error={countryHelperText}
                        value={country}
                        variant="outlined"
                        style={{ width: "400px" }}
                        onChange={onChangeTextField} required />
                </Grid>
            </Grid>
            <Divider sx={{ paddingTop: "20px" }} />
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h5'>
                        Contact Details
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={disableManagerFields}
                        label="Manager Name"
                        id="manager_name"
                        helperText={ManagerNameHelperText}
                        error={ManagerNameHelperText}
                        value={managerName}
                        variant="outlined"
                        style={{ width: "400px" }}
                        onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        disabled={disableManagerFields}
                        label="Manager Contact"
                        id="manager_contact"
                        helperText={ManagerContactHelperText}
                        error={ManagerContactHelperText}
                        value={managerContact}
                        variant="outlined"
                        style={{ width: "400px" }}
                        onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        disabled={disableManagerFields}
                        label="Manager Email"
                        id="manager_email"
                        helperText={ManagerEmailHelperText}
                        error={!!ManagerEmailHelperText}
                        value={managerEmail}
                        variant="outlined"
                        style={{ width: "400px" }}
                        onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Remarks (Max 100 Characters)"
                        id="remarks"
                        value={remarks}
                        variant="outlined"
                        style={{ width: "400px" }}
                        onChange={(e) => { setRemarks(e.target.value) }} />
                </Grid>
            </Grid>
            <Divider sx={{ paddingTop: "20px" }} />
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h5'>
                        Site Parameters
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Latency(in ms)" id="latency" value={latency} variant="outlined" style={{ width: "400px" }} onChange={onChangeTextField} helperText={latencyHelperText} error={latencyHelperText} required />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Jitter(in ms)" id="jitter" value={jitter} variant="outlined" style={{ width: "400px" }} onChange={onChangeTextField} helperText={jitterHelperText} error={jitterHelperText} required />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Packet Loss(in %)" id="packet_loss" value={packetLoss} variant="outlined" style={{ width: "400px" }} onChange={onChangeTextField} helperText={packetLossHelperText} error={packetLossHelperText} required />
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth required error={error}>
                        <InputLabel id="criticality-label-id">criticality</InputLabel>
                        <Select
                            labelId="criticality-label-id"
                            id="criticality_id"
                            value={criticality}
                            label="Criticality"
                            style={{ width: "400px" }}
                            onChange={onChangeTextField}
                        >
                            {/* <MenuItem value={"-1"}>Select</MenuItem> */}
                            <MenuItem value={"0"}>Low</MenuItem>
                            <MenuItem value={"1"}>Medium</MenuItem>
                            <MenuItem value={"2"}>High</MenuItem>
                        </Select>
                        {/* <FormHelperText style={{ color: 'red' }}>{criticalityHelperText}</FormHelperText> */}
                        {error && <Typography variant="caption" color="error">{criticalityHelperText}</Typography>}
                    </FormControl>
                </Grid>
            </Grid>

            <Divider sx={{ paddingTop: "20px" }} />
            
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h5'>
                        Utilization Threshold
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        id="Waring-Thresh"
                        label="Warning Threshold"
                        variant="outlined"
                        value={warning}
                        placeholder="Enter Warning Threshold(in %)"
                        onChange={(e) => {
                            setWarningHelperText("");
                            setWarning(e.target.value)
                        }
                        }
                        fullWidth
                        style={{ width: "400px" }}
                        helperText={warningHelperText}
                        error={!!warningHelperText}
                        required
                    />

                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="Critical-Thresh"
                        label="Critical Threshold"
                        variant="outlined"
                        value={critical}
                        placeholder="Enter Critical Threshold(in %)"
                        onChange={(e) => {
                            setCriticalHelperText("");
                            setCritical(e.target.value)
                        }
                        }
                        fullWidth
                        style={{ width: "400px" }}
                        helperText={criticalHelperText}
                        error={!!criticalHelperText}
                        required
                    />
                </Grid>

            </Grid>

            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton color='primary'
                            onClick={() => {
                                setShowEsc1Comps(prevShowEsc1Comps => {
                                    if (prevShowEsc1Comps) {
                                        setShowEsc2Comps(false);
                                        setShowEsc3Comps(false);
                                    }
                                    return !prevShowEsc1Comps;
                                });
                            }}>
                            {!showEsc1Comps && <AddCircleOutlineIcon />}
                            {showEsc1Comps && <RemoveCircleOutlineIcon />}
                        </IconButton>
                        <Typography variant='h5'>
                            Level-1 Escalation Details
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            {showEsc1Comps && <Divider sx={{ paddingTop: "20px" }} />}
            {showEsc1Comps && <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h5'>
                        Level-1 Escalation Details (Optional)
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Name" id="esc1_name" value={esc1Name} variant="outlined" helperText={eSCName1HelperText}
                        error={eSCName1HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Email" id="esc1_email" value={esc1Email} variant="outlined" helperText={eSEmail1HelperText}
                        error={!!eSEmail1HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Contact" id="esc1_contact" value={esc1Contact} variant="outlined" helperText={eSContact1HelperText}
                        error={eSContact1HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Threshold(In Minutes)" id="esc1_threshold" value={esc1Threshold} helperText={eSThreshold1HelperText} error={eSThreshold1HelperText} variant="outlined" style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>
            </Grid>}
            {showEsc1Comps && <Divider sx={{ paddingTop: "20px" }} />}
            {showEsc1Comps && <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton color='primary'
                            onClick={() => {
                                setShowEsc2Comps(prevShowEsc2Comps => {
                                    if (prevShowEsc2Comps) {
                                        setShowEsc3Comps(false);
                                    }
                                    return !prevShowEsc2Comps;
                                });
                            }}>
                            {!showEsc2Comps && <AddCircleOutlineIcon />}
                            {showEsc2Comps && <RemoveCircleOutlineIcon />}
                        </IconButton>
                        <Typography variant='h5'>
                            Level-2 Escalation Details
                        </Typography>
                    </div>
                </Grid>
            </Grid>}
            {showEsc2Comps && <Divider sx={{ paddingTop: "20px" }} />}
            {showEsc2Comps && <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h5'>
                        Level-2 Escalation Details (Optional)
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Name" id="esc2_name" value={esc2Name} variant="outlined" helperText={eSCName2HelperText}
                        error={eSCName2HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Email" id="esc2_email" value={esc2Email} variant="outlined" helperText={eSEmail2HelperText}
                        error={!!eSEmail2HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Contact" id="esc2_contact" value={esc2Contact} variant="outlined" helperText={eSContact2HelperText}
                        error={eSContact2HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Threshold(In Minutes)" id="esc2_threshold" value={esc2Threshold} variant="outlined" helperText={eSThreshold2HelperText} error={eSThreshold2HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>
            </Grid>}
            {showEsc2Comps && <Divider sx={{ paddingTop: "20px" }} />}
            {showEsc2Comps && <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton color='primary' onClick={() => { setShowEsc3Comps(!showEsc3Comps) }}>
                            {!showEsc3Comps && <AddCircleOutlineIcon />}
                            {showEsc3Comps && <RemoveCircleOutlineIcon />}
                        </IconButton>
                        <Typography variant='h5'>
                            Level-3 Escalation Details
                        </Typography>
                    </div>
                </Grid>
            </Grid>}
            {showEsc3Comps && <Divider sx={{ paddingTop: "20px" }} />}
            {showEsc3Comps && <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h5'>
                        Level-3 Escalation Details (Optional)
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Name" id="esc3_name" value={esc3Name} variant="outlined" helperText={eSCName3HelperText}
                        error={eSCName3HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Email" id="esc3_email" value={esc3Email} variant="outlined" helperText={eSEmail3HelperText}
                        error={!!eSEmail3HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Contact" id="esc3_contact" value={esc3Contact} variant="outlined" helperText={eSContact3HelperText}
                        error={eSContact3HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>

                <Grid item xs={6}>
                    <TextField label="Threshold(In Minutes)" id="esc3_threshold" value={esc3Threshold} variant="outlined" helperText={eSThreshold3HelperText} error={eSThreshold3HelperText} style={{ width: "400px" }} onChange={onChangeTextField} />
                </Grid>
            </Grid>}
            <Divider sx={{ paddingTop: "20px" }} />
            <br /><br />
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Button style={{ width: "400px", height: "50px", }} variant="contained" sx={{ fontSize: '1rem' }} onClick={handleOnSubmit} disabled={!isMandatoryFieldUpdated}>
                        Add
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button style={{ background: "red", width: "400px", height: "50px", }} variant="contained" sx={{ fontSize: '1rem' }} onClick={handleOnCancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} onWarningClose={onWarningClose} />}
            <Dialog
                open={confirmOpen}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Warning !!!
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{`Are you sure to add site for business unit - ${buName}?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCancel} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
