import axios from 'axios';

const customColors = ['#33FF57'];
const GetSaasAppDetailsGraphAPICall = async (locationId, appId, time, setAppJitterData, setAppLatencyData, setAppPacketLossData, setAppTimeData, setOpenBackdrop, setAppDetailsStatus) => {

    const url = `${process.env.REACT_APP_API_SERVER_URL}/get_application_metrics_graph.php`;


    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "location_id": locationId,
        "app_id": appId,
        "time": time
    });

    return axios.post(url, payload).then((response) => {
        if (response.data.code === 1) {
            setAppDetailsStatus(true)
            const data = response.data;
            setAppTimeData(data.time_data);
            setAppJitterData([]);
            setAppLatencyData([]);
            setAppPacketLossData([]);
            setOpenBackdrop(false);
            
            let defaultLatencyName = "Default Latency (ICMP/443)";
            if (data.default_monitoring_type === 0) {
                defaultLatencyName = "Default Latency (ICMP)";
            } else if (data.default_monitoring_type === 1 && data.default_port_num === 443) {
                defaultLatencyName = "Default Latency (TCP 443)";
            }
            let defaultJitterName = "Default Jitter (ICMP/443)";
            if (data.default_monitoring_type === 0) {
                defaultJitterName = "Default Jitter (ICMP)";
            } else if (data.default_monitoring_type === 1 && data.default_port_num === 443) {
                defaultJitterName = "Default Jitter (TCP 443)";
            }
            let defaultPacketLossName = "Default Packet Loss (ICMP/443)";
            if (data.default_monitoring_type === 0) {
                defaultPacketLossName = "Default Packet Loss (ICMP)";
            } else if (data.default_monitoring_type === 1 && data.default_port_num === 443) {
                defaultPacketLossName = "Default Packet Loss (TCP 443)";
            }
            setAppLatencyData(prevState => [...prevState, {
                name: defaultLatencyName,
                type: "line",
                fill: "gradient",
                data: data.app_latency_data,
                color: customColors[0]
            }]);

            setAppJitterData(prevState => [...prevState, {
                name: defaultJitterName,
                type: "line",
                fill: "gradient",
                data: data.app_jitter_data,
                color: customColors[0]
            }]);

            setAppPacketLossData(prevState => [...prevState, {
                name: defaultPacketLossName,
                type: "line",
                fill: "gradient",
                data: data.app_packet_loss_data,
                color: customColors[0]
            }]);

        }
        else {
            setAppJitterData([]);
            setAppLatencyData([]);
            setAppPacketLossData([]);
            setOpenBackdrop(false);
        }
    });
}

export default GetSaasAppDetailsGraphAPICall