import VideoStableOutlinedIcon from '@mui/icons-material/VideoStableOutlined';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import BuildIcon from '@mui/icons-material/Build';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import HomeIcon from '@mui/icons-material/Home';
import InsightsIcon from '@mui/icons-material/Insights';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import StreetviewIcon from '@mui/icons-material/Streetview';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import LinkIcon from '@mui/icons-material/Link';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import BusinessIcon from '@mui/icons-material/Business';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SummarizeIcon from '@mui/icons-material/Summarize';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RouterIcon from '@mui/icons-material/Router';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import AppsIcon from '@mui/icons-material/Apps';
import ReorderIcon from '@mui/icons-material/Reorder';
import BroadcastOnPersonalIcon from '@mui/icons-material/BroadcastOnPersonal';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddCardIcon from '@mui/icons-material/AddCard';
import ExploreIcon from '@mui/icons-material/Explore';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddchartIcon from '@mui/icons-material/Addchart';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MessageIcon from '@mui/icons-material/Message';
import StyleIcon from '@mui/icons-material/Style';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';

const navConfig = [
  {
    title: 'Home',
    icon: <HomeIcon />,
    children: [
      {
        title: 'Overview',
        icon: <DashboardIcon />,
        to: '/dashboard/home',
      },
      {
        title: 'Links',
        icon: <LinkIcon />,
        to: '/dashboard/isp-management',
      },
      {
        title: 'Edge Devices',
        icon: <EdgesensorHighIcon />,
        to: '/dashboard/edge-device-management',
      },
      {
        title: 'Sites',
        icon: <BusinessIcon />,
        to: '/dashboard/ISP-sitewise',
      },
      {
        title: 'App Monitor',
        icon: <AppsIcon />,
        to: '/dashboard/sa-monitor',
      },
      {
        title: 'Insights',
        icon: <InsightsIcon />,
        to: '/dashboard/power-bi-insights',
      },
      {
        children: [
          {
            title: 'Reports',
            icon: <SummarizeIcon />,
            children: [
              {
                title: 'Ready Reports',
                icon: <CalendarMonthIcon />,
                to: '/dashboard/isp-reports',
              },
              {
                title: 'Custom Reports',
                icon: <AssessmentIcon />,
                to: '/dashboard/reports',
              },
            ]
          }
        ]
      },
      {
        title: 'Inventory',
        icon: <InventoryIcon />,
        to: '/dashboard/link-devices-inventory',
      },
      {
        children: [
          {
            title: 'Logs',
            icon: <WebStoriesIcon />,
            children: [
              {
                title: 'Events',
                icon: <CalendarMonthIcon />,
                to: '/dashboard/alerts',
              },
              {
                title: 'Audit Logs',
                icon: <AssessmentIcon />,
                to: '/dashboard/audit-logs',
              },
              {
                title: 'System Logs',
                icon: <SystemUpdateAltIcon />,
                to: '/dashboard/system-logs',
              },
              {
                title: 'Decommisioned Logs',
                icon: <ExitToAppIcon />,
                to: '/dashboard/decommisioned-logs',
              }
            ]
          }
        ]
      }
    ]
  },
  {
    title: 'Settings',
    icon: <BuildIcon />,
    children: [
      {
        children: [
          {
            title: 'Zone Management',
            icon: <ExploreIcon />,
            children: [
              {
                title: 'View Zones',
                icon: <ExploreIcon />,
                to: '/dashboard/zones',
              },
              {
                title: 'Zone-Location Mapping',
                icon: <SwapHorizIcon />,
                to: '/dashboard/zone-location-map',
              },
            ]
          },
          {
            title: 'Business Unit Management',
            icon: <BusinessCenterIcon />,
            children: [
              {
                title: 'View Business-Units',
                icon: <CorporateFareIcon />,
                to: '/dashboard/business-unit',
              },
              {
                title: 'Business-Unit Location Mapping',
                icon: <SwapHorizIcon />,
                to: '/dashboard/business-unit-location-map',
              },
            ]
          },
          {
            title: 'Vendor Management',
            icon: <SettingsAccessibilityIcon />,
            children: [
              {
                title: 'View Vendors',
                icon: <GroupIcon />,
                to: '/dashboard/vendors',
              },
            ]
          },
          {
            title: 'Site Management',
            icon: <BusinessIcon />,
            children: [
              {
                title: 'Site Groups',
                icon: <WorkspacesIcon />,
                to: '/dashboard/site-groups',
              },
              {
                title: 'Create New Site',
                icon: <AddLocationIcon />,
                to: '/dashboard/add-single-location',
              },
              {
                title: 'Manage Sites',
                icon: <StreetviewIcon />,
                to: '/dashboard/view-locations',
              },
              {
                title: 'Groups-Sites Map',
                icon: <SwapHorizIcon />,
                to: '/dashboard/site-group-location-map',
              },
            ]
          },
          {
            title: 'Edge Devices Management',
            icon: <BusinessIcon />,
            children: [
              {
                title: 'View Edge Devices',
                icon: <EdgesensorHighIcon />,
                to: '/dashboard/view-edge-devices',
              },
            ]
          },
          {
            title: 'Link Management',
            icon: <LinkIcon />,
            to: '/dashboard/upload-isp-csv',
            children: [
              // {
              //   title: 'Add Single Link',
              //   icon: <AddLinkIcon />,
              //   to: '/dashboard/add-single-isp',
              // },
              {
                title: 'Add Multiple Links',
                icon: <DatasetLinkedIcon />,
                to: '/dashboard/upload-isp-csv',
              },
              {
                title: 'Manage Links',
                icon: <VideoStableOutlinedIcon />,
                to: '/dashboard/view-isp',
              }
            ]
          },
          {
            title: 'UBA Management',
            icon: <AppsIcon />,
            children: [
              {
                title: 'Managent Agents',
                icon: <BroadcastOnPersonalIcon />,
                to: '/dashboard/view-agents',
              },
              {
                title: 'Manage Application',
                icon: <ReorderIcon />,
                to: '/dashboard/view-apps',
              },
            ]
          },
          {
            title: 'User Management',
            icon: <GroupIcon />,
            children: [
              {
                title: 'Create New User',
                icon: <GroupAddIcon />,
                to: '/dashboard/create-new-user',
              },
              {
                title: 'View Users',
                icon: <ManageAccountsIcon />,
                to: '/dashboard/view-users',
              },
            ]
          },
          // {
          //   title: 'Tag Management',
          //   icon: <StyleIcon />,
          //   children: [
          //     {
          //       title: 'Create New Tag',
          //       icon: <LocalOfferIcon />,
          //       to: '/dashboard/create-new-tag',
          //     },
              
          //   ]
          // },

          {
            title: 'Alert Config',
            icon: <ErrorOutlineIcon />,
            children: [
              {
                title: 'Link/Site Alerts ',
                icon: <AttachEmailIcon />,
                to: '/dashboard/alert-email-config',
              },
              {
                title: 'System Alerts',
                icon: <AttachEmailIcon />,
                to: '/dashboard/system-alert-email-config',
              },
              {
                title: 'IM Config',
                icon: <MessageIcon />,
                to: '/dashboard/alert-im-config',
              },
              {
                title: 'Utilization Config',
                icon: <DataThresholdingIcon />,
                to: '/dashboard/utilization-config',
              },
            ]
          },
          // {
          //   title: 'Custom Reports',
          //   icon: <GroupIcon />,
          //   children: [
          //     {
          //       title: 'Templates',
          //       icon: <AssessmentIcon />,
          //       to: '/dashboard/templates',
          //     },
          //   ]
          // },
          {
            title: 'Connect Local Probe',
            icon: <RouterIcon />,
            children: [
              {
                title: 'Download OVF',
                icon: <CloudDownloadIcon />,
                to: '/dashboard/download-iso',
              },
            ]
          },
          // {
          //   title: 'License',
          //   icon: <CreditCardIcon />,
          //   children: [
          //     {
          //       title: 'View License',
          //       icon: <AddCardIcon />,
          //       to: '/dashboard/view-license',
          //     }
          //   ]
          // },
        ]
      }
    ],
  },
  // {
  //   title: 'Reports',
  //   icon: <AccountBalanceWalletIcon />,
  //   children: [
  //     {
  //       title: 'View Reports',
  //       icon: <AddchartIcon />,
  //       to: '/dashboard/reports',
  //     },
  //   ]
  // },
];

export default navConfig;