import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { EnterOTPRestAPICall } from './EnterOTPRestAPICall'
import { CheckOtpStatusApiCall } from './CheckOtpStatusApiCall';
// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { email } = state;
  
  const [code, setCode] = useState("");
  const [loginStatus, setLoginStatus] = useState(0);
  const [trailStatus, setTrailStatus] = useState(false);

 const [showCode, setShowCode] = useState(false);

  const ResetSchema = Yup.object().shape({
    code : Yup.string().required('OTP is required'),
  });

  const defaultValues = {
    code : '{code}',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(ResetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const changeCode = (e) => {
    setCode(e.target.value);
  }

  useEffect(()=>{
    CheckOtpStatusApiCall(email, code , setTrailStatus)
  }, [])
  

  const onSubmit = async (formData) => {
    try {
      const userData = await EnterOTPRestAPICall(email, code , setLoginStatus, setTrailStatus)
      navigate('/reset-password', { state: { email } });

    } catch (error) {
      // console.log(error);
    }
  }; 


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>

        <RHFTextField
          name="code"
          label="OTP"
          type={showCode ? 'text' : 'password'}
          onChange = {changeCode}
          value = {code}
          disabled={trailStatus}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowCode(!showCode)} edge="end">
                  <Iconify icon={showCode ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      {trailStatus && <p style={{paddingLeft:"20px", paddingTop:"20px", color:"red"}}>{"You have exceeded limit"}</p>}
      </Stack>


      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{marginTop:'30px'}}>
         Submit
      </LoadingButton>
      {loginStatus === -1 && <Alert severity="error">OTP is Not valid.. </Alert>}
    </FormProvider>
  );
  
}
