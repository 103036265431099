import { useRoutes, Routes, Route, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, setState } from "react";
import { useTour, TourProvider } from "@reactour/tour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { element } from 'prop-types';

import DashboardLayout from './layouts/dashboard';
// layouts
import Sidewise from './pages/Sidewise';
import ViewLinks from './pages/ViewLinks';
import ISPReports from './pages/ISP-Reports';
import Alerts from './pages/Alerts';
import Overview from './pages/Overview';
import ISPGood from './components/ISPManagementTable/ISPManagementDashboard/ISPGood';
import ISPBad from './components/ISPManagementTable/ISPManagementDashboard/ISPBad';
import ISPDown from './components/ISPManagementTable/ISPManagementDashboard/ISPDown';
import SidewiseGood from './components/SidewiseComponents/Components/SidewiseGood';
import SidewiseBad from './components/SidewiseComponents/Components/SidewiseBad';
import SidewiseDown from './components/SidewiseComponents/Components/SidewiseDown';
import SidewiseTotalLinks from './components/SidewiseComponents/Components/SidewiseTotalLinks';
import ISPGood1 from './components/ISPManagementTable/ISPManagementDashboard/ISPGood1/ISPGood1';
import ISPBad1 from './components/ISPManagementTable/ISPManagementDashboard/ISPBad1/ISPBad1';
import ISPDown1 from './components/ISPManagementTable/ISPManagementDashboard/ISPDown1/ISPDown1';

import EdGood from './components/EdgeDevicesManagementTable/EdgeDevicesManagementDashboard/EdGood';
import EdBad from './components/EdgeDevicesManagementTable/EdgeDevicesManagementDashboard/EdBad';
import EdDown from './components/EdgeDevicesManagementTable/EdgeDevicesManagementDashboard/EdDown';

import Login from './layouts/Login';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import ResetPassword from './layouts/ResetPassword';
import ForgotPassword from './layouts/ForgotPassword';
import { ISPTotal } from './components/ISPManagementTable/ISPManagementDashboard';
import AddSingleISP from './pages/AddSingleISP';
import EditSingleISP from './pages/EditSingleISP';
import UploadMultipleISP from './pages/UploadMultipleISP';
import SiteDown from './pages/SiteDown';
import AlertEmailConfig from './pages/AlertEmailConfig';
import CreateNewUser from './pages/CreateNewUser';
import ViewUsers from './pages/ViewUsers';
import PageNotFound from './pages/PageNotFound';
import AddSingleLocation from './pages/AddSingleLocation';
import ViewLocations from './pages/ViewLocations';
import AuditLogs from './pages/AuditLogs';
import Vendors from './pages/Vendors';
import Zones from './pages/Zones';
import ZoneLocationMapping from './pages/ZoneLocationMapping';
import BusinessUnit from './pages/BusinessUnit'
import BusinessUnitLocationMapping from './pages/BusinessLocationMapping';
import PowerBiInsights from './pages/PowerBiInsights';
import ValidateOtp from './layouts/ValidateOtp';
import EnterOtp from './layouts/EnterOTP';
import Templates from './pages/Templates';
import HomePage from './pages/HomePage';
import DownloadIso from './pages/DownloadIso';
import SiteGroups from './pages/SiteGroups';
import SiteGroupsLocationsMapping from './pages/SiteGroupsLocationsMapping';
import EdgeDevices from './pages/EdgeDevices';

import WizardLayout from './layouts/WizardLayout';
import WizardForm from './pages/Wizard';

import UpdateTourStatusAPICall from './pages/UpdateTourStatusAPICall';
import ISPFlapping from './components/ISPManagementTable/ISPManagementDashboard/ISPFlapping';
import Customers from './pages/Customers';
import MspUsers from './pages/MspUsers';
import LinksDevicesInventory from './pages/LinksDevicesInventory';
import SystemAlertEmailConfig from './pages/SystemAlertEmailConfig';
import EdgeDevicesOverview from './pages/EdgeDevicesOverview';
import EdTotal from './components/EdgeDevicesManagementTable/EdgeDevicesManagementDashboard/EdTotal';
import ISPPaused from './components/ISPManagementTable/ISPManagementDashboard/ISPPaused';

import { EdGood1 } from './components/EdgeDevicesManagementTable/EdgeDevicesManagementDashboard/EdGood1';
// import { EdBad1 } from './components/EdgeDevicesManagementTable/EdgeDevicesManagementDashboard/EdBad1';
import { EdDown1 } from './components/EdgeDevicesManagementTable/EdgeDevicesManagementDashboard/EdDown1';

import SAMonitor from './pages/SAMonitor';
import AppMonitor from './pages/AppMonitor';
import SaasApplications from './pages/SaasApplications';
import ViewAgents from './pages/ViewAgents';

import EdgeDevicesData from './pages/EdgeDevicesData';
import EdLogs from './pages/EdLogs';
import EdPaused from './components/EdgeDevicesManagementTable/EdgeDevicesManagementDashboard/EdPaused';
import ViewLicenseComponent from './components/LicenseComponents/components/ViewLicenseComponent';
import ISPFlapping1 from './components/ISPManagementTable/ISPManagementDashboard/ISPFlapping1/ISPFlapping1';
import SystemLogs from './pages/SystemLog';
import DecommisionedLogs from './pages/DecommisionedLogs';
import AlertIMConfig from './pages/AlertIMConfig';
import SiteUp from './pages/SiteUp';
import NewReports from './pages/New-reports';
import CreateNewTag from './pages/CreateNewTag';
import UtilizationConfig from './pages/UtilizationConfig';

// ----------------------------------------------------------------------
export default function Router() {
  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  const { setIsOpen } = useTour();
  const [isShowingMore, setIsShowingMore] = useState(false);

  const closeTour = (target) => {
    enableBodyScroll(target);
    UpdateTourStatusAPICall();

  };
  const steps = [
    {
      selector: '[data-tut="linkeyetour_home_sitessummarynumbers"]',
      content: `This provides you the sites functioning summary..`
    },
    {
      selector: '[data-tut="linkeyetour_home_changelogo"]',
      content: `Update your company logo here..`
    },
    {
      selector: '[data-tut="linkeyetour_home_producthelpdocument"]',
      content: `You can view the product usage and help document here.`
    },
    {
      selector: '[data-tut="linkeyetour_home_avgsitelinksla"]',
      content: `This signifies your average site uptime, link uptime, SLA breaches and total site down time.`
    },

    {
      selector: '[data-tut="linkeyetour_home_bestworstperforminglinkslist"]',
      content: `This shows the list of best and worst performing links list.`
    },
    {
      selector: '[data-tut="linkeyetour_home_bestworstperformingsiteslist"]',
      content: `This shows the list of best and worst performing sites list.`
    },
    {
      selector: '[data-tut="linkeyetour_home_totalgoodbaddownlinks"]',
      content: `This shows the numbers of Total, Good, Bad and Down Links.`
    },

    {
      selector: '[data-tut="linkeyetour_home_addmultipleedgedevices"]',
      content: `You can add multiple Edge Devices by filling the template excel sheet and upload.`
    },
    {
      selector: '[data-tut="linkeyetour_home_addmultiplelinks"]',
      content: `You can add multiple links to linkeye by filling the excel sheet attached here.`
    },
    {
      selector: '[data-tut="linkeyetour_home_connecttolocalprobe"]',
      content: `You can connect your local probe by setting up the local probe server by following the probe installation documents.`
    },
  ];
  const redirect = useNavigate();
  const [step, setStep] = useState(0);
  const setCurrentStep = (step) => {
    switch (step) {
      case 0:
        redirect("/dashboard/home", true);
        break;
      case 1:
        redirect("/dashboard/home", true);
        break;
      case 2:
        redirect("/dashboard/home", true);
        break;
      case 3:
        redirect("/dashboard/home", true);
        break;
      case 4:
        redirect("/dashboard/home", true);
        break;
      case 5:
        redirect("/dashboard/home", true);
        break;

      case 6:
        redirect("/dashboard/isp-management", true);
        break;

      case 7:
        redirect("/dashboard/view-locations", true);
        break;
      case 8:
        redirect("/dashboard/upload-isp-csv", true);
        break;
      case 9:
        redirect("/dashboard/download-iso", true);
        break;
      default:
        break;
    }
    setStep(step);

  };

  const routes = [
    {
      path: '/',
      element: <Login />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'isp-sitewise',
          element: <Sidewise />,
          children: [
            { path: '', element: <SidewiseTotalLinks /> },
            { path: 'sidewise-good', element: <SidewiseGood /> },
            { path: 'sidewise-bad', element: <SidewiseBad /> },
            { path: 'sidewise-down', element: <SidewiseDown /> },
          ]
        },
        { path: 'alerts', element: <Alerts /> },
        { path: 'view-isp', element: <ViewLinks /> },
        { path: 'link-devices-inventory', element: <LinksDevicesInventory /> },
        { path: 'isp-reports', element: <ISPReports /> },
        { path: 'app-monitor-dashboard/:locationId', element: <AppMonitor /> },
        {
          path: 'isp-management',
          element: <Overview />,
          children: [
            { path: 'isp-good', element: <ISPGood /> },
            { path: 'isp-bad', element: <ISPBad /> },
            { path: 'isp-total', element: <ISPTotal /> },
            { path: 'isp-flapping', element: <ISPFlapping /> },
            { path: 'isp-paused', element: <ISPPaused /> },
            { path: '', element: <ISPDown /> },
          ]
        },
        {
          path: 'edge-device-management',
          element: <EdgeDevicesOverview />,
          children: [
            { path: '', element: <EdGood /> },
            { path: 'edge-device-bad', element: <EdBad /> },
            { path: 'edge-device-total', element: <EdTotal /> },
            { path: 'edge-device-down', element: <EdDown /> },
            { path: 'edge-device-paused', element: <EdPaused /> },
          ]
        },
        {
          path: 'edge-device-data',
          element: <EdgeDevicesData />,
        },
        {
          path: 'home', element: <HomePage />
        },
        { path: 'add-single-isp/', element: <AddSingleISP /> },
        { path: 'edit-single-isp/:id', element: <EditSingleISP /> },
        {
          path: 'upload-isp-csv', element: <UploadMultipleISP openTour={() => setIsOpen(true)}
            toggleShowMore={() => setIsShowingMore((m) => !m)}
            isShowingMore={isShowingMore} />
        },
        { path: 'isp-management/isp-good/:id', element: <ISPGood1 /> },
        { path: 'isp-management/isp-bad/:id', element: <ISPBad1 /> },
        { path: 'isp-management/isp-down/:id', element: <ISPDown1 /> },
        { path: 'isp-management/isp-flapping/:id/:status', element: <ISPFlapping1 /> },

        { path: 'isp-management/ed-good/:id', element: <EdGood1 /> },
        // { path: 'isp-management/ed-bad/:id', element: <EdBad1 /> },
        { path: 'isp-management/ed-down/:id', element: <EdDown1 /> },

        { path: 'site-up', element: <SiteUp /> },
        { path: 'site-down', element: <SiteDown /> },
        { path: 'alert-email-config', element: <AlertEmailConfig /> },
        { path: 'alert-im-config', element: <AlertIMConfig /> },
        { path: 'system-alert-email-config', element: <SystemAlertEmailConfig /> },
        // Utilization
        { path: 'utilization-Config', element:<UtilizationConfig/>},

        // User Components
        { path: 'create-new-user/', element: <CreateNewUser /> },
        { path: 'view-users/', element: <ViewUsers /> },

        // Tag Management
        { path: 'create-new-tag', element: <CreateNewTag /> },

        // Location Components
        { path: 'add-single-location', element: <AddSingleLocation /> },
        { path: 'view-locations', element: <ViewLocations /> },

        // Edge Devices
        { path: 'view-edge-devices', element: <EdgeDevices /> },

        // Site-Groups
        { path: 'site-groups', element: <SiteGroups /> },
        { path: 'site-group-location-map', element: <SiteGroupsLocationsMapping /> },

        // Logs
        { path: 'audit-logs', element: <AuditLogs /> },
        { path: 'system-logs', element: <SystemLogs /> },
        { path: 'ed-logs', element: <EdLogs /> },
        { path: 'decommisioned-logs', element: <DecommisionedLogs /> },

        // Vendors
        { path: 'vendors', element: <Vendors /> },

        // Templates
        { path: 'templates', element: <Templates /> },

        // Zones
        { path: 'zones', element: <Zones /> },
        { path: 'zone-location-map', element: <ZoneLocationMapping /> },
        // { path:'zone-parameters', element:<ZoneParameters />},

        // Business-unit
        { path: 'business-unit', element: <BusinessUnit /> },
        { path: 'business-unit-location-map', element: <BusinessUnitLocationMapping /> },

        // Reports
        { path: 'power-bi-insights', element: <PowerBiInsights /> },

        // Download OVF
        { path: 'download-iso', element: <DownloadIso /> },

        { path: 'wizard', element: <WizardForm /> },

        { path: 'customers', element: <Customers /> },

        { path: 'msp-users', element: <MspUsers /> },

        { path: 'sa-monitor', element: <SAMonitor /> },

        // { path: 'app-monitor-dashboard', element: <AppMonitor /> },

        { path: 'view-apps', element: <SaasApplications /> },

        { path: 'view-agents', element: <ViewAgents /> },

        { path: 'view-license', element: <ViewLicenseComponent /> },

        { path: 'view-license', element: <ViewLicenseComponent /> },

        // reports path
        { path: 'reports', element: <NewReports /> },

      ]
    },
    {
      path: '/logout',
      element: <LogoOnlyLayout />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />
    },
    {
      path: 'validate-otp',
      element: <ValidateOtp />
    },
    {
      path: 'reset-password',
      element: <ResetPassword />
    },
    {
      path: 'enter-otp',
      element: <EnterOtp />
    },
    {
      path: '*',
      element: <PageNotFound />
    },
    {
      path: 'wizard-form', element: <WizardLayout />
    },


  ]
  return (<TourProvider
    steps={steps}
    currentStep={step}
    beforeClose={closeTour}
    afterOpen={disableBody}
    setCurrentStep={setCurrentStep}
    disableInteraction={false}
  > {useRoutes(routes)}
  </TourProvider>);
}