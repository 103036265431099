


import axios from 'axios';

const UtilizationAlertApiCall = async (locationId, locationName, alertStatus, setOpen) => {
  const path = localStorage.getItem("msp_status") === "1" ? "site_utilization_alert_for_msp.php" : "site_utilization_alert.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "location_id": locationId,
    "location_name": locationName,
    "alert_status": alertStatus
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "location_id": locationId,
    "location_name": locationName,
    "alert_status": alertStatus
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
      setOpen(true);
    }
    else {
      console.log("Error in enabling alert for the sites");

    }
  }).catch(() => {
    console.log("Error in enabling alert for the sites");

  })
}
export default UtilizationAlertApiCall;

