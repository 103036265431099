import axios from 'axios';

const AddSingleLocationRestApi = async (locationName, businessHrStart, businessHrEnd, remarks, city, state, country, managerId, managerName, managerContact, managerEmail, esc1Name, esc1Email, esc1Contact, esc1Threshold, esc2Name, esc2Email, esc2Contact, esc2Threshold, esc3Name, esc3Email, esc3Contact, esc3Threshold, latency, jitter, packetLoss, criticality, warning, critical, setStatus, setAddSiteMessage, buState) => {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
        const url = `${process.env.REACT_APP_API_SERVER_URL}/push_single_isp_location_for_bu.php`;
        const payload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "client_id": localStorage.getItem("reg_cust_id"),
            "bu_id": buState,
            "location_data": {
                "location_name": locationName,
                "business_hr_start": businessHrStart,
                "business_hr_end": businessHrEnd,
                "city": city,
                "state": state,
                "country": country,
                "remarks": remarks,
                "manager_id": managerId,
                "manager_name": managerName,
                "manager_contact": managerContact,
                "manager_email": managerEmail,

                "esc1_name": esc1Name,
                "esc1_email": esc1Email,
                "esc1_contact": esc1Contact,
                "esc1_threshold": esc1Threshold,

                "esc2_name": esc2Name,
                "esc2_email": esc2Email,
                "esc2_contact": esc2Contact,
                "esc2_threshold": esc2Threshold,

                "esc3_name": esc3Name,
                "esc3_email": esc3Email,
                "esc3_contact": esc3Contact,
                "esc3_threshold": esc3Threshold,

                "latency": latency,
                "jitter": jitter,
                "packet_loss": packetLoss,
                "criticality": criticality,

                "warning_th_value": warning,
                "critical_th_value": critical
            }
        });

        return axios.post(url, payload).then((response) => {
            if (response.data.code === 1) {
                setStatus(1);
                setAddSiteMessage(response.data.message)
            }
            else if (response.data.code === 2) {
                setStatus(2);
                setAddSiteMessage(response.data.message)
            }
            else {
                setStatus(-1);
                setAddSiteMessage(response.data.message)

            }
        })
    }
    if (localStorage.getItem("bu_status") === "-1" || buState === -1) {
        const path = localStorage.getItem("msp_status") === "1" ? "push_single_isp_location_for_msp.php" : "push_single_isp_location.php";
        const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
        const regPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "client_id": localStorage.getItem("reg_cust_id"),
            "location_data": {
                "location_name": locationName,
                "business_hr_start": businessHrStart,
                "business_hr_end": businessHrEnd,
                "city": city,
                "state": state,
                "country": country,
                "remarks": remarks,
                "manager_id": managerId,
                "manager_name": managerName,
                "manager_contact": managerContact,
                "manager_email": managerEmail,

                "esc1_name": esc1Name,
                "esc1_email": esc1Email,
                "esc1_contact": esc1Contact,
                "esc1_threshold": esc1Threshold,

                "esc2_name": esc2Name,
                "esc2_email": esc2Email,
                "esc2_contact": esc2Contact,
                "esc2_threshold": esc2Threshold,

                "esc3_name": esc3Name,
                "esc3_email": esc3Email,
                "esc3_contact": esc3Contact,
                "esc3_threshold": esc3Threshold,

                "latency": latency,
                "jitter": jitter,
                "packet_loss": packetLoss,
                "criticality": criticality,

                "warning_th_value": warning,
                "critical_th_value": critical
            }
        });

        const mspPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "msp_client_id": localStorage.getItem("cust_id"),
            "reg_client_id": localStorage.getItem("reg_cust_id"),
            "location_data": {
                "location_name": locationName,
                "business_hr_start": businessHrStart,
                "business_hr_end": businessHrEnd,
                "city": city,
                "state": state,
                "country": country,
                "remarks": remarks,
                "manager_id": managerId,
                "manager_name": managerName,
                "manager_contact": managerContact,
                "manager_email": managerEmail,

                "esc1_name": esc1Name,
                "esc1_email": esc1Email,
                "esc1_contact": esc1Contact,
                "esc1_threshold": esc1Threshold,

                "esc2_name": esc2Name,
                "esc2_email": esc2Email,
                "esc2_contact": esc2Contact,
                "esc2_threshold": esc2Threshold,

                "esc3_name": esc3Name,
                "esc3_email": esc3Email,
                "esc3_contact": esc3Contact,
                "esc3_threshold": esc3Threshold,

                "latency": latency,
                "jitter": jitter,
                "packet_loss": packetLoss,
                "criticality": criticality,

                "warning_th_value": warning,
                "critical_th_value": critical
            }

        });
        const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;


        return axios.post(baseUrl, payload).then((response) => {
            if (response.data.code === 1) {
                setStatus(1);
                setAddSiteMessage(response.data.message)
            }
            else if (response.data.code === 2) {
                setStatus(2);
                setAddSiteMessage(response.data.message)

            }
            else {
                setStatus(-1);
                setAddSiteMessage(response.data.message)

            }
        })
    }

}


export default AddSingleLocationRestApi