import React, { useState, useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import GetPowerBiToken from './GetPowerBiToken';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';

export default function Report(props) {
  const [token, setToken] = useState("")
  const { globalState, setGlobalState } = useGlobalState();
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [custId, setCustId] = useState(parseInt(localStorage.getItem("cust_id"), 10));

  const customFilters = [{
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "fn_customers",
      column: "cust_id"
    },
    operator: "Eq",
    values: [parseInt(custId, 10)]
  }];

  useEffect(() => {
    if (localStorage.getItem("msp_status") === "1") {
      if (parseInt(localStorage.getItem("cust_id"), 10) === globalState) {
        setOpenWarnDialog(true);
        setShowReport(false)
      }
      else {
        setCustId(globalState);
        setShowReport(true);
      }
    }
    else {
      setCustId(parseInt(localStorage.getItem("cust_id"), 10));
      setShowReport(true)
    }

    GetPowerBiToken(setToken);
  }, [token, globalState])


  return (
    <div>
      {/* { showReport && <PowerBIEmbed
        embedConfig={{
          type: 'report',   // Supported types: report, dashboard, tile, visual and qna
          id: 'a0ee2128-059e-42a5-8982-62b103910099', // Report fot Test Bed 
          embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=a0ee2128-059e-42a5-8982-62b103910099&groupId=3858ed3c-6714-4b5f-935e-7926c4807c09&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
          accessToken: token,
          tokenType: models.TokenType.Aad,
          settings: {
            panes: {
              filters: {
                expanded: true,
                visible: false
              }
            },
            background: models.BackgroundType.Transparent,
          },
          filters: customFilters
        }}

        eventHandlers={
          new Map([
            ['loaded', function () { console.log('Report loaded'); }],
            ['rendered', function () { console.log('Report rendered'); }],
            ['error', function (event) { console.log(event.detail); }]
          ])
        }

        cssClassName={"Embed-Container"}

        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />} */}

      {showReport &&
        <PowerBIEmbed
          embedConfig={{
            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
            id: '10e160ec-f494-4b63-bf4d-b39ae1b9b059', // Power BI Report for Azure Production
            embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=10e160ec-f494-4b63-bf4d-b39ae1b9b059&groupId=91f5b112-126b-48a4-8570-dbfcb0691950&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
            accessToken: token,
            tokenType: models.TokenType.Aad,
            settings: {
              panes: {
                filters: {
                  expanded: true,
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent,
            },
            filters: customFilters
          }}

          eventHandlers={
            new Map([
              ['loaded', function () { console.log('Report loaded'); }],
              ['rendered', function () { console.log('Report rendered'); }],
              ['error', function (event) { console.log(event.detail); }]
            ])
          }

          cssClassName={"Embed-Container"}

          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />}

      {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}
    </div>
  );
};

