import React, { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Divider,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function ReportsDialogComponent(props) {

    const { openReportsDialog, setOpenReportDialog, setVendorReports, setSiteReports, setLinkReport, setReportType, handleReportClose } = props; 

    const [vendorChecked, setVendorChecked] = useState(false);
    const [siteChecked, setSiteChecked] = useState(false);
    const [linkChecked, setLinkChecked] = useState(false);
    const [reportTypeError, setReportTypeError] = useState(false);


    const handleClose = (e) =>{
        setOpenReportDialog(false);
    }

    const handleVendorCheckedChange=(e)=>{
        setVendorChecked(e.target.checked);
        if(e.target.checked){
            setVendorReports(true)
        }
        else{
            setVendorReports(false)
        }
    }

    const handleSiteCheckedChange=(e)=>{
        setSiteChecked(e.target.checked);
        if(e.target.checked){
            setSiteReports(true)
        }
        else{
            setSiteReports(false)
        }
    }

    const handleLinkCheckedChange=(e)=>{
        setLinkChecked(e.target.checked);
        if(e.target.checked){
            setLinkReport(true)
        }
        else{
            setLinkReport(false)
        }
    }

    const handleReportTypeChange=(e)=>{
        setReportType(e.target.value);
    }

    const handleGenerateClick = () => {
        if (!vendorChecked && !linkChecked && !siteChecked) {
            // Display error message if neither checkbox is checked
            setReportTypeError(true);
        } else if (!setReportType) {
            // Display error message if report type is not selected
            setReportTypeError(true);
        } else {
            // All validation passed, close the form
            handleReportClose();
        }
    }
    
    return (
        <>
            <Dialog open={openReportsDialog} onClose={handleClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Reports
                    <IconButton sx={{ float: 'right' }} onClick={handleClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <Typography>
                        <strong>Select Reports to be Generated</strong>
                    </Typography>
                    <Divider sx={{ margin: 2 }} />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={vendorChecked} onChange={handleVendorCheckedChange}/>} label="Vendorwise Reports" />
                        <FormControlLabel control={<Checkbox checked={siteChecked} onChange={handleSiteCheckedChange} />} label="Sitewise Reports" />
                        <FormControlLabel control={<Checkbox checked={linkChecked} onChange={handleLinkCheckedChange} />} label="Linkwise Reports" />
                    </FormGroup>
                    <Divider sx={{ margin: 2 }} />
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Report Type</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="1"
                            name="radio-buttons-group"
                            onChange={handleReportTypeChange}
                        >
                            <FormControlLabel value="PDF" control={<Radio value={1}/>} label="PDF" />
                            <FormControlLabel value="CSV" control={<Radio value={2}/>} label="CSV" />
                        </RadioGroup>
                    </FormControl>
                    {reportTypeError && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            Please select at least one report to be generated.
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleGenerateClick}>Generate</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}