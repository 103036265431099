// @mui
import {
    Box,
    Button,
    Card,
    ButtonGroup,
    Typography,
    Grid,
} from '@mui/material';
import { CSVLink, CSVDownload } from "react-csv";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import { useState, useEffect, useRef, createRef, useContext } from 'react';
import 'react-tabs/style/react-tabs.css';
import _ from "lodash";
import JsPDF from "jspdf";
// import { useIdleTimer } from 'react-idle-timer';

import "jspdf-autotable";
// components
import { $CombinedState } from 'redux';

import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';

import BestVendorsTable from "./BestComponents/BestVendorsTable"
import BestPerformingSitesTable from './BestComponents/BestPerformingSitesTable';
import BestPerformingLinksTable from './BestComponents/BestPerformingLinksTable';

import WorstVendorsTable from './WorstComponents/WorstVendorsTable';
import WorstPerformingSitesTable from './WorstComponents/WorstPerformingSitesTable';
import WorstPerformingLinksTable from './WorstComponents/WorstPerformingLinksTable';

import HollisticLinkView from './HollisticComponents/HolisticLinkView';
import HollisticVendorView from './HollisticComponents/HolisticVendorView';
import HolisticSitesView from './HollisticComponents/HolisticSitesView';
import MonthYearPicker from './MonthYearPicker';
import FileSystemTreeView from './FileSystemTreeView';

import BestVendorsApiCall from './BestVendorsApiCall';
import BestSitesApiCall from './BestSitesApiCall';
import BestLinksApiCall from './BestLinksApiCall';
import ReportsDialogComponent from './ReportsDialogComponent';
import { useGlobalState } from '../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../WarningComponents/SelectCustomerWarningDialog';

const useStyles = makeStyles({
    boxPadding: {
        textDecoration: 'none',
    },
    boxItems: {
        display: 'flex',
        alignItems: 'center',
    },
    titleStyle: {
        padding: 24,
    },
});

export default function ReportsMainComponent() {

    const classes = useStyles();
    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();
    const [value, setValue] = useState(0);
    const [holisticValue, setHolisticValue] = useState('0');
    
    const [linkVariant, setLinkVariant] = useState('contained');
    const [vendorVariant, setVendorVariant] = useState('outlined');
    const [siteVariant, setSiteVariant] = useState('outlined')

    const [monthYear, setMonthYear] = useState(() => {
        const date = new Date();
        const month = date.toLocaleString("en-IN", { month: "2-digit" });
        const year = date.toLocaleString("en-IN", { year: "numeric" });
        return `${year}-${month}`;
    });

    const [bestVendors, setBestVendors] = useState([]);
    const [bestSites, setBestSites] = useState([]);
    const [bestLinks, setBestLinks] = useState([]);
    const [linkData, setLinkData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const [siteHeader, setSiteHeader] = useState([])
    const [linkheader, setLinkHeader] = useState([]);
    const [vendorHeader, setVendorHeader] = useState([]);
    const [worstVendors, setWorstVendors] = useState([]);
    const [worstSites, setWorstSites] = useState([]);
    const [worstLinks, setWorstLinks] = useState([]);
    const [openReportsDialog, setOpenReportDialog] = useState(false);
    const [vendorReports, setVendorReports] = useState(false);
    const [siteReports, setSiteReports] = useState(false)
    const [linkReports, setLinkReport] = useState(false);
    const [reportType, setReportType] = useState("1");
    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
    const [openWarnDialog, setOpenWarnDialog] = useState(false);




    const csvLinkVendors = createRef();
    const csvLinkSites = createRef();
    const csvLinkLinks = createRef();



    const handleChange = (event, newValue) => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        setValue(newValue);
    };

    function TabPanel(props) {

        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {!(globalState === custId && mspStatus === 1) && value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }


    const handleHolisticChange = (e) => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }

        setHolisticValue(e.target.value);
        if (e.target.value === "0") {
            setLinkVariant('contained')
            setSiteVariant('outlined');
            setVendorVariant('outlined');
        }
        if (e.target.value === "1") {
            setLinkVariant('outlined')
            setSiteVariant('outlined');
            setVendorVariant('contained');
        }
        if (e.target.value === "2") {
            setLinkVariant('outlined')
            setSiteVariant('contained');
            setVendorVariant('outlined');
        }
        

        // console.log(siteVariant);
    }

    const convertMinToHourMinutes = (minutes) => {
        const d = Math.round(Math.floor(minutes / (24 * 60)));
        const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
        const m = Math.round((minutes % (24 * 60)) % 60);
        if (minutes === "0") {
            return "No Impact";
        }

        return `${d}days, ${h}hours, ${m}minutes`;


    }

    const generateVendorReport = () => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new JsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Vendor Report";

        // const data = bestVendors;
        // console.log(bestVendors)
        const bestVendorsArr = [];
        for (let index = 0; index < bestVendors.length; index += 1) {
            const vendor = [];
            vendor[0] = index + 1;
            vendor[1] = bestVendors[index].vendor;
            vendor[2] = bestVendors[index].avgUptime.toString().concat('%');
            vendor[3] = convertMinToHourMinutes(bestVendors[index].downTime);
            vendor[4] = bestVendors[index].slaBreaches;
            vendor[5] = convertMinToHourMinutes(bestVendors[index].businessHourMetrics);
            bestVendorsArr.push(vendor)
        }
        // console.log(bestVendorsArr);
        const headers = [["Sl.No", "Vendor", "Avg Uptime in(%)", "Down Time", "SLA Breaches", "Business Hour Metrics"]];
        // console.log(headers);
        const content = {
            startY: 50,
            head: headers,
            body: bestVendorsArr
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`Vendor_Report_${monthYear}.pdf`)
    }

    const generateSiteReport = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new JsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Sites Report";

        // const data = bestVendors;
        const bestSitessArr = [];
        for (let index = 0; index < bestSites.length; index += 1) {
            const site = [];
            site[0] = index + 1;
            site[1] = bestSites[index].group_name;
            site[2] = bestSites[index].location_name;
            site[3] = bestSites[index].down_cnt.toString().concat(' Minutes');
            site[4] = bestSites[index].up_cnt.toString().concat(' Minutes');
            site[5] = bestSites[index].down_time_in_hh_mm.toString();
            site[6] = bestSites[index].up_time_in_hh_mm.toString();
            site[7] = bestSites[index].down_time_percent.toString().concat(' %');
            site[8] = bestSites[index].up_time_percent.toString().concat(' %');
            bestSitessArr.push(site)
        }
        
        const headers = [["Sl.No", "Group Name", "Site Name", "Down Time (in Minutes)", "Up Time (in Minutes)", "Down Time (in HH:MM)", "Up Time (in HH:MM)", "Down Time (in %)", "Up Time (in %)"]];

        const content = {
            startY: 50,
            head: headers,
            body: bestSitessArr
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`Sites_Report_${monthYear}.pdf`)
    }

    const generateLinkReport = () => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new JsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Link Report";

        const bestLinksArr = [];
        // console.log(bestLinks)
        for (let index = 0; index < bestLinks.length; index += 1) {
            const link = [];
            link[0] = index + 1;
            link[1] = bestLinks[index].circuit_id;
            link[2] = bestLinks[index].location;
            link[3] = bestLinks[index].vendor;
            link[4] = bestLinks[index].public_ip;
            link[5] = bestLinks[index].avgUptime.toString().concat('%');
            link[6] = convertMinToHourMinutes(bestLinks[index].downTime);
            link[7] = bestLinks[index].slaBreaches;
            link[8] = convertMinToHourMinutes(bestLinks[index].businessHourMetrics);
            bestLinksArr.push(link)
        }
        // console.log(bestLinksArr);
        const headers = [["Sl.No", "Circuit ID", "Site Name", "Vendor", "Public IP", "Avg Uptime(in%)", "Down Time", "SLA Breaches", "Business Hour Metrics"]];

        const content = {
            startY: 50,
            head: headers,
            body: bestLinksArr
        };
        // 
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`Link_Report_${monthYear}.pdf`)
    }

    const generateLinkReportCSV = (bestLinks) => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        const bestLinksArr = [];


        for (let index = 0; index < bestLinks.length; index += 1) {
            const link = [];
            link[0] = index + 1;
            link[1] = bestLinks[index].circuit_id;
            link[2] = bestLinks[index].location;
            link[3] = bestLinks[index].vendor;
            link[4] = bestLinks[index].public_ip;
            link[5] = bestLinks[index].avgUptime.toString().concat('%');
            link[6] = convertMinToHourMinutes(bestLinks[index].downTime);
            link[7] = bestLinks[index].slaBreaches;
            link[8] = convertMinToHourMinutes(bestLinks[index].businessHourMetrics);
            bestLinksArr.push(link)

        }
        const headers = ["Sl.No", "Circuit ID", "Site Name", "Vendor", "Public IP", "Avg Uptime(in%)", "Avg Restore Time", "SLA Breaches", "Business Hour Metrics"];
        setLinkHeader(headers);


        // console.log("Setting,Link Data")
        // console.log(bestLinksArr);
        setLinkData(bestLinksArr);
    }

    const generateSiteReportCSV = (bestSites) => {
        const bestSitessArr = [];
        for (let index = 0; index < bestSites.length; index += 1) {
            const site = [];
            site[0] = index + 1;
            site[1] = bestSites[index].group_name;
            site[2] = bestSites[index].location_name;
            site[3] = bestSites[index].down_cnt.toString().concat(' Minutes');
            site[4] = bestSites[index].up_cnt.toString().concat(' Minutes');
            site[5] = bestSites[index].down_time_in_hh_mm.toString();
            site[6] = bestSites[index].up_time_in_hh_mm.toString();
            site[7] = bestSites[index].down_time_percent.toString().concat(' %');
            site[8] = bestSites[index].up_time_percent.toString().concat(' %');
            bestSitessArr.push(site)
        }
        
        const headers = ["Sl.No", "Group Name", "Site Name", "Down Time (in Minutes)", "Up Time (in Minutes)", "Down Time (in HH:MM)", "Up Time (in HH:MM)", "Down Time (in %)", "Up Time (in %)"];

        setSiteHeader(headers)
        setSiteData(bestSitessArr);
    }

    const generateVendorReportCSV = (bestVendors) => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        const bestVendorsArr = [];
        // console.log(bestVendors)
        for (let index = 0; index < bestVendors.length; index += 1) {
            const vendor = [];
            vendor[0] = index + 1;
            vendor[1] = bestVendors[index].vendor;
            vendor[2] = bestVendors[index].avgUptime.toString().concat('%');
            vendor[3] = convertMinToHourMinutes(bestVendors[index].downTime);
            vendor[4] = bestVendors[index].slaBreaches;
            vendor[5] = convertMinToHourMinutes(bestVendors[index].businessHourMetrics);
            bestVendorsArr.push(vendor)
        }

        const headers = ["Sl.No", "Vendor", "Avg Uptime in(%)", "Avg Restore Time", "SLA Breaches", "Business Hour Metrics"];
        setVendorHeader(headers)

        // console.log("Setting,Vendor Data")
        // console.log(bestVendorsArr);
        setVendorData(bestVendorsArr);
    }

    const setPresentMonthYear = async () => {
        const date = new Date();
        const month = date.toLocaleString("en-IN", { month: "2-digit" });
        const year = date.toLocaleString("en-IN", { year: "numeric" });
        setMonthYear(`${year}-${month}`)
    }

    useEffect(() => {
        setPresentMonthYear();
    }, [])


    const _setBestVendorsData = async () => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        await BestVendorsApiCall(buState, setBestVendors, setWorstVendors, monthYear, generateVendorReportCSV);
    }

    const _setBestSitesData = async () => {
        await BestSitesApiCall(buState, setBestSites, setWorstSites, monthYear, generateSiteReportCSV);
    }

    const _setBestLinksData = async () => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        await BestLinksApiCall(buState, setBestLinks, setWorstLinks, monthYear, generateLinkReportCSV);
    }
    

    useEffect(() => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        _setBestVendorsData();
    }, [monthYear, globalState, buState])

    useEffect(() => {
        _setBestSitesData();
    }, [monthYear, globalState, buState])


    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        _setBestLinksData();
    }, [monthYear, globalState, buState])

    const handleReportClose = () => {
        if (reportType === "1") {
            if (vendorReports === true) {
                generateVendorReport();
            }
            if (siteReports) {
                generateSiteReport();
            }
            if (linkReports) {
                generateLinkReport();

            }
        }
        else {
            if (vendorReports === true) {
                csvLinkVendors.current.link.click();
            }
            if (siteReports === true) {
                csvLinkSites.current.link.click();
            }
            if (linkReports) {
                csvLinkLinks.current.link.click();

            }
        }
        setReportType("1");
        setVendorReports(false);
        setSiteReports(false);
        setLinkReport(false)
        setOpenReportDialog(false);
    }

    const generateReports = () => {

        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        setOpenReportDialog(true)
    }
    return (


        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
                <Grid item xs={9} sm={9} md={9} lg={9} sx={{ px: 1, py: 1 }}>
                    <div className={classes.titleStyle}>
                        <Typography variant='h4'>
                            <strong>Reports</strong>
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} sx={{ px: 1, py: 1 }}>
                    <MonthYearPicker setMonthYear={setMonthYear} monthYear={monthYear} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: 1, py: 1 }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {/* <Tab label="Best Performing Details" /> */}
                        {/* <Tab label="Worst Performing Details" /> */}
                        <Tab label="Reports" />
                        <Tab label="Download" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Grid container sx={{ paddingTop: "20px" }}>
                            <Grid item xs={2} sm={2} md={2} lg={2} >
                                <ButtonGroup size="large" aria-label="outlined primary button group">
                                    <Button value="0" variant={linkVariant} onClick={handleHolisticChange}>Link</Button>
                                    <Button value="1" variant={vendorVariant} onClick={handleHolisticChange}>Vendor</Button>
                                    <Button value="2" variant={siteVariant} onClick={handleHolisticChange}>Sites</Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} >
                                {''}
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                <Button size="large" variant="contained" onClick={generateReports} sx={{ mx: 1 }}>Generate Reports</Button>
                            </Grid>
                        </Grid>
                        {!(globalState === custId && mspStatus === 1) && holisticValue === '0' && <Card variant="outlined" elevation={15} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <HollisticLinkView bestLinks={bestLinks} />
                            </Grid>
                        </Card>}
                        {!(globalState === custId && mspStatus === 1) && holisticValue === '1' && <Card variant="outlined" elevation={15} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <HollisticVendorView bestVendors={bestVendors} />
                            </Grid>
                        </Card>}
                        {!(globalState === custId && mspStatus === 1) && holisticValue === '2' && <Card variant="outlined" elevation={15} sx={{ marginTop: "20px", marginBottom: "20px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <HolisticSitesView bestSites={bestSites} />
                            </Grid>
                        </Card>}
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <Card variant="outlined" elevation={15} sx={{ marginTop: "1%" }}>
                            <FileSystemTreeView monthYear={monthYear} />
                        </Card>
                    </TabPanel>

                    {openReportsDialog && <ReportsDialogComponent openReportsDialog={openReportsDialog} setOpenReportDialog={setOpenReportDialog}
                        setVendorReports={setVendorReports} setSiteReports={setSiteReports} setLinkReport={setLinkReport} setReportType={setReportType} handleReportClose={handleReportClose} />}

                    <CSVLink data={vendorData} headers={vendorHeader} filename={`Vendors_Report_${monthYear}.csv`} ref={csvLinkVendors} />
                    <CSVLink data={siteData} headers={siteHeader} filename={`Sites_Report_${monthYear}.csv`} ref={csvLinkSites} />
                    <CSVLink data={linkData} headers={linkheader} filename={`Links_Report_${monthYear}.csv`} ref={csvLinkLinks} />
                </Grid>
                {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}
            </Grid>
        </Grid>
    );
}