import React, { useEffect, useState, forwardRef } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';

import Iconify from '../../Iconify';
import EdgeDeviceCustumTagDialog from './EdgeDeviceCustumTagDialog';
import GetEdgeDevicesDetailsApiCall from './GetEdgeDevicesDetailsApiCall';
import AddEdgeDeviceDialog from './AddEdgeDeviceDialog';
import DeleteEdgeDeviceDialog from './DeleteEdgeDeviceDialog';
import UpdateEdgeDeviceDialog from './UpdateEdgeDeviceDialog';
import PauseEdgeDeviceApiCall from './PauseEdgeDeviceApiCall';
import PauseEdgeDeviceDailog from './PauseEdgeDeviceDailog';
import CheckPortAndAuthStatusDialog from './CheckPortAndAuthStatusDialog';

import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';
import { useGlobalState } from '../../../globalHooks/GlobalState';

const useStyles = makeStyles({
    container: {
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px', // Adjust the width as desired
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
        },
    },
});

const headCells = [
    {
        id: 'slNo',
        numeric: true,
        disablePadding: true,
        label: 'Sl No',
    },
    {
        id: 'cust_name',
        numeric: false,
        disablePadding: false,
        label: 'Customer',
    },
    {
        id: 'hostName',
        numeric: false,
        disablePadding: false,
        label: 'Host Name',
    },
    {
        id: 'deviceIp',
        numeric: false,
        disablePadding: false,
        label: 'Device IP',
    },
    {
        id: 'devicetype',
        numeric: false,
        disablePadding: false,
        label: 'Device Type',
    },
    {
        id: 'vendor name',
        numeric: false,
        disablePadding: false,
        label: 'Vendor Name',
    },
    {
        id: 'connected links',
        numeric: true,
        disablePadding: false,
        label: 'Connected Links',
    },
    {
        id: 'ssh port status',
        numeric: true,
        disablePadding: false,
        label: 'SSH Port Status',
    },
    {
        id: 'ssh auth status',
        numeric: true,
        disablePadding: false,
        label: 'SSH Auth Status',
    },
    {
        id: 'snmp port status',
        numeric: true,
        disablePadding: false,
        label: 'SNMP Port Status',
    },
    {
        id: 'snmp auth status',
        numeric: true,
        disablePadding: false,
        label: 'SNMP Auth Status',
    },
    {
        id: 'location',
        numeric: false,
        disablePadding: true,
        label: 'Location',
    },
    {
        id: 'last updated',
        numeric: false,
        disablePadding: true,
        label: 'Last Updated',
    },
    {
        id: 'Actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },

];

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    marginLeft: 'auto',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

function EnhancedTableHead({ showCustomer }) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    (headCell.id !== 'cust_name' || showCustomer) &&
                    <TableCell
                        sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px' }}
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    showCustomer: PropTypes.bool.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({ filterName, onFilterName, handleUploadClick, handleAddClick, handleDeviceCustumTag, isCompact }) {
    return (
        <RootStyle>

            <Typography variant='h4'>
                Edge Devices
            </Typography>
            <Box
                width="75%"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                paddingRight="10px"
                position="relative"

            >

                <Box
                    width="10px"
                />
                <Button
                    variant="contained"
                    endIcon={<AddIcon />}
                    sx={{ width: "150px", height: "40px", paddingLeft: "10px" }}
                    onClick={handleAddClick}>
                    New
                </Button>

            </Box>

            <SearchStyle
                value={filterName}
                onChange={onFilterName}
                onKeyDown={onFilterName}
                onKeyUp={onFilterName}
                placeholder="Search..."
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                }
            />
        </RootStyle>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    isCompact: PropTypes.bool.isRequired,
};

export default function EdgeDevicesTable() {

    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();
    const { buName, setBuName } = useGlobalState();


    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspClientId = localStorage.getItem("cust_id");
    const regClientId = localStorage.getItem("reg_cust_id");
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

    const isEngineer = (localStorage.getItem("role") === "2")
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [value, setValue] = useState({});

    const [openEdgeDeviceCustumTag, setOpenEdgeDeviceCustumTag] = useState(false);
    const [edCustumTagStatus, setEdCustumTagStatus] = useState(0);

    const [openAddEdDailog, setOpenAddEdDialog] = useState(false);
    const [addEdStatus, setAddEdStatus] = useState(0);

    const [openUpdateEdDialog, setOpenUpdateEdDialog] = useState(false)
    const [updateEdStatus, setUpdateEdStatus] = useState(0)

    const [openDeleteEdDialog, setOpenDeleteEdDialog] = useState(false);
    const [deleteEdStatus, setDeleteEdStatus] = useState(0);

    const [edCustumTagMessage, setEdCustumTagMessage] = useState("")
    const [AddEdMessage, setAddEdMessage] = useState("")
    const [updateEdMessage, setUpdateEdMessage] = useState("")

    const [openWarnDialog, setOpenWarnDialog] = useState(false);
    const [locIdNames, setLocIdNames] = useState([]);

    const [pauseDeviceId, setPauseDeviceId] = useState(-1);
    const [pauseValue, setPauseValue] = useState(-1);
    const [pauseDeviceIp, setPauseDeviceIp] = useState("");
    const [openPauseDialog, setOpenPauseDialog] = useState(false);
    const [pauseOpen, setPauseOpen] = useState(false);

    const [openCustomerWarnDialog, setOpenCustomerWarnDialog] = useState(false);
    const [openCheckPortAndAuthStatusDialog, setCheckPortAndAuthStatusDialog] = useState(false);

    const Alert = forwardRef((props, ref) => (
        <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    ));
    console.log("rows=", rows)
    console.log('Parent Component - edCustumTagStatus:', edCustumTagStatus);
    console.log('Parent Component - edCustumTagMessage:', edCustumTagMessage);

    const handleOnEdit = (e, row) => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setValue(row);
        setOpenUpdateEdDialog(true);
    }

    const handleOnCheckStatus = (e, row) => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setValue(row);
        setCheckPortAndAuthStatusDialog(true);
    }
    const handleDelete = (e, row) => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setValue(row);
        setOpenDeleteEdDialog(true);
    }


    const handleFilterByName = async (event, searchedVal) => {
        setFilterName(event.target.value);
        setFilteredRows(rows);

        const filteredRows = rows.filter((row) => {
            return (
                (row.host_name && row.host_name.toLowerCase().includes(searchedVal.toLowerCase())) ||
                (row.device_ip && row.device_ip.toLowerCase().includes(searchedVal.toLowerCase())) ||
                (row.device_type && row.device_type.toLowerCase().includes(searchedVal.toLowerCase())) ||
                (row.vendor_name && row.vendor_name.toLowerCase().includes(searchedVal.toLowerCase())) ||
                (row.location_name && row.location_name.toLowerCase().includes(searchedVal.toLowerCase()))
            );
        });

        setFilteredRows(filteredRows);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const _GetEdgeDevicesDetailsApiCall = async () => {
        await GetEdgeDevicesDetailsApiCall(setRows, setFilteredRows, setLocIdNames, buState);
    }

    useEffect(() => {
        _GetEdgeDevicesDetailsApiCall();
    }, [addEdStatus, deleteEdStatus, updateEdStatus, globalState, buState, edCustumTagStatus])

    const resetAll = () => {
        setAddEdStatus(0);
        setOpenAddEdDialog(false);

        setDeleteEdStatus(0);
        setOpenDeleteEdDialog(false);

        setUpdateEdStatus(0);
        setOpenUpdateEdDialog(false);

        setPauseOpen(false)

        setEdCustumTagStatus(null);
        setEdCustumTagMessage('');
        setOpenCustomerWarnDialog(false);
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        resetAll();
    };

    const handleUploadClick = () => {
        resetAll();
        setValue(rows);
    }

    const handleAddClick = () => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setOpenAddEdDialog(true);
    }

    const handleDeviceCustumTag = () => {
        resetAll();
        setOpenEdgeDeviceCustumTag(true);
    }

    const handlePauseDevice = async (pauseStatus) => {

        if (pauseStatus === true) {
            await PauseEdgeDeviceApiCall(pauseDeviceId, pauseDeviceIp, pauseValue, setPauseOpen);
            await GetEdgeDevicesDetailsApiCall(setRows, setFilteredRows, setLocIdNames, buState);
        }
        setOpenPauseDialog(false);
        setPauseDeviceId(-1);
        setPauseDeviceIp("");
    }

    const handlePauseDialog = (pauseDeviceId, pauseDeviceIp, value) => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return;
        }
        setPauseDeviceIp(pauseDeviceIp);
        setPauseDeviceId(pauseDeviceId);
        setPauseValue(value)
        setOpenPauseDialog(true);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={15}>
                <EnhancedTableToolbar
                    filterName={filterName}
                    onFilterName={(e) => { handleFilterByName(e, filterName) }}
                    handleUploadClick={handleUploadClick}
                    handleAddClick={handleAddClick}
                    handleDeviceCustumTag={handleDeviceCustumTag}

                />
                <TableContainer className={classes.container}>
                    <Table stickyHeader
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            showCustomer={mspClientId === regClientId && mspStatus === 1}
                            numSelected={selected.length}
                            rowCount={filteredRows.length}
                        />
                        <TableBody>
                            {filteredRows.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.zone_id}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        {mspClientId === regClientId && mspStatus === 1 ? (<TableCell align="left">{row.cust_name}</TableCell>) : null}
                                        <TableCell align="left">{row.host_name}</TableCell>
                                        <TableCell align="left">{row.device_ip}</TableCell>
                                        <TableCell align="left">{row.device_type}</TableCell>
                                        <TableCell align="left">{row.vendor_name}</TableCell>
                                        <TableCell align="center">{row.isp_cnt}</TableCell>
                                        {row.ssh_port_status === 1 && row.device_vendor_id !== 10 && <TableCell align="center"><CheckIcon style={{ color: 'green' }} /></TableCell>}
                                        {row.ssh_port_status === 0 && row.device_vendor_id !== 10 && <TableCell align="center"><ClearIcon style={{ color: 'red' }} /></TableCell>}
                                        {row.ssh_port_status !== 0 && row.ssh_port_status !== 1 && row.device_vendor_id !== 10 && <TableCell align="center">{""}</TableCell>}
                                        {row.device_vendor_id === 10 && <TableCell align="center" style={{ color: "blue", fontWeight: "bold" }}>{"N/A"}</TableCell>}

                                        {row.ssh_auth_status === 1 && row.device_vendor_id !== 10 && <TableCell align="center"><CheckIcon style={{ color: 'green' }} /></TableCell>}
                                        {row.ssh_auth_status === 0 && row.device_vendor_id !== 10 && <TableCell align="center"><ClearIcon style={{ color: 'red' }} /></TableCell>}
                                        {row.ssh_auth_status !== 0 && row.ssh_auth_status !== 1 && row.device_vendor_id !== 10 && <TableCell align="center">{""}</TableCell>}
                                        {row.device_vendor_id === 10 && <TableCell align="center" style={{ color: "blue", fontWeight: "bold" }}>{"N/A"}</TableCell>}


                                        {row.snmp_port_status === 1 && <TableCell align="center"><CheckIcon style={{ color: 'green' }} /></TableCell>}
                                        {row.snmp_port_status === 0 && <TableCell align="center"><ClearIcon style={{ color: 'red' }} /></TableCell>}
                                        {row.snmp_port_status !== 0 && row.snmp_port_status !== 1 && <TableCell align="center">{""}</TableCell>}

                                        {row.snmp_auth_status === 1 && <TableCell align="center"><CheckIcon style={{ color: 'green' }} /></TableCell>}
                                        {row.snmp_auth_status === 0 && <TableCell align="center"><ClearIcon style={{ color: 'red' }} /></TableCell>}
                                        {row.snmp_auth_status !== 0 && row.snmp_auth_status !== 1 && <TableCell align="center">{""}</TableCell>}
                                        <TableCell align="left">{row.location_name}</TableCell>
                                        <TableCell align="left">{row.updated_time}</TableCell>
                                        <TableCell align="center">
                                            <Grid direction="row">
                                                {row.device_id !== null && row.device_ip !== "" && (
                                                    <IconButton variant="outlined" color="primary" onClick={(e) => { handleOnCheckStatus(e, row) }}>
                                                        <Button variant="contained">Check Status</Button>
                                                    </IconButton>
                                                )}
                                                {row.device_id !== null && <IconButton variant="outlined" color="primary" onClick={(e) => { handleOnEdit(e, row) }}><EditIcon /></IconButton>}
                                                {!isEngineer && row.device_id !== null && <IconButton variant="outlined" color="error" onClick={(e) => { handleDelete(e, row) }}><DeleteIcon /></IconButton>}
                                                {row.pause_status === 0 && <IconButton variant="outlined" value={row.device_id} color="error" onClick={() => { handlePauseDialog(row.device_id, row.device_ip, 1) }}><StopCircleIcon /></IconButton>}
                                                {row.pause_status === 1 && <IconButton variant="outlined" value={row.device_id} color="primary" onClick={() => { handlePauseDialog(row.device_id, row.device_ip, 0) }}><SmartDisplayIcon /></IconButton>}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>


                {openEdgeDeviceCustumTag && (<EdgeDeviceCustumTagDialog openEdgeDeviceCustumTag={openEdgeDeviceCustumTag} setOpenEdgeDeviceCustumTag={setOpenEdgeDeviceCustumTag} setEdCustumTagStatus={setEdCustumTagStatus} setEdCustumTagMessage={setEdCustumTagMessage} />)}

                {openAddEdDailog && <AddEdgeDeviceDialog locIdNames={locIdNames} openAddEdDailog={openAddEdDailog} setOpenAddEdDialog={setOpenAddEdDialog} setAddEdStatus={setAddEdStatus} setAddEdMessage={setAddEdMessage} buName={buName} />}
                {openDeleteEdDialog && <DeleteEdgeDeviceDialog value={value} openDeleteEdDialog={openDeleteEdDialog} setOpenDeleteEdDialog={setOpenDeleteEdDialog} setDeleteEdStatus={setDeleteEdStatus} />}
                {openUpdateEdDialog && <UpdateEdgeDeviceDialog value={value} openUpdateEdDialog={openUpdateEdDialog} setOpenUpdateEdDialog={setOpenUpdateEdDialog} setUpdateEdStatus={setUpdateEdStatus} setUpdateEdMessage={setUpdateEdMessage} />}

                {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}
                {openPauseDialog && <PauseEdgeDeviceDailog pauseValue={pauseValue} pauseDeviceIp={pauseDeviceIp} openPauseDialog={openPauseDialog} handlePauseDevice={handlePauseDevice} />}
                {openCheckPortAndAuthStatusDialog && <CheckPortAndAuthStatusDialog value={value} openCheckPortAndAuthStatusDialog={openCheckPortAndAuthStatusDialog} setCheckPortAndAuthStatusDialog={setCheckPortAndAuthStatusDialog} />}
            </Paper>

            {addEdStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {AddEdMessage}
                </Alert>
            </Snackbar>}
            {addEdStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {AddEdMessage}
                </Alert>
            </Snackbar>}
            {edCustumTagStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {edCustumTagMessage}
                </Alert>
            </Snackbar>}

            {edCustumTagStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {edCustumTagMessage}
                </Alert>
            </Snackbar>}

            {deleteEdStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    Edge Device Deleted Successfully
                </Alert>
            </Snackbar>}
            {deleteEdStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error in deleting Edge Device
                </Alert>
            </Snackbar>}

            {updateEdStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {updateEdMessage}
                </Alert>
            </Snackbar>}
            {updateEdStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {updateEdMessage}
                </Alert>
            </Snackbar>}

            <Snackbar open={pauseOpen} autoHideDuration={6000} onClose={handleSnackClose} key={'bottom + right'}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {pauseValue === 1 && <Typography>Edge Device Paused Successfully.</Typography>}
                    {pauseValue === 0 && <Typography>Edge Device Resumed Successfully.</Typography>}
                </Alert>
            </Snackbar>

        </Box>
    );
}