import React, { useState, useEffect, useCallback } from "react";
// import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  removeElements,
  addEdge,
  MiniMap,
  Controls,
  Background,
  SmoothStepEdge,
  Position,
  Handle
} from "react-flow-renderer";
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { MarkerType, ReactFlowProvider } from 'reactflow';
import { Popover, Typography, Grid, Card, Divider, Paper } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import RouterIcon from '@mui/icons-material/Router';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GetTraceRouteAPICall from './GetTraceRouteDataAPICall';
import monitorImgPath from '../../Assets/monitor.png';
import asnGreenImgPath from '../../Assets/asn green.png';
import asnRedImgPath from '../../Assets/asn red.png';
import timeoutImgPath from '../../Assets/timeout.png';
import destinationImgPath from '../../Assets/destination.png';
import routerGreenImgPath from '../../Assets/router green.png';
import routerRedImgPath from '../../Assets/router red.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const initialNodes = [
  {
    id: "node-1",
    sourcePosition: "right",
    type: "input",
    data: {
      label: "Source",
      name: "Network"
    },
    position: { x: 10, y: 0 },
  },
];

const initialEdges = [
  {
    id: "node-e1-2",
    source: "node-1",
    type: "bezier",
    target: "node-2",
    animated: true,
    style: { stroke: "#FF5733" }
  },
];

// Custom hover style , tool tip
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

// to display the current date & time
const getCurrentDateTime = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const TracerouteGraph = (props) => {

  const { locationId, appId } = props; // props from ApplicationMonitorGraphs.js

  const [nodesVal, setNodeVal, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [open, setOpen] = React.useState(false);
  const [fullDialog, setFullDialog] = useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params }, eds)),
    []
  );
  const handleClickDialogOpen = () => {
    setFullDialog(true);
  };

  const handleCloseDialog = () => {
    setFullDialog(false);
  };

  const [activeStep, setActiveStep] = useState(71); // selects the last or the latest dot so that the latest data is displayed
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const [timeData, setTimeData] = useState(getCurrentDateTime() || []);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [monitorImg, setMonitorImg] = useState(new Image());
  const [asnGreenImg, setAsnGreenImg] = useState(new Image());
  const [asnRedImg, setAsnRedImg] = useState(new Image());
  const [timeoutImg, setTimeoutImg] = useState(new Image());
  const [destinationImg, setDestinationImg] = useState(new Image());
  const [routerGreenImg, setRouterGreenImg] = useState(new Image());
  const [routerRedImg, setRouterRedImg] = useState(new Image());
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null); // State for tooltip content
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 }); // State for tooltip position
  const [isTooltipVisible, setIsTooltipVisible] = useState(false); // State for tooltip visibility
  const [pathContent, setPathContent] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreenToggle = () => {
    setIsFullScreen(!isFullScreen);
  };

  // const handle = useFullScreenHandle();

  // Availability left arrow control
  const handleLeftArrowClick = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      handleStep(activeStep - 1);
    }
  };

  // Availability right arrow control
  const handleRightArrowClick = () => {
    if (activeStep < totalSteps - 1) {
      setActiveStep(activeStep + 1);
      handleStep(activeStep + 1);
    }
  };

  const loadImage = (src, setImage) => {
    const image = new Image();
    image.onload = () => setImage(image);
    image.src = src;
  };

  useEffect(() => {
    loadImage(monitorImgPath, setMonitorImg);
    loadImage(asnGreenImgPath, setAsnGreenImg);
    loadImage(asnRedImgPath, setAsnRedImg);
    loadImage(timeoutImgPath, setTimeoutImg);
    loadImage(destinationImgPath, setDestinationImg);
    loadImage(routerGreenImgPath, setRouterGreenImg);
    loadImage(routerRedImgPath, setRouterRedImg);
  }, []);



  const nodeImageMap = {
    // SOURCE: monitorImg,
    // PRIVATE: [routerGreenImg, routerRedImg],
    // ASN: [asnGreenImg, asnRedImg],
    // TIMEOUT: timeoutImg,
    // DESTINATION: destinationImg,
  };


  const theme = useTheme();

  const totalSteps = 72;

  // availabilty api call with time update.

  const handleStep = (step) => {
    setActiveStep(step);
    if (step !== activeStep) {
      const dateTime = getDateTimeFromStep(step);
      setTimeData(dateTime);
      GetTraceRouteAPICall(appId, locationId, dateTime, setNodes, setLinks);
    } else {
      const currentTimeData = getCurrentDateTime();
      setTimeData(currentTimeData);
      GetTraceRouteAPICall(appId, locationId, currentTimeData, setNodes, setLinks);
    }
  };

  // set the current time on the left corner
  useEffect(() => {
    if (timeData) {
      const timeOptions = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      };
      const formattedTime = new Date(timeData).toLocaleTimeString(undefined, timeOptions);
      setTooltipContent(formattedTime);
    }
  }, [timeData]);

  // function to get the color from latency on the node border/outline  
  const getColorFromLatency = (latency, nodeType, label) => {
    const latencyValue = parseFloat(latency);
    if (Number.isNaN(latencyValue) && nodeType !== "TIMEOUT" && (label !== " " || label !== null)) {
      return "gray";
    }
    if (latencyValue > 0 && latencyValue < 30) {
      return "green";
    }
    if ((Number.isNaN(latencyValue) || latencyValue === 0) && nodeType === "TIMEOUT" && (label === " " || label === null)) {
      return "red";
    }
    if (latencyValue === 0 && nodeType !== "TIMEOUT" && (label !== " " || label !== null)) {
      return "gray";
    }
    return "red";
  };

  const transformNodes = (nodesData, edgesData, nodeImageMap) => {
    // Function to calculate dynamic distance based on label lengths
    const calculateDynamicDistance = (nodes) => {
      const baseDistance = 150;
      const maxLabelLength = Math.max(...nodes.map(node => (node.label ? node.label.length : 0)));
      return baseDistance + maxLabelLength * 4; // distance between the nodes 
    };

    const dynamicDistance = calculateDynamicDistance(nodesData);

    const nodePositions = nodesData.map((node) => {
      const position = node.position || { x: 20, y: 0 }; // Use existing position if available
      return {
        id: node.node_id,
        position,
        repeated: false,
        originalX: position.x,
      };
    });
    const totalWidth = nodesData.length * dynamicDistance; // Use dynamic distance
    const startX = (800 - totalWidth) / 2; // calculates the starting x position & gets the nodes in center within the horizontal container.

    const updateNodePositions = (sourceNodeId, targetNodeId) => {
      const sourceNodeIndex = nodePositions.findIndex((node) => node.id === sourceNodeId); // finds the index of source
      const targetNodeIndex = nodePositions.findIndex((node) => node.id === targetNodeId); // finds the index of destination

      if (sourceNodeIndex !== -1 && targetNodeIndex !== -1) {
        const sourceNodeRepeated = nodePositions[sourceNodeIndex].repeated;
        const targetNodeRepeated = nodePositions[targetNodeIndex].repeated;

        if (sourceNodeRepeated && targetNodeRepeated) {
          nodePositions[targetNodeIndex].position.x = nodePositions[sourceNodeIndex].position.x + dynamicDistance;
          nodePositions[targetNodeIndex].position.y = nodePositions[sourceNodeIndex].position.y + dynamicDistance;
        }

        if (!sourceNodeRepeated) {
          nodePositions[sourceNodeIndex].repeated = true;
        }
        if (!targetNodeRepeated) {
          nodePositions[targetNodeIndex].repeated = true;
        }
      }
    };

    // dotted lines 
    edgesData.forEach((edge) => {
      const sourceNodeId = edge.source_id;
      const targetNodeId = edge.target_id;

      updateNodePositions(sourceNodeId, targetNodeId);
    });

    const nodePositionsWrapped = [];
    const visitedNodes = new Set();
    const nodeMap = new Map(nodesData.map(node => [node.node_id, node]));

    const addNodeAndChildren = (nodeId, referenceX = 0, referenceY = 0) => {
      if (visitedNodes.has(nodeId)) return;
      visitedNodes.add(nodeId);

      const node = nodeMap.get(nodeId);
      if (!node) return;

      let positionX;
      let positionY;

      if (referenceX !== 0 || referenceY !== 0) {
        positionX = referenceX;
        positionY = referenceY;
      } else {
        const index = nodePositionsWrapped.length;
        positionX = index * dynamicDistance;
        positionY = 150;
      }

      let collisionDetected = false;
      for (let i = 0; i < nodePositionsWrapped.length; i += 1) {
        const existingNode = nodePositionsWrapped[i];
        if (Math.abs(existingNode.position.x - positionX) < 100 && Math.abs(existingNode.position.y - positionY) < 100) {
          collisionDetected = true;
          break;
        }
      }

      if (collisionDetected) {
        positionX += 0;
        positionY += dynamicDistance;
      }
      nodePositionsWrapped.push({
        ...node,
        position: { x: positionX, y: positionY }
      });

      let childOffsetY = 0;
      edgesData.forEach((edge) => {
        if (edge.source_id === nodeId) {
          const childNode = nodeMap.get(edge.target_id);
          if (childNode) {
            addNodeAndChildren(edge.target_id, positionX + dynamicDistance, positionY + childOffsetY);
            childOffsetY += dynamicDistance; // Increment offset to avoid overlap
          }
        }
      });
    };

    for (let i = 0; i < nodesData.length; i += 1) {
      const node = nodesData[i];
      if (!visitedNodes.has(node.node_id)) {
        addNodeAndChildren(node.node_id);
      }
    }

    const reactFlowNodes = [];
    const nodeLatencies = new Map();

    // Collecting incoming latencies for each node
    edgesData.forEach((edge) => {
      const targetLatency = parseFloat(edge.latency);
      if (!Number.isNaN(targetLatency)) {
        if (!nodeLatencies.has(edge.target_id)) {
          nodeLatencies.set(edge.target_id, []);
        }
        nodeLatencies.get(edge.target_id).push(targetLatency);
      }
    });

    for (let i = 0; i < nodePositionsWrapped.length; i += 1) {
      const currentNode = nodePositionsWrapped[i];

      // Determine the maximum incoming latency for the current node
      const latencies = nodeLatencies.get(currentNode.node_id) || [];
      const maxLatency = Math.max(0, ...latencies);

      let backgroundImage = "";
      if (currentNode.type && currentNode.type in nodeImageMap) {
        if (Array.isArray(nodeImageMap[currentNode.type])) {
          const color = getColorFromLatency(maxLatency, currentNode.type, currentNode.label);
          const index = color === "green" ? 0 : 1;
          backgroundImage = nodeImageMap[currentNode.type][index]?.src || "";
        } else {
          backgroundImage = nodeImageMap[currentNode.type]?.src || "";
        }
      }
      let borderColor = "";
      if (currentNode.type === "SOURCE" || currentNode.type === "DESTINATION") {
        borderColor = "black";
      } else if (currentNode.type === "TIMEOUT") {
        borderColor = "red";
      } else {
        borderColor = getColorFromLatency(maxLatency, currentNode.type, currentNode.label);
      }
      reactFlowNodes.push({
        id: currentNode.node_id,
        sourcePosition: "right",
        targetPosition: "left",
        data: {
          label: (
            <>
              <div style={{
                marginTop: "120px",
                width: "130px",
                marginLeft: "2px",
                wordBreak: "break-word",
                whiteSpace: "normal",
                color: 'black',
                fontWeight: 'bold',
              }}>
                {currentNode.label}
              </div>
              <div style={{ marginTop: "5px", fontWeight: 'bold' }}>
                {currentNode.name === 'T' ? 'Timeout' : currentNode.name}
              </div>
            </>
          )
        },
        position: currentNode.position,
        //   let borderColor = "";
        //   if(currentNode.type === "SOURCE" || currentNode.type === "DESTINATION") {
        //   borderColor = "black";
        // } else if (currentNode.type === "Timeout") {
        //   borderColor = "red";
        // } else {
        //   borderColor = getColorFromLatency(maxLatency, currentNode.type, currentNode.label);
        // }
        style: {
          borderColor,
          borderWidth: "3px",
          background: "white",
          width: 40,
          height: 40,
          borderRadius: 80,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
        },
      });
    }


    return reactFlowNodes;
  };

  const transformEdges = (edgesData, nodesData) => {
    return edgesData.map((edge, i) => {
      const sourceNode = nodesData.find(node => node.id === edge.source_id);
      const targetNode = nodesData.find(node => node.id === edge.target_id);

      if (!sourceNode || !targetNode) {
        return null;
      }
      const edgeColor = getColorFromLatency(edge.latency);

      const labelX = (sourceNode.position.x + targetNode.position.x) / 2;
      const labelY = (sourceNode.position.y + targetNode.position.y) / 2;

      const nodeWidth = 80;
      const nodeHeight = 80;
      const offsetX = nodeWidth / 2;
      const offsetY = nodeHeight / 2;
      const labelWidth = edge.label ? edge.label.length * 2 : 0;
      const labelOffsetX = labelWidth / 2;
      const label = edge.latency ? `${edge.latency} ms` : "";
      const stroke = edge.latency === null || edge.latency === 0 ? 4 : 6;
      return {
        id: `edge-${i + 1}`,
        source: edge.source_id,
        target: edge.target_id,
        label,
        labelStyle: { fontSize: "10px", fill: "#000" },
        labelBgPadding: [4, 4],
        labelBgBorderRadius: 4,
        labelBgStyle: { fill: "#fff", stroke: "#000" },
        animated: !!label,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        style: {
          stroke: edgeColor,
          strokeWidth: stroke,
          strokeDasharray: "5,5",
        },
        labelX: labelX - labelOffsetX,
        labelY,
      };
    }).filter(edge => edge !== null);

  };

  

  useEffect(() => {
    const transformedNodes = transformNodes(nodes, links, nodeImageMap);
    const transformedEdges = transformEdges(links, transformedNodes);
    setNodeVal(transformedNodes);
    setEdges(transformedEdges);
  }, [nodes, links, setNodeVal, setEdges]);

  const currentTimeData = timeData || getCurrentDateTime();

  useEffect(() => {
    const currentTimeData = timeData || getCurrentDateTime();
    GetTraceRouteAPICall(appId, locationId, currentTimeData, setNodes, setLinks);
  }, [appId, locationId, timeData]);

  // availability control function

  const renderDots = () => {
    const dotInterval = 20 * 60 * 1000;
    const totalDots = totalSteps;
    const currentDate = new Date();

    const dots = [];

    const getCurrentTime = (dotDate) => {
      return `Time: ${dotDate.toLocaleTimeString()}, Date: ${dotDate.toLocaleDateString()}`;
    };

    for (let i = 0; i < totalDots; i += 1) {
      let dotDate;

      if (i < totalDots - 1) {
        dotDate = new Date(currentDate.getTime() - (totalDots - 1 - i) * dotInterval);
      } else {
        dotDate = currentDate;
      }

      const currentTime = getCurrentTime(dotDate);

      dots.push(
        <BootstrapTooltip
          placement="top"
          key={i}
          title={currentTime}
        >
          <div
            key={i}
            onClick={() => handleStep(i)}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                handleStep(i);
              }
            }}
            role="button"
            tabIndex={0}
            aria-label={`Step ${i + 1}`}
            style={{
              cursor: "pointer",
              margin: "0 3px",
              width: 12,
              height: 12,
              borderRadius: "50%",
              backgroundColor:
                activeStep === i
                  ? theme.palette.primary.main
                  : theme.palette.grey[400],
            }}
          />
        </BootstrapTooltip>
      );
    }
    return dots;
  };

  // get and update time to each dots
  const getDateTimeFromStep = (step) => {
    const dotInterval = 20 * 60 * 1000;
    const totalDots = totalSteps;
    const currentDate = new Date();

    const dotIndex = totalDots - 1 - step;

    const finalDate = new Date(currentDate.getTime() - dotIndex * dotInterval);

    const year = finalDate.getFullYear();
    const month = String(finalDate.getMonth() + 1).padStart(2, '0');
    const day = String(finalDate.getDate()).padStart(2, '0');
    const hours = String(finalDate.getHours()).padStart(2, '0');
    const minutes = String(finalDate.getMinutes()).padStart(2, '0');
    const seconds = String(finalDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const css = `
  .react-flow__attribution a {
      font-size: 0;
  }`

  const handleEdgeClick = (event, edge) => {
    setOpen(true);

    // Find source and target nodes based on edge source and target
    const sourceNode = nodes.find(node => node.node_id === edge.source);
    const targetNode = nodes.find(node => node.node_id === edge.target);

    // Function to format node labels
    const formatNodeLabel = (node) => {
      if (!node) return '';
      const name = node.name === 'T' ? 'Timeout' : node.name;
      const label = node.label === 'T' ? 'Timeout' : node.label;
      return `${label} (${name})`;
    }

    // Function to format node display
    const formatNode = (node) => {
      if (!node) return '';
      const formattedLabel = formatNodeLabel(node);
      const [label, name] = formattedLabel.split(' (');
      const nameWithoutClosingBracket = name.slice(0, -1);
      return (
        <span>
          <strong>{label}</strong> (<span style={{ fontWeight: 'normal' }}>{nameWithoutClosingBracket}</span>)
        </span>
      );
    }

    if (sourceNode && targetNode) {
      if (edge.source === sourceNode.node_id && edge.target === targetNode.node_id) {
        // Check if either source or target node is a TIMEOUT type
        if (sourceNode.type === 'TIMEOUT' || targetNode.type === 'TIMEOUT') {
          setPathContent(
            <Grid container direction="column">
              <Grid item>
                {formatNode(sourceNode)}
                <span style={{ fontWeight: 'bold', fontSize: '20px' }}> &#8594; </span>
                {formatNode(targetNode)}
              </Grid>
              <Grid item>
                <span style={{ fontWeight: 'bold' }}>Latency </span>: {edge.label}
              </Grid>
              <Grid item>
                <span style={{ fontWeight: 'bold' }}>Jitter </span>: {`${sourceNode.jitter} ms`}
              </Grid>
              <Grid item>
                <span style={{ fontWeight: 'bold' }}>Packet Loss </span>: {`${sourceNode.packet_loss} %`}
              </Grid>
            </Grid>
          );
        } else {
          setPathContent(
            <Grid container direction="column">
              <Grid item>
                {formatNode(sourceNode)}
                <span style={{ fontWeight: 'bold', fontSize: '20px' }}> &#8594; </span>
                {formatNode(targetNode)}
              </Grid>
              <Grid item>
                <span style={{ fontWeight: 'bold' }}>Latency </span>: {edge.label}
              </Grid>
              <Grid item>
                <span style={{ fontWeight: 'bold' }}>Jitter </span>: {`${sourceNode.jitter} ms`}
              </Grid>
              <Grid item>
                <span style={{ fontWeight: 'bold' }}>Packet Loss </span>: {`${sourceNode.packet_loss} %`}
              </Grid>
            </Grid>
          );
        }
      } else {
        setPathContent(null);
      }
    } else {
      setPathContent(null);
    }
  };
  const Legend = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: "20px 20px" }}>
        <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
            <div style={{ width: "30px", borderBottom: "4px dotted green" }} />
            <div style={{ width: "0", height: "0", borderLeft: "10px solid green", borderTop: "7px solid transparent", borderBottom: "7px solid transparent" }} />
          </div>
          <span style={{ fontWeight: "bold" }}>Active Path with latency less than 30ms</span>
        </div>
        <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
            <div style={{ width: "30px", borderBottom: "4px dotted red" }} />
            <div style={{ width: "0", height: "0", borderLeft: "10px solid red", borderTop: "7px solid transparent", borderBottom: "7px solid transparent" }} />
          </div>
          <span style={{ fontWeight: "bold" }}>Active Path with latency greater than 30ms</span>
        </div>
        <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
            <div style={{ width: "30px", borderBottom: "4px dotted #B2BEB5" }} />
            <div style={{ width: "0", height: "0", borderLeft: "10px solid #B2BEB5", borderTop: "7px solid transparent", borderBottom: "7px solid transparent" }} />
          </div>
          <span style={{ fontWeight: "bold" }}>Alternative Path</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Paper elevation={24}
        sx={{
          width: '100%',
          position: isFullScreen ? 'fixed' : 'relative',
          top: isFullScreen ? 0 : 'auto',
          left: isFullScreen ? 0 : 'auto',
          height: isFullScreen ? '100vh' : 'auto',
          zIndex: isFullScreen ? 1300 : 'auto',
          overflowY: isFullScreen ? 'auto' : 'hidden',
          overflowX: isFullScreen ? 'auto' : 'hidden',
          scrollbarWidth: 'thin'
        }}>
        <Card sx={{ width: '100%', height: isFullScreen ? 'auto' : 'auto' }}>
          <CardContent>
            <Grid container>
              <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '7vh' }}>
                <Grid item sx={{ display: 'flex', alignItems: 'center', marginTop: '-15px' }}>
                  <h2 style={{ margin: 0 }}>Forward TracePath (Beta)</h2>
                  <IconButton onClick={handleFullScreenToggle} sx={{ marginLeft: '10px' }}>
                    {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
                  </IconButton>
                </Grid>
                <Grid item sx={{ marginTop: '-15px' }}>
                  <Legend />
                </Grid>
              </Grid>
              <Grid item sx={{ width: '100%' }}>
                <div className="traceroute-graph-container" style={{ height: isFullScreen ? '91vh' : '380px', width: '100%', marginTop: '-40px', marginBottom: '20px' }}>
                  <style>{css}</style>
                  <ReactFlowProvider style={{ marginLeft: '90%' }}>
                    <ReactFlow
                      style={{
                        width: '100%',
                        marginTop: '-10px',
                        overflowX: isFullScreen ? 'auto' : 'auto',
                        overflowY: isFullScreen ? 'auto' : 'auto',
                        whiteSpace: 'nowrap',
                        scrollbarWidth: 'thin',
                        paddingLeft: '2%',
                      }}
                      onEdgeClick={handleEdgeClick}
                      nodes={nodesVal}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      onConnect={onConnect}
                      attributionPosition="top-right"
                      nodesDraggable={false}
                      nodesConnectable={false}
                      zoomOnDoubleClick={false}
                      zoomOnPinch={false}
                    >
                      <Background color="#fff" />
                    </ReactFlow>
                    <Grid container spacing={2} justifyContent="center">
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '25px 10px' }}>
                        <span style={{ marginRight: '10px', alignSelf: 'center' }}>Availability:</span>
                        <KeyboardArrowLeft onClick={handleLeftArrowClick} />
                        {renderDots()}
                        <KeyboardArrowRight onClick={handleRightArrowClick} />
                      </div>
                    </Grid>
                  </ReactFlowProvider>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>


      {/* <Paper
        elevation={24}
        sx={{
          width: '100%',
          position: isFullScreen ? 'fixed' : 'relative',
          top: isFullScreen ? 0 : 'auto',
          left: isFullScreen ? 0 : 'auto',
          height: isFullScreen ? '100vh' : 'auto',
          zIndex: isFullScreen ? 1300 : 'auto', // Make sure the zIndex is higher to overlay other content
        }}
      >
        <Card sx={{ width: '100%', height: '100%' }}>
          <CardContent>
            <Grid container direction="column" sx={{ height: '100%' }}>
              <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '7vh' }}>
                <Grid item sx={{ display: 'flex', alignItems: 'center', marginTop: '-15px' }}>
                  <h2 style={{ margin: 0 }}>Forward TracePath (Beta)</h2>
                  <IconButton onClick={handleFullScreenToggle} sx={{ marginLeft: '10px' }}>
                    {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
                  </IconButton>
                </Grid>
                <Grid item sx={{ marginTop: '-15px' }}>
                  <Legend />
                </Grid>
              </Grid>
              <Grid item sx={{ width: '100%', flexGrow: 1 }}>
                <div className="traceroute-graph-container" style={{ height: '100%', width: '100%', marginTop: '-40px', marginBottom: '20px' }}>
                  <style>{css}</style>
                  <ReactFlowProvider>
                    <ReactFlow
                      style={{
                        width: '100%',
                        height: '100%', // Make ReactFlow take the full height
                        marginTop: '-10px',
                        overflowX: 'auto',
                        overflowY: 'auto',
                        whiteSpace: 'nowrap',
                        scrollbarWidth: 'thin',
                        paddingLeft: '2%',
                      }}
                      onEdgeClick={handleEdgeClick}
                      nodes={nodesVal}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      onConnect={onConnect}
                      attributionPosition="top-right"
                      nodesDraggable={false}
                      nodesConnectable={false}
                      zoomOnDoubleClick={false}
                      zoomOnPinch={false}
                    >
                      <Background color="#fff" />
                    </ReactFlow>
                    <Grid container spacing={2} justifyContent="center">
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '25px 10px' }}>
                        <span style={{ marginRight: '10px', alignSelf: 'center' }}>Availability:</span>
                        <KeyboardArrowLeft onClick={handleLeftArrowClick} />
                        {renderDots()}
                        <KeyboardArrowRight onClick={handleRightArrowClick} />
                      </div>
                    </Grid>
                  </ReactFlowProvider>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ '& .MuiPaper-root': { width: '600px', maxWidth: '80%' } }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Path Details
        </DialogTitle>
        <Divider />
        <DialogContent >
          <Typography gutterBottom>
            {pathContent}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default TracerouteGraph;
