import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    InputAdornment,
    FormHelperText
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';


// Api Import
import AddNewVendorApiCall from './AddNewVendorApiCall';


export default function AddNewVendorDialog(props) {
    const { openAddVendorDialog, setOpenAddVendorDialog, setAddVendorStatus, setAddVendorMessage } = props;

    const [vendorName, setVendorName] = useState("");
    const [vendorAddress, setVendorAddress] = useState("");
    const [vendorContact, setVendorContact] = useState("");
    const [helpDeskEmail, setHelpDeskEmail] = useState("");
    const [escalationLevel1Mail, setEscalationLevel1Mail] = useState("");
    const [escalationLevel2Mail, setEscalationLevel2Mail] = useState("");
    const [vendorCity, setVendorCity] = useState("");
    const [promisedUptime, setPromisedUptime] = useState("98");
    const [resolutionTime, setResolutionTime] = useState("4");

    const [vendorNameHelperText, setVendorNameHelperText] = useState("");
    const [vendorAddressHelperText, setVendorAddressHelperText] = useState("");
    const [vendorContactHelperText, setVendorContactHelperText] = useState("");
    const [helpDeskEmailHelper, setHelpDeskEmailHelper] = useState("");
    const [escalationLevel1MailHelper, setEscalationLevel1MailHelper] = useState("");
    const [escalationLevel2MailHelper, setEscalationLevel2MailHelper] = useState("");
    const [vendorCityHelper, setVendorCityHelper] = useState("");
    const [promisedUptimeHelper, setPromisedUptimeHelper] = useState("");
    const [resolutionTimeHelper, setResolutionTimeHelper] = useState("");


    const vendorNameRegex = /^[a-zA-Z][a-zA-Z0-9(),-_\s]*[A-Za-z\d]$/;
    const vendorAddressRegex = /^[a-zA-Z][a-zA-Z0-9,()-_\s]*[A-Za-z\d]$/;
    const vendorContactRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const cityRegex = /^[a-zA-Z][a-zA-Z0-9,()-_\s]*$/;
    const uptimeRegex = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/;
    const resolutionTimeRegex = /^(?:\d{1,2}|100)$/;

    const handleOnClose = () => {
        setOpenAddVendorDialog(false);
    }

    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "vendor_name": if (!e.target.value.match(vendorNameRegex)) {
                setVendorNameHelperText("Vendor Name Should be Valid");
            }
            else {
                setVendorNameHelperText("");

            }
                setVendorName(e.target.value);
                break;

            case "vendor_address": if (!e.target.value.match(vendorAddressRegex)) {
                setVendorAddressHelperText("Vendor Address Should be Valid");
            }
            else {
                setVendorAddressHelperText("");

            }
                setVendorAddress(e.target.value);
                break;

            case "vendor_contact": if (!e.target.value.match(vendorContactRegex)) {
                setVendorContactHelperText("Phone Number should be Valid");
            }
            else {
                setVendorContactHelperText("");

            }
                setVendorContact(e.target.value);
                break;

            case "help_desk_email": if (!e.target.value.match(emailRegex)) {
                setHelpDeskEmailHelper("Enter Valid Email ID");
            }
            else {
                setHelpDeskEmailHelper("");

            }
                setHelpDeskEmail(e.target.value);
                break;

            case "escalation_level1_mail": if (!e.target.value.match(emailRegex)) {
                setEscalationLevel1MailHelper("Enter Valid Email ID");
            }
            else {
                setEscalationLevel1MailHelper("");

            }
                setEscalationLevel1Mail(e.target.value);
                break;


            case "escalation_level2_mail": if (!e.target.value.match(emailRegex)) {
                setEscalationLevel2MailHelper("Enter Valid Email ID");
            }
            else {
                setEscalationLevel2MailHelper("");

            }
                setEscalationLevel2Mail(e.target.value);
                break;

            case "vendor_city": if (!e.target.value.match(cityRegex)) {
                setVendorCityHelper("Enter Valid City Name");
            }
            else {
                setVendorCityHelper("");

            }
                setVendorCity(e.target.value);
                break;

            case "promised_uptime": if (!e.target.value.match(uptimeRegex)) {
                setPromisedUptimeHelper("Enter Valid Promised Uptime");
            }
            else {
                setPromisedUptimeHelper("");

            }
                setPromisedUptime(e.target.value);
                break;

            case "resolution_time": if (!e.target.value.match(resolutionTimeRegex)) {
                setResolutionTimeHelper("Enter Valid Resolution Time");
            }
            else {
                setResolutionTimeHelper("");

            }
                setResolutionTime(e.target.value);
                break;

            default: setPromisedUptime(e.target.value);
                break;

        }
    };

    const handleOnSave = (e) => {

        if (!vendorName.match(vendorNameRegex)) {
            setVendorNameHelperText("Vendor Name Should be Valid");
            return;
        }


        if (!vendorAddress.match(vendorAddressRegex)) {
            setVendorAddressHelperText("Vendor Address Should be Valid");
            return;
        }


        if (!vendorContact.match(vendorContactRegex)) {
            setVendorContactHelperText("Phone Number should be Valid");
            return;
        }


        if (!helpDeskEmail.match(emailRegex)) {
            setHelpDeskEmailHelper("Invalid email address");
            return;
        }


        if (!vendorCity.match(cityRegex)) {
            setVendorCityHelper("Enter Correct City Name");
            return;
        }


        if (promisedUptime.trim() === "") {
            setPromisedUptimeHelper("Enter Valid Promised Uptime");
            return;
        }


        if (!resolutionTime.match(resolutionTimeRegex)) {
            setResolutionTimeHelper("Enter Valid Resolution Time");
            return;
        }

        AddNewVendorApiCall(vendorName, vendorAddress, vendorContact, helpDeskEmail, escalationLevel1Mail, escalationLevel2Mail, vendorCity, promisedUptime, resolutionTime, setAddVendorStatus, setAddVendorMessage);
        handleOnClose();
    };
    
    return (
        <>
            <Dialog open={openAddVendorDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Add New Vendor
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="Vendor Name" id="vendor_name" value={vendorName} onChange={handleOnChange} helperText={vendorNameHelperText} error={!!vendorNameHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Vendor Address" id="vendor_address" value={vendorAddress} onChange={handleOnChange} helperText={vendorAddressHelperText} error={!!vendorAddressHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Vendor Contact Number" id="vendor_contact" value={vendorContact} onChange={handleOnChange} helperText={vendorContactHelperText} error={!!vendorContactHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Help Desk Email" id="help_desk_email" value={helpDeskEmail} onChange={handleOnChange} helperText={helpDeskEmailHelper} error={!!helpDeskEmailHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Escalation Level-1 Email" id="escalation_level1_mail" value={escalationLevel1Mail} onChange={handleOnChange} helperText={escalationLevel1MailHelper} error={!!escalationLevel1MailHelper} variant="outlined" style={{ width: "400px" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Escalation Level-2 Email" id="escalation_level2_mail" value={escalationLevel2Mail} onChange={handleOnChange} helperText={escalationLevel2MailHelper} error={!!escalationLevel2MailHelper} variant="outlined" style={{ width: "400px" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="City" id="vendor_city" value={vendorCity} onChange={handleOnChange} helperText={vendorCityHelper} error={!!vendorCityHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Promised Uptime" id="promised_uptime" value={promisedUptime} onChange={handleOnChange} helperText={promisedUptimeHelper} error={!!promisedUptimeHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Resolution Time" id="resolution_time" value={resolutionTime} onChange={handleOnChange} helperText={resolutionTimeHelper} error={!!resolutionTimeHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleOnSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
