import React from 'react'
import CustomTagTable from './Components/CustomTagTable'

export default function CustomTagComponents() {
  return (
     <CustomTagTable />
    // <>
    //   Hi Ganesh
    // </>
  )
}
// C:\Users\KavyaP\OneDrive - LinkEye\Desktop\linkeye-ui\Linkeye-UI-V1\src\components\TagComponents\Components\CustomTagTable.js