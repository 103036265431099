import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Grid,
  Box,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
  Snackbar
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Iconify from '../../Iconify';

// API Call Imports
import UtilizationAlertApiCall from './UtilizationAlert';
import GetSiteGroupDetailsApiCall from './GetSiteGroupDetailsApiCall';
import AddNewSiteGroupDialog from './AddNewSiteGroupDialog';
import EditSiteGroupDetailsDialog from './EditSiteGroupDetailsDialog';
import DeleteSiteGroupDialog from './DeleteSiteGroupDialog';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';
import UtilizationAlertDailog from './UtilizationAlertDailog';
import { useGlobalState } from '../../../globalHooks/GlobalState';


const headCells = [
  { id: 'slNo', label: 'Sl No', numeric: true, disablePadding: false },
  { id: 'cust_name', label: 'Customer', numeric: false, disablePadding: false },
  { id: 'groupname', label: 'Group Name', numeric: false, disablePadding: false },
  { id: 'grouptype', label: 'Group Type', numeric: false, disablePadding: false },
  { id: 'criticality', label: 'Criticality', numeric: false, disablePadding: false },
  { id: 'CreatedAt', label: 'Created On', numeric: false, disablePadding: false },
  { id: 'action', label: 'Actions', numeric: true, disablePadding: false },
];

function EnhancedTableHead({ showCustomer, order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          (headCell.id !== 'cust_name' || showCustomer) &&
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ background: '#f0f0f0', padding: '10px' }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  showCustomer: PropTypes.bool.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 40,
  marginLeft: 'auto',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

function EnhancedTableToolbar({ handleAddClick, filterName, onFilterName }) {
  return (
    <RootStyle>
      <Typography variant='h4' sx={{ flexShrink: 0 }}>
        Site Groups
      </Typography>
      <Box
        width="75%"
        display="flex"
        justifyContent="flex-end"
        paddingRight="10px"
      >
        <Box
          width="10px"
        />
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          sx={{ width: "150px", height: "40px", paddingLeft: "10px" }}
          onClick={handleAddClick}
        >
          New
        </Button>
      </Box>

      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  filterName: PropTypes.string.isRequired,
  onFilterName: PropTypes.func.isRequired,
};

export default function SiteGroupsTableComponent() {

  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspClientId = localStorage.getItem("cust_id");
  const regClientId = localStorage.getItem("reg_cust_id");
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

  const isEngineer = (localStorage.getItem("role") === "2")
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [group, setGroup] = useState({});

  const [openAddSiteGroupDialog, setOpenAddSiteGroupDialog] = useState(false);
  const [addSiteGroupStatus, setAddSiteGroupStatus] = useState(false);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editStatus, setEditStatus] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const { globalState, setGlobalState } = useGlobalState();
  const [addSiteMessage, setAddSiteMessage] = useState("");
  const [editSiteMessage, setEditSiteMessage] = useState("");
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [utilizationGroupId, setUtilizationGroupId] = useState(-1);
  const [utilizationGroupName, setUtilizationGroupName] = useState("")
  const [UtilizationAlertDialog, setUtilizationAlertDialog] = useState(false);
  const [UtilizationAlertValue, setUtilizationAlertValue] = useState(-1);
  const [UtilizationOpen, setUtilizationOpen] = useState(false);

  const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));

  const handleAddClick = () => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setOpenAddSiteGroupDialog(true);
  }
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const _GetSiteGroupDetailsApiCall = async () => {
    await GetSiteGroupDetailsApiCall(setGroups);
  };

  useEffect(() => {
    _GetSiteGroupDetailsApiCall();
  }, [globalState, addSiteGroupStatus, editStatus, deleteStatus]);

  useEffect(() => {
    setFilteredGroups(groups);
  }, [groups]);

  const resetAll = () => {
    setOpenAddSiteGroupDialog(false);
    setAddSiteGroupStatus(false);
    setOpenEditDialog(false);
    setEditStatus(false);
    setOpenDeleteDialog(false);
    setDeleteStatus(false);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    resetAll();
    setUtilizationOpen(false);
  };

  const handleEditClick = (row) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    setGroup(row);
    setOpenEditDialog(true);
  };

  const handleDataReLoad = () => {
    _GetSiteGroupDetailsApiCall();
  };

  const handleDeleteClick = (row) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setGroup(row);
    setOpenDeleteDialog(true);
  };
  const handleUtilizationAlertDialog = (groupId, groupName, value) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    setUtilizationGroupId(groupId);
    setUtilizationGroupName(groupName);
    setUtilizationAlertValue(value)
    setUtilizationAlertDialog(true);
  }

  const handleUtilizationAlertLink = async (alertStatus) => {
    if (alertStatus === true) {
      await UtilizationAlertApiCall(utilizationGroupId, utilizationGroupName, UtilizationAlertValue, setUtilizationOpen);
      await GetSiteGroupDetailsApiCall(setGroups);
    }
    setUtilizationAlertDialog(false);

  }
  const handleFilterByName = (event) => {
    const searchedVal = event.target.value.toLowerCase();
    setFilterName(event.target.value);
    const filteredRows = groups.filter((row) => {
      const groupName = row.group_name ? row.group_name.toLowerCase() : "";
      return groupName.includes(searchedVal);
    });
    setFilteredGroups(filteredRows);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }} elevation={15}>
        {/* <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
            Please select a customer.
          </Alert>
        </Snackbar> */}
        <EnhancedTableToolbar
          handleAddClick={handleAddClick}
          filterName={filterName}
          onFilterName={(e) => { handleFilterByName(e, filterName) }} />
        <TableContainer>

          <Table
            sx={{ minWidth: 750, maxHeight: 500 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              showCustomer={mspClientId === regClientId && mspStatus === 1}
            />

            <TableBody>
              {filteredGroups.map((row, index) => (
                // {groups.map((row, index) =>
                <TableRow
                  hover
                  key={index} >
                  <TableCell align="center">{index + 1}</TableCell>
                  {mspClientId === regClientId && mspStatus === 1 ? (<TableCell align="left">{row.cust_name}</TableCell>) : null}
                  <TableCell align="left">{row.group_name}</TableCell>
                  <TableCell align="left">{row.group_type}</TableCell>
                  {row.group_criticality === 0 && <TableCell align="left">{'Low'}</TableCell>}
                  {row.group_criticality === 1 && <TableCell align="left">{'Medium'}</TableCell>}
                  {row.group_criticality === 2 && <TableCell align="left">{'High'}</TableCell>}
                  <TableCell align="left">{row.created_at}</TableCell>
                  <TableCell align="center">
                    <Grid direction="row">
                      <IconButton variant="outlined" color="primary" onClick={() => { handleEditClick(row) }}><EditIcon /></IconButton>
                      {!isEngineer && <IconButton variant="outlined" color="error" onClick={() => { handleDeleteClick(row) }}><DeleteIcon /></IconButton>}


                      {
                        row.util_alert_status === 0 &&
                        (
                          <IconButton
                            variant="outlined"
                            value={row.group_id}
                            color="error"
                            onClick={() => handleUtilizationAlertDialog(row.group_id, row.group_name, 1)}
                          >
                            <NotificationsOffIcon />
                          </IconButton>
                        )}
                      {row.util_alert_status === 1 && (
                        <IconButton
                          variant="outlined"
                          value={row.group_id}
                          color="primary"
                          onClick={() => handleUtilizationAlertDialog(row.group_id, row.group_name, 0)}
                        >
                          <NotificationsActiveIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {openAddSiteGroupDialog &&
        <AddNewSiteGroupDialog
          openAddSiteGroupDialog={openAddSiteGroupDialog}
          setOpenAddSiteGroupDialog={setOpenAddSiteGroupDialog}
          setAddSiteGroupStatus={setAddSiteGroupStatus} handleDataReLoad={handleDataReLoad}
          setAddSiteMessage={setAddSiteMessage}
        />}

      {openEditDialog &&
        <EditSiteGroupDetailsDialog
          group={group}
          openEditDialog={openEditDialog}
          setOpenEditDialog={setOpenEditDialog}
          setEditStatus={setEditStatus} handleDataReLoad={handleDataReLoad}
          setEditSiteMessage={setEditSiteMessage}
        />}

      {openDeleteDialog &&
        <DeleteSiteGroupDialog
          group={group}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          setDeleteStatus={setDeleteStatus} handleDataReLoad={handleDataReLoad}
        />}
      {UtilizationAlertDialog && <UtilizationAlertDailog UtilizationAlertValue={UtilizationAlertValue}
        UtilizationSiteGroup={utilizationGroupName}
        UtilizationAlertDialog={UtilizationAlertDialog}
        handleUtilizationAlertLink={handleUtilizationAlertLink}
      />}

      {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}

      {addSiteGroupStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          {addSiteMessage}
        </Alert>
      </Snackbar>}
      {addSiteGroupStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
          Error:{addSiteMessage}
        </Alert>
      </Snackbar>}

      {editStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          {editSiteMessage}
        </Alert>
      </Snackbar>}
      {editStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
          Error:{editSiteMessage}
        </Alert>
      </Snackbar>}

      {deleteStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          Site Group Deleted Successfully.
        </Alert>
      </Snackbar>}
      {deleteStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
          Error in deleting site group.
        </Alert>
      </Snackbar>}
      <Snackbar open={UtilizationOpen} autoHideDuration={6000} onClose={handleSnackClose} key={'set alerts for group'}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          {UtilizationAlertValue === 1 && <Typography>Alerts enabled for the site group</Typography>}
          {UtilizationAlertValue === 0 && <Typography>Alerts disabled for the site group</Typography>}
        </Alert>
      </Snackbar>
    </Box>
  );
}