import React, { useEffect, useState } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, TextField, FormHelperText, Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import UpdateEdgeDeviceApiCall from './UpdateEdgeDeviceApiCall';
import GetEdgeDeviceVendorAndTypesApiCall from './GetEdgeDeviceVendorAndTypesApiCall';

const securityLevel = {
    0: "No Auth No Privacy",
    1: "Auth No Privacy",
    2: "Auth with Privacy",
}

const authType = [
    "SHA",
    "MD5"
]

const privacyType = [
    "AES",
    "DES"
]

export default function UpdateEdgeDeviceDialog(props) {

    const { value, openUpdateEdDialog, setOpenUpdateEdDialog, setUpdateEdStatus, setUpdateEdMessage } = props;
    const [locationName, setLocationName] = useState(value.location_name);
    const [locationId, setLocationId] = useState(value.location_id);
    const [deviceId, setDeviceId] = useState(value.device_id);
    const [deviceIp, setDeviceIp] = useState(value.device_ip);
    const [snmpStr, setSnmpStr] = useState(value.snmp_str);
    const [sshUserName, setSshUserName] = useState(value.ssh_username);
    const [sshPassword, setSshPassword] = useState(value.ssh_password);
    const [apiKey, setApiKey] = useState(value.api_key);
    const [sshPort, setSshPort] = useState(value.ssh_port);
    const [apiPort, setApiPort] = useState(value.api_port);
    const [deviceType, setDeviceType] = useState(value.device_type_id);
    const [deviceVendor, setDeviceVendor] = useState(value.device_vendor_id);
    const [hostName, setHostName] = useState(value.host_name);
    const [organisationId , setOrganisationId] = useState(value.org_id);
    const [devSerial , setDevSerial] = useState(value.device_serial);
    const [snmpVersion, setSnmpVersion] = useState(value.snmp_version);
    const [snmpUserName, setSnmpUserName] = useState(value.snmp_str);
    const [authPassword, setAuthPassword] = useState(value.auth_password);
    const [privacyPassword, setPrivacyPassword] = useState(value.privacy_password);
    const [securityLevelValue, setSecurityLevelValue] = useState(value.security_level);
    const [authTypeValue, setAuthTypeValue] = useState(value.auth_type);
    const [privacyTypeValue, setPrivacyTypeValue] = useState(value.privacy_type);


    const [ipHelperText, setIpHelperText] = useState("");
    const [snmpHelperText, setSnmpHelperText] = useState("");
    const [keyHelperText, setKeyHelperText] = useState("")
    const [sshUserNameHelperText, setSshUserNameHelperText] = useState("");
    const [sshPasswordHelperText, setSshPasswordHelperText] = useState("");
    const [vendorHelperText, setVendorHelperText] = useState("");
    const [sshPortHelperText, setSshPortHelperText] = useState("");
    const [apiPortHelperText, setApiPortHelperText] = useState("");
    const [typeHelperText, setTypeHelperText] = useState("");
    const [organisationHelperText , setOrganisationHelperText] = useState("");
    const [deviceHelperText , setDeviceHelperText] = useState("");
    const [snmpVersionHelperText, setSnmpVersionHelperText] = useState("");
    const [securityLevelHelperText, setSecurityLevelHelperText] = useState("");
    const [authTypeValueHelperText, setAuthTypeValueHelperText] = useState("");
    const [authPasswordHelperText, setAuthPasswordHelperText] = useState("");
    const [privacyTypeValueHelperText, setPrivacyTypeValueHelperText] = useState("");
    const [privacyPasswordHelperText, setPrivacyPasswordHelperText] = useState("");

    const [typeList, setTypeList] = useState([]);
    const [vendorList, setVendorList] = useState([]);

    const ipRegEx = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    const snmpOrKeyRegEx = /^[A-Za-z0-9_\-!@#$%^&*()+=<>?{}[]|~`"'.,;:]+$/;
    const sshUserNameRegEx = /^(?!.{51})(?![ -])[A-Za-z\d!@#$%&*_()+,\-.:;<=>?@]+[A-Za-z\d]$/;
    const organisationRegEx = /^[a-zA-Z0-9-]+$/;

    
    const securityLevels = Object.keys(securityLevel).map(key => ({ value: key, label: securityLevel[key] }));


    useEffect(() => {
        GetEdgeDeviceVendorAndTypesApiCall(setVendorList, setTypeList);
    }, [])

    

    const [prevValues, setPrevValues] = useState({
        snmp2: { snmpStr: value.snmp_str },
        snmp3: {
            snmpUserName: value.snmp_str,
            authPassword: value.auth_password,
            privacyPassword: value.privacy_password,
            authTypeValue: value.auth_type,
            privacyTypeValue: value.privacy_type,
            securityLevelValue: value.security_level
        }
    });

    const handleSnmpVersionChange = async (e) => {
        const newVersion = e.target.value;
        if (snmpVersion === 3 && newVersion === "2") {
            setPrevValues((prev) => ({
                ...prev,
                snmp3: {
                    snmpUserName,
                    authPassword,
                    privacyPassword,
                    authTypeValue,
                    privacyTypeValue,
                    securityLevelValue
                }
            }));
            setSnmpStr("");
            setSnmpUserName("");
            setAuthPassword("");
            setPrivacyPassword("");
            setAuthTypeValue(-1);
            setPrivacyTypeValue(-1);
            setSecurityLevelValue(-1);
            setAuthTypeValueHelperText("");
            setPrivacyTypeValueHelperText("");
            setAuthPasswordHelperText("");
            setPrivacyPasswordHelperText("");
        } else if (snmpVersion === 2 && newVersion === "3") {
            setPrevValues((prev) => ({
                ...prev,
                snmp2: { snmpStr }
            }));
            const { snmpUserName, authPassword, privacyPassword, authTypeValue, privacyTypeValue, securityLevelValue } = prevValues.snmp3;
            setSnmpUserName(snmpUserName);
            setAuthPassword(authPassword);
            setPrivacyPassword(privacyPassword);
            setAuthTypeValue(authTypeValue);
            setPrivacyTypeValue(privacyTypeValue);
            setSecurityLevelValue(securityLevelValue);
        }
        setSnmpVersion(Number(newVersion));
    };

    const handleSecurityLevelChange = async (e) => {
        if ( e.target.value === "0"){
            setAuthTypeValue(-1);
            setAuthTypeValueHelperText("");
            setAuthPassword("");
            setAuthPasswordHelperText("");
            setPrivacyTypeValue(-1);
            setPrivacyTypeValueHelperText("");
            setPrivacyPassword("");
            setPrivacyPasswordHelperText("");
        }
        if (e.target.value === "1"){
            setPrivacyTypeValue(-1);
            setPrivacyTypeValueHelperText("");
            setPrivacyPassword("");
            setPrivacyPasswordHelperText("");
        }
        setSecurityLevelValue(e.target.value);
    };  


    const handleOnUpdate = async (e) => {
        e.preventDefault();

        if (!deviceIp.match(ipRegEx)) {
            setIpHelperText("Please Enter Correct IP");
            return;
        }
        if (snmpVersion === 0) {
            setSnmpVersionHelperText("Select SNMP version");
            return;
        }

        if (snmpVersion === 2) {
            if (!snmpStr.match(snmpOrKeyRegEx)) {
                setSnmpHelperText("Please Enter Correct SNMP String");
                return;
            }
        }

        if (snmpVersion === 3) {
            if (!snmpUserName.match(snmpOrKeyRegEx)) {
                setSnmpHelperText("Please Enter Correct SNMP Username");
                return;
            }
        }

        if (snmpVersion === 3) {
            if (securityLevelValue === -1) {
                setSecurityLevelHelperText("Please Select Security Level");
                return;
            }
        }

        if (snmpVersion === 3) {
            if (securityLevelValue === "1" || securityLevelValue === "2") {
                if (authTypeValue === -1) {
                    setAuthTypeValueHelperText("Please Select Auth Type");
                    return;
                }
            }
        }

        if (snmpVersion === 3) {
            if (securityLevelValue === "1" || securityLevelValue === "2") {
                if (authPassword.trim() === "") {
                    setAuthPasswordHelperText("Please Enter Valid Password");
                    return;
                }
            }
        }

        if (snmpVersion === 3) {
            if (securityLevelValue === "2") {
                if (privacyTypeValue === -1) {
                    setPrivacyTypeValueHelperText("Please Select Privacy Type");
                    return;
                }
            }
        }

        if (snmpVersion === 3) {
            if (securityLevelValue === "2") {
                if (privacyPassword.trim() === "") {
                    setPrivacyPasswordHelperText("Please Enter Valid Password");
                    return;
                }
            }
        }

        if (!apiKey.match(snmpOrKeyRegEx)) {
            setKeyHelperText("Please Enter Correct API Key");
            return;
        }

        if (!sshUserName.match(sshUserNameRegEx)) {
            setSshUserNameHelperText("Please Enter Valid SSH Username");
            return;
        }

        if (sshPassword === "") {
            setSshPasswordHelperText("Please Enter Valid Password");
            return;
        }

        if (sshPort.trim() === "" || parseInt(sshPort, 10) < 1 || parseInt(sshPort, 10) > 65535 || Number.isNaN(parseInt(sshPort, 10))) {
            setSshPortHelperText("Please Enter Correct SSH Port");
            return;
        }

        if (apiPort.trim() === "" || parseInt(apiPort, 10) < 1 || parseInt(apiPort, 10) > 65535 || Number.isNaN(parseInt(apiPort, 10))) {
            setApiPortHelperText("Please Enter Correct API Port");
            return;
        }

        if (deviceVendor === 0) {
            setVendorHelperText("Please Select Vendor");
            return;
        }

        if (deviceType === 0) {
            setTypeHelperText("Please Select Type");
            return;
        }
        if (deviceVendor === "10" || deviceVendor === 10) {
            if (!organisationId || organisationId.trim() === "" || !organisationId.match(organisationRegEx)) {
                setOrganisationHelperText("Please Enter Valid Organisation ID");
                return;
            }
    
            if (!devSerial || devSerial.trim() === "" || !devSerial.match(organisationRegEx)) {
                setDeviceHelperText("Please Enter Valid Device Serial No");
                return;
            }
        }
        await UpdateEdgeDeviceApiCall(deviceId, locationId, hostName, deviceIp, snmpStr, sshUserName, sshPassword, apiKey, sshPort, apiPort, deviceVendor, deviceType, setUpdateEdStatus, setUpdateEdMessage,organisationId, devSerial, snmpVersion, snmpUserName, securityLevelValue, authTypeValue, authPassword, privacyTypeValue, privacyPassword);
        setOpenUpdateEdDialog(false);
    };

    return (
        <>
            <Dialog open={openUpdateEdDialog} onClose={() => { setOpenUpdateEdDialog(false) }} fullWidth maxWidth="lg" aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Update Edge Device
                    <IconButton onClick={() => { setOpenUpdateEdDialog(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 6, px: 3 }}>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Location Name"
                                    id="location_name"
                                    value={locationName}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setLocationName(e.target.value) }}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Host Name"
                                    id="host_name"
                                    value={hostName}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setHostName(e.target.value) }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Edge Device IP"
                                    id="device_ip"
                                    value={deviceIp}
                                    variant="outlined"
                                    style={{ width: "400px", color: "red" }}
                                    error={ipHelperText}
                                    helperText={ipHelperText}
                                    onChange={(e) => {
                                        if (!ipRegEx.test(e.target.value)) {
                                            setIpHelperText("Please Enter Correct IP")
                                        }
                                        else {
                                            setIpHelperText("")
                                        }
                                        setDeviceIp(e.target.value)
                                    }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Select SNMP Version</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="snmp-version"
                                        name="snmp-version"
                                        value={snmpVersion}
                                        onChange={handleSnmpVersionChange}
                                    >
                                        <FormControlLabel value="2" control={<Radio />} label="SNMP V2" />
                                        <FormControlLabel value="3" control={<Radio />} label="SNMP V3" />
                                    </RadioGroup>
                                    {snmpVersion === 0 && (
                                        <FormHelperText error>
                                            {snmpVersionHelperText}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            {snmpVersion === 3 && <Grid item xs={6}>
                                <TextField
                                    label="SNMP User Name"
                                    id="snmp_username"
                                    value={snmpUserName}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    error={snmpHelperText}
                                    helperText={snmpHelperText}
                                    onChange={(e) => {
                                        if (e.target.value === "" || !snmpOrKeyRegEx.test(e.target.value)) {
                                            setSnmpHelperText("Please Enter Valid SNMP Username")
                                        }
                                        else {
                                            setSnmpHelperText("")
                                        }
                                        setSnmpUserName(e.target.value)
                                    }}
                                    required />
                            </Grid>}

                            {snmpVersion === 3 && <Grid item xs={6}>
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="security-level-id">Security Level</InputLabel>
                                    <Select
                                        labelId="security-level-id"
                                        id="demo-simple-select-helper"
                                        value={securityLevelValue}
                                        style={{ width: "400px" }}
                                        label="Security Level"
                                        onChange={handleSecurityLevelChange}
                                    >
                                        <MenuItem value={-1}><em>Select Security Level</em></MenuItem>

                                        {securityLevels.map((row, index) => (
                                            <MenuItem key={index} value={row.value}>{row.label}</MenuItem>
                                        ))}

                                    </Select>
                                    {securityLevelValue === -1 && (
                                        <FormHelperText error>
                                            {securityLevelHelperText}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>}

                            {snmpVersion === 3 && <>
                                {(securityLevelValue === "1" || securityLevelValue === "2") && (<> <Grid item xs={6}>
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="auth-type-id">Auth Type</InputLabel>
                                        <Select
                                            labelId="auth-type-id"
                                            id="auth-type-id-helper"
                                            value={authTypeValue}
                                            style={{ width: "400px" }}
                                            label="Auth Type"
                                            onChange={(e) => { setAuthTypeValue(e.target.value) }}
                                        >
                                            <MenuItem value={-1}><em>Select Auth Type</em></MenuItem>

                                            {authType.map((row, index) => (
                                                <MenuItem value={row}>{row}</MenuItem>
                                            ))}

                                        </Select>
                                        {(securityLevelValue === "1" || securityLevelValue === "2") && authTypeValue === -1 && (
                                            <FormHelperText error>
                                                {authTypeValueHelperText}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            label="Auth Password"
                                            type="password"
                                            id="auth_password"
                                            value={authPassword}
                                            variant="outlined"
                                            style={{ width: "400px" }}
                                            error={authPasswordHelperText}
                                            helperText={authPasswordHelperText}
                                            onChange={(e) => {
                                                if (securityLevelValue === "1" || securityLevelValue === "2") {
                                                    if (e.target.value === "") {
                                                        setAuthPasswordHelperText("Please Enter Valid Password")
                                                    }
                                                    else {
                                                        setAuthPasswordHelperText("")
                                                    }
                                                }
                                                setAuthPassword(e.target.value)
                                            }}
                                            required />
                                    </Grid>
                                </>)
                                }

                                {securityLevelValue === "2" && <>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="Privacy-type-id">Privacy Type</InputLabel>
                                            <Select
                                                labelId="Privacy-type-id"
                                                id="Privacy-type-select-helper"
                                                value={privacyTypeValue}
                                                style={{ width: "400px" }}
                                                label="Privacy Type"
                                                onChange={(e) => { setPrivacyTypeValue(e.target.value) }}
                                            >
                                                <MenuItem value={-1}><em>Select Privacy Type</em></MenuItem>

                                                {privacyType.map((row, index) => (
                                                    <MenuItem value={row}>{row}</MenuItem>
                                                ))}

                                            </Select>
                                            {privacyTypeValue === -1 && (
                                                <FormHelperText error>
                                                    {privacyTypeValueHelperText}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            label="Privacy Password"
                                            type="password"
                                            id="privacy_password"
                                            value={privacyPassword}
                                            variant="outlined"
                                            style={{ width: "400px" }}
                                            error={privacyPasswordHelperText}
                                            helperText={privacyPasswordHelperText}
                                            onChange={(e) => {
                                                if (e.target.value === "") {
                                                    setPrivacyPasswordHelperText("Please Enter Valid Password")
                                                }
                                                else {
                                                    setPrivacyPasswordHelperText("")
                                                }
                                                setPrivacyPassword(e.target.value)
                                            }}
                                            required />
                                    </Grid>
                                </>
                                }
                            </>}

                            {snmpVersion === 2 && <Grid item xs={6}>
                                <TextField
                                    label="SNMP String"
                                    id="snmp_str"
                                    value={snmpStr}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    error={snmpHelperText}
                                    helperText={snmpHelperText}
                                    onChange={(e) => {
                                        if (e.target.value === "" || !snmpOrKeyRegEx.test(e.target.value)) {
                                            setSnmpHelperText("Please Enter Correct SNMP String")
                                        }
                                        else {
                                            setSnmpHelperText("")
                                        }
                                        setSnmpStr(e.target.value)
                                    }}
                                    required />
                            </Grid>}

                            <Grid item xs={6}>
                                <TextField
                                    label="API Key"
                                    id="api_key"
                                    value={apiKey}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    error={keyHelperText}
                                    helperText={keyHelperText}
                                    onChange={(e) => {
                                        if (e.target.value === "" || !snmpOrKeyRegEx.test(e.target.value)) {
                                            setKeyHelperText("Please Enter Correct API Key")
                                        }
                                        else {
                                            setKeyHelperText("")
                                        }
                                        setApiKey(e.target.value)
                                    }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="SSH UserName"
                                    id="ssh_username"
                                    value={sshUserName}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    error={sshUserNameHelperText}
                                    helperText={sshUserNameHelperText}
                                    onChange={(e) => {
                                        setSshUserName(e.target.value)
                                        if (e.target.value === "" || !sshUserNameRegEx.test(e.target.value)) {
                                            setSshUserNameHelperText("Please Enter Valid SSH Username")
                                        }
                                        else {
                                            setSshUserNameHelperText("")
                                        }
                                    }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="SSH Password"
                                    type="password"
                                    id="ssh_password"
                                    value={sshPassword}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    error={sshPasswordHelperText}
                                    helperText={sshPasswordHelperText}
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            setSshPasswordHelperText("Please Enter Valid Password")
                                        }
                                        else {
                                            setSshPasswordHelperText("")
                                        }
                                        setSshPassword(e.target.value)
                                    }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="SSH Port"
                                    id="ssh_port"
                                    value={sshPort}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    error={sshPortHelperText}
                                    helperText={sshPortHelperText}
                                    onChange={(e) => {
                                        if (e.target.value === "" || parseInt(sshPort, 10) < 1 || parseInt(sshPort, 10) > 65535 || Number.isNaN(parseInt(sshPort, 10))) {
                                            setSshPortHelperText("Please Enter Correct SSH Port")
                                        }
                                        else {
                                            setSshPortHelperText("")
                                        }
                                        setSshPort(e.target.value)
                                    }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="API Port"
                                    id="api_port"
                                    value={apiPort}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    error={apiPortHelperText}
                                    helperText={apiPortHelperText}
                                    onChange={(e) => {
                                        if (e.target.value === "" || parseInt(apiPort, 10) < 1 || parseInt(apiPort, 10) > 65535 || Number.isNaN(parseInt(apiPort, 10))) {
                                            setApiPortHelperText("Please Enter Correct API Port")
                                        }
                                        else {
                                            setApiPortHelperText("")
                                        }
                                        setApiPort(e.target.value)
                                    }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="vendor-label-id">Vendor</InputLabel>
                                    <Select
                                        labelId="vendor-label-id"
                                        id="vendor"
                                        value={deviceVendor}
                                        label="Vendor"
                                        style={{ width: "400px" }}
                                        onChange={(e) => { setDeviceVendor(e.target.value); }}
                                    >
                                        <MenuItem value={0}>Select</MenuItem>
                                        {vendorList.map((value) => (
                                            <MenuItem value={value.ed_vendor_id}>{value.vendor_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {deviceVendor === 0 && (
                                        <FormHelperText error>
                                            {vendorHelperText}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="type-label-id">Type</InputLabel>
                                    <Select
                                        labelId="type-label-id"
                                        id="type"
                                        value={deviceType}
                                        label="Type"
                                        style={{ width: "400px" }}
                                        onChange={(e) => { setDeviceType(e.target.value); }}
                                    >
                                        <MenuItem value={0}>Select</MenuItem>
                                        {typeList.map((value) => (
                                            <MenuItem value={value.type_id}>{value.type}</MenuItem>
                                        ))}
                                    </Select>
                                    {deviceType === 0 && (
                                        <FormHelperText error>
                                            {typeHelperText}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {(deviceVendor === "10" || deviceVendor === 10) && <>
                            <Grid item xs={6}>
                                <TextField
                                    label="Organization ID"
                                    id="organisation_id"
                                    value={organisationId}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    error={organisationHelperText}
                                    helperText={organisationHelperText}
                                    onChange={(e) => {
                                        if (e.target.value === "" || !organisationRegEx.test(e.target.value)) {
                                            setOrganisationHelperText("Please Enter Valid Organisation Id")
                                        }
                                        else {
                                            setOrganisationHelperText("")
                                        }
                                        setOrganisationId(e.target.value)
                                    }}
                                     />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Device Serial No"
                                    id="serial_no"
                                    value={devSerial}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    error={deviceHelperText}
                                    helperText={deviceHelperText}
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            setDeviceHelperText("Please Enter Valid Device Serial No")
                                        }
                                        else {
                                            setDeviceHelperText("")
                                        }
                                        setDevSerial(e.target.value)
                                    }}
                                 />
                            </Grid>
                            </>}
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenUpdateEdDialog(false) }}>Cancel</Button>
                    <Button onClick={handleOnUpdate} variant="contained">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
        
    );
}