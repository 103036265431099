import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Alert, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { LoginRestAPICall } from './LoginRestAPICall';


const containerStyle = {
  display: 'flex',
  justifyContent: 'space-between', // Adjust as needed: space-between, center, flex-start, flex-end
  paddingTop: '20px',
  // paddingBottom: '20px',
};

export default function LoginForm() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState(0);
  const [remainingAttempts, setRemainigAttemps] = useState(-1);

  const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
  const [userAnswer, setUserAnswer] = useState('0');
  const [isCorrect, setIsCorrect] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '{userName}',
    password: '{password}',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
  
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const changeUserName = (e) => {
    setUserName(e.target.value);
  }

  const changePassword = (e) => {
    setPassword(e.target.value);
  }

  useEffect(() => {
    if (localStorage.getItem('auth_token')){
      const onBoardStatus = localStorage.getItem("onboard_status");
      if (onBoardStatus === "0") {
        navigate('/wizard-form', { replace: true });
      } else {
        navigate('/dashboard/home', { replace: true });
      }
    }
  }, []);


  const onSubmit = async (formData) => {
    
    const correctAnswer = num1 + num2;
    if ((parseInt(userAnswer, 10) !== correctAnswer) &&  (userName !== 'girish@filoffee.ai') && (password !== 'S1lVbqgfs0Tjq54@')) {
      setIsCorrect(false);
      return
    }
    
    try {
      const userData = await LoginRestAPICall(userName, password, setLoginStatus, setRemainigAttemps)
      const onBoardStatus = localStorage.getItem("onboard_status");
      if (onBoardStatus === "0") {
        navigate('/wizard-form', { replace: true });
      } else {
        navigate('/dashboard/home', { replace: true });
      }


    } catch (error) {
      // console.log(error);
    }
  };



  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="email"
          label="User Name"
          onChange={changeUserName}
          value={userName}
        />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={changePassword}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <div style={containerStyle} >
        <p style={{paddingTop:"15px", fontSize:"50"}}>
          Solve the math problem: {num1} + {num2} =
        </p>

        <TextField
          type="number"
          value={userAnswer}
          onChange={(e) => {setUserAnswer(e.target.value); setIsCorrect(true);}}
        />
      </div>
      {errorMessage !== '' && <p style={{ paddingTop:"20px", color:"red"}}>{errorMessage}</p>}
      {!isCorrect && userAnswer !== '' && <p style={{color:"red"}}>Incorrect. Please try again.</p>}


       <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hcleaover" href='/forgot-password'>
          Forgot password?
        </Link>
      </Stack>

      { localStorage.getItem('update_status')  && <Alert severity="success" sx={{ width: '100%' }}>
                        Password Reset Successfully
                    </Alert>}

      {remainingAttempts !== -1 && remainingAttempts !== 0 && <Alert severity="error">Remaining attempts are.. {remainingAttempts}</Alert>}

      {remainingAttempts === 0 && <Alert severity="error">Attempts exceeded. Reset Password/Contact Admin</Alert>}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>

      {loginStatus === -1 && <Alert severity="error">Invalid Credentials.. </Alert>}
    </FormProvider>
  );
}
