import { filter } from 'lodash';
import { useState, useEffect, forwardRef } from 'react';
import { useParams, Link } from 'react-router-dom';
// material
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';

// components
import Scrollbar from '../Scrollbar';
import ViewLinkTableHead from './ViewLinkTableHead';
import ViewLinkTableToolbar from './ViewLinkTableToolbar';
// mock
import USERLIST from '../../_mock/user';
import { LinksDataAPI, DeleteLinkDataAPI } from './LinksDataAPI'
import BusinessHourDialog from './BusinessHourDialog';
import AddLinkDialog from './AddLinkDialog';
import DeleteLinkDialog from './DeleteLinkDialog';
import SelectCustomerWarningDialog from '../WarningComponents/SelectCustomerWarningDialog';
import { useGlobalState } from '../../globalHooks/GlobalState';
import PauseLinkDailog from './PauseLinkDailog';
import PauseLinkApiCall from './PauseLinkApiCall';
import UtilizationAlertApiCall from './UtilizationAlert';
import UtilizationAlertDailog from './UtilizationAlertDailog';

const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'cust_name', label: 'Customer', alignRight: false },
  { id: 'Circuit', label: 'Circuit ID/Account ID', alignRight: false },
  { id: 'Site', label: 'Site', alignRight: false },
  { id: 'City', label: 'City', alignRight: false },
  { id: 'IP', label: 'Public IP/CE-IP', alignRight: false },
  { id: 'Vendor', label: 'Vendor', alignRight: false },
  { id: 'Vendor_Email', label: 'Vendor Support Email', alignRight: false },
  { id: 'DefaultGateway', label: 'Wan Gateway/PE-IP', alignRight: false },
  { id: 'PromisedUptime', label: 'Promised Uptime', alignRight: false },
  { id: 'ResolutionTime', label: 'Resolution Time', alignRight: false },
  { id: 'LinkType', label: 'Link Type', alignRight: false },
  { id: 'Provisioned Speed', label: 'Provisioned Speed', alignRight: false },
  { id: 'Price', label: 'Price', alignRight: false },
  { id: 'Renewal Date', label: 'Renewal Date', alignRight: false },
  { id: 'Actions', label: 'Actions', alignRight: false },
];


export default function ViewLinkTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspClientId = localStorage.getItem("cust_id");
  const regClientId = localStorage.getItem("reg_cust_id");
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
  const [openCustomerWarnDialog, setOpenCustomerWarnDialog] = useState(false);
  const [openPauseDialog, setOpenPauseDialog] = useState(false);
  const [UtilizationAlertDialog, setUtilizationAlertDialog] = useState(false);
  const [pauseValue, setPauseValue] = useState(-1);
  const [UtilizationAlertValue, setUtilizationAlertValue] = useState(-1);


  const classes = useStyles();

  const isEngineer = (localStorage.getItem("role") === "2")

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isp, setIsp] = useState({});
  const [ispData, setIspData] = useState([]);
  const [allIspData, setAllIspData] = useState([]);
  const [open, setOpen] = useState(false);
  const [pauseOpen, setPauseOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [UtilizationOpen, setUtilizationOpen] = useState(false);
  const [clickedLocation, setClickedLocation] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState(0);
  const [deleteLinkIP, setDeleteLinkIP] = useState("");
  const [deleteLinkWanID, setDeleteLinkWanID] = useState(-1);
  const [openAddLinkDialog, setOpenAddLinkDialog] = useState(false);
  const [addLinkStatus, setAddLinkStatus] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [pauseLinkIp, setPauseLinkIp] = useState("");
  const [pauseLinkWanId, setPauseLinkWanId] = useState(-1);
  const [utilizationLinkIp, setUtilizationLinkIp] = useState("");
  const [utilizationLinkWanId, setUtilizationLinkWanId] = useState(-1);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [addLinkMessage, setAddLinkMessage] = useState("");


  const Alert = forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  })


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAddLinkStatus(0);
    setEdit(false);
    setPauseOpen(false);
    setOpen(false);
    setUtilizationOpen(false);
  };

  const handleAddClick = () => {

    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setOpenAddLinkDialog(true);
  }

  const handlePauseDialog = (ispWanId, ispPublicIP, value) => {

    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setPauseLinkIp(ispPublicIP);
    setPauseLinkWanId(ispWanId);
    setPauseValue(value)
    setOpenPauseDialog(true);
  }


  const handleDeleteDialog = (ispWanId, ispPublicIP) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    setDeleteLinkIP(ispPublicIP);
    setDeleteLinkWanID(ispWanId);
    setOpenDeleteDialog(true);
  }


  const handleUtilizationAlertDialog = (ispWanId, ispPublicIP, value) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    setUtilizationLinkIp(ispPublicIP);
    setUtilizationLinkWanId(ispWanId);
    setUtilizationAlertValue(value)
    setUtilizationAlertDialog(true);
  }


  const handleDelete = async (deleteStatus) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    if (deleteStatus === true) {
      await DeleteLinkDataAPI(deleteLinkWanID, deleteLinkIP, setOpen);
      await LinksDataAPI(setIspData, setAllIspData, buState);
    }
    setOpenDeleteDialog(false);
    setDeleteLinkWanID(-1);
    setDeleteLinkIP("");
  }


  const handlePauseLink = async (pauseStatus) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    if (pauseStatus === true) {
      await PauseLinkApiCall(pauseLinkWanId, pauseLinkIp, pauseValue, setPauseOpen);
      await LinksDataAPI(setIspData, setAllIspData, buState);
    }
    setOpenPauseDialog(false);
    setPauseLinkWanId(-1);
    setPauseLinkIp("");
  }


  const handleUtilizationAlertLink = async (alertStatus) => {
    if (alertStatus === true) {
      await UtilizationAlertApiCall(utilizationLinkWanId, utilizationLinkIp, UtilizationAlertValue, setUtilizationOpen);
      await LinksDataAPI(setIspData, setAllIspData, buState);
    }
    setUtilizationAlertDialog(false);
    setPauseLinkWanId(-1);
    setPauseLinkIp("");
  }


  const handleFilterByName = async (event, searchedVal) => {
    setFilterName(event.target.value);
    setIspData(allIspData);
    const filteredRows = allIspData.filter((row) => {
      const location = row.location ? row.location.toLowerCase() : "";
      const vendor = row.vendor ? row.vendor.toLowerCase() : "";
      const city = row.city ? row.city.toLowerCase() : "";
      const circuitId = row.circuit_id ? row.circuit_id.toLowerCase() : "";
      const linkType = row.link_type ? row.link_type.toLowerCase() : "";
      const publicIp = row.public_ip ? row.public_ip.toLowerCase() : "";

      return location.includes(searchedVal.toLowerCase()) || vendor.includes(searchedVal.toLowerCase())
        || city.includes(searchedVal.toLowerCase()) || circuitId.includes(searchedVal.toLowerCase())
        || linkType.includes(searchedVal.toLowerCase()) || publicIp.includes(searchedVal.toLowerCase());
    });
    setIspData(filteredRows);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;


  const _LinksDataAPI = async () => {
    await LinksDataAPI(setIspData, setAllIspData, buState);
  }

  useEffect(() => {
    _LinksDataAPI();
  }, [globalState, buState,addLinkStatus]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setClickedLocation(null);
  }



  const getDisplayText = (value) => {
    switch (value) {
      case "Broadband":
        return "Static Broadband";
      case "DBB":
        return "Dynamic Broadband";
      default:
        return value;
    }
  };


  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <ViewLinkTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
            handleAddClick={handleAddClick}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <ViewLinkTableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD.filter(tableCell => {
                  return (
                    (mspClientId === regClientId || tableCell.id !== 'cust_name') &&
                    (mspStatus !== 0 || tableCell.id !== 'cust_name')
                  );
                })}
                rowCount={USERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {ispData.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                    >
                      <TableCell align="left" >{index + 1}</TableCell>
                      {mspClientId === regClientId && mspStatus === 1 ? <TableCell>{row.cust_name}</TableCell> : null}
                      <TableCell align="left"><Link to={{ pathname: `/dashboard/edit-single-isp/${row.isp_wan_id}` }}>{row.circuit_id}</Link></TableCell>
                      <TableCell align="left">{row.location}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.public_ip}</TableCell>
                      <TableCell align="left">{row.vendor}</TableCell>
                      <TableCell align="left">{row.vendor_email}</TableCell>
                      <TableCell align="left">{row.default_gateway}</TableCell>
                      <TableCell align="left">{row.promised_uptime} %</TableCell>
                      <TableCell align="left">{row.resolution_time} Hrs</TableCell>
                      <TableCell align="left">{getDisplayText(row.link_type)}</TableCell>
                      {/* <TableCell align="left">{row.provisioned_speed} Mbps</TableCell> */}
                      <TableCell align='left'>{row.provisioned_speed ? `${row.provisioned_speed} Mbps` : ''}</TableCell>
                      {/* <TableCell align="left">Rs. {row.price}</TableCell> */}
                      <TableCell align='left'>{row.price ? `Rs. ${row.price}` : ''}</TableCell>
                      <TableCell align="left">{row.renewal_date}</TableCell>
                      <TableCell align="center">
                        <Link to={{ pathname: `/dashboard/edit-single-isp/${row.isp_wan_id}` }}>
                          <IconButton
                            variant="outlined"
                            value={row.isp_wan_id}
                            color="primary"
                            onClick={(event) => {
                              if (globalState === custId && mspStatus === 1) {
                                setOpenWarnDialog(true);
                                event.preventDefault();
                              } else {
                                handleDelete(event);
                              }
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Link>
                        {!isEngineer && <IconButton variant="outlined" value={row.isp_wan_id} color="error" onClick={() => { handleDeleteDialog(row.isp_wan_id, row.public_ip) }}><DeleteIcon /></IconButton>}
                        {row.pause_status === 0 && <IconButton variant="outlined" value={row.isp_wan_id} color="error" onClick={() => { handlePauseDialog(row.isp_wan_id, row.public_ip, 1) }}><StopCircleIcon /></IconButton>}
                        {row.pause_status === 1 && <IconButton variant="outlined" value={row.isp_wan_id} color="primary" onClick={() => { handlePauseDialog(row.isp_wan_id, row.public_ip, 0) }}><SmartDisplayIcon /></IconButton>}
                        {row.util_alert_status === 0 && <IconButton variant="outlined" value={row.isp_wan_id} color="error" onClick={() => { handleUtilizationAlertDialog(row.isp_wan_id, row.public_ip, 1) }}><NotificationsOffIcon /></IconButton>}
                        {row.util_alert_status === 1 && <IconButton variant="outlined" value={row.isp_wan_id} color="primary" onClick={() => { handleUtilizationAlertDialog(row.isp_wan_id, row.public_ip, 0) }}><NotificationsActiveIcon /></IconButton>}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>


          {clickedLocation && <BusinessHourDialog clickedLocation={clickedLocation} openDialog={openDialog} handleClose={handleCloseDialog} isp={isp} />}
          {openAddLinkDialog && <AddLinkDialog openAddLinkDialog={openAddLinkDialog} setOpenAddLinkDialog={setOpenAddLinkDialog} setAddLinkStatus={setAddLinkStatus} setAddLinkMessage={setAddLinkMessage} />}
          {openDeleteDialog && <DeleteLinkDialog deleteLinkIP={deleteLinkIP} openDeleteDialog={openDeleteDialog} handleDelete={handleDelete} />}
          {openPauseDialog && <PauseLinkDailog pauseValue={pauseValue} pauseLinkIp={pauseLinkIp} openPauseDialog={openPauseDialog} handlePauseLink={handlePauseLink} />}
          {UtilizationAlertDialog && <UtilizationAlertDailog UtilizationAlertValue={UtilizationAlertValue} UtilizationAlertIp={utilizationLinkIp} UtilizationAlertDialog={UtilizationAlertDialog} handleUtilizationAlertLink={handleUtilizationAlertLink} />}
          {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}


          {addLinkStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose} key={'Add link'}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              {addLinkMessage}
            </Alert>
          </Snackbar>}

          {addLinkStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose} key={'Add link'}>
            <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
              Error: {addLinkMessage}
            </Alert>
          </Snackbar>}

          {addLinkStatus === 2 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose} key={'Add link'}>
            <Alert onClose={handleSnackClose} severity="warning" sx={{ width: '100%' }}>
              Error: {addLinkMessage}
            </Alert>
          </Snackbar>}

          <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} key={'Delete link'}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              Link Deleted Successfully.
            </Alert>
          </Snackbar>

          <Snackbar open={pauseOpen} autoHideDuration={6000} onClose={handleSnackClose} key={'Pause link'}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              {pauseValue === 1 && <Typography>Link Paused Successfully.</Typography>}
              {pauseValue === 0 && <Typography>Link Resumed Successfully.</Typography>}
            </Alert>
          </Snackbar>

          <Snackbar open={UtilizationOpen} autoHideDuration={6000} onClose={handleSnackClose} key={'set alerts for link'}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              {UtilizationAlertValue === 1 && <Typography>Alerts enabled for the link.</Typography>}
              {UtilizationAlertValue === 0 && <Typography>Alerts disabled for the link.</Typography>}
            </Alert>
          </Snackbar>
        </Paper>
      </Grid>
    </>
  );
}
