import axios from 'axios';

// API CALL CODE HERE

const BestSitesApiCall = async (buState, setBestSites, setWorstSites, monthYear, generateSiteReportCSV) => {
    const path = localStorage.getItem("msp_status") === "1" ? "get_best_sites_report_for_msp.php" : "get_best_sites_report.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "bu_id": buState,
        "month": monthYear
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "month": monthYear,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setBestSites(response.data.sites_data);
            setWorstSites([...response.data.sites_data].reverse());
            generateSiteReportCSV(response.data.sites_data);
        }
        else {
            setBestSites([]);
            setWorstSites([]);
        }
    })
}

export default BestSitesApiCall;