import axios from 'axios';

// API CALL CODE HERE
const pauseBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/link_utilization_alert.php`;
const UtilizationAlertApiCall = async (ispWanId, ispPublicIP, alertStatus, setOpen) => {
  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId,
    "link_ip": ispPublicIP,
    "alert_status": alertStatus
  });
  return axios.post(pauseBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setOpen(true);
    }
    else{
      console.log("Error in enabling alert for the link");
    }
  }).catch(()=>{
    console.log("Error in enabling alert for the link");
  })
}

export default UtilizationAlertApiCall;
