import { useState, useEffect, forwardRef, useRef, createRef } from 'react';
// material
import {
  Grid,
  Table,
  FormControlLabel,
  FormGroup,
  TextField,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Toolbar,
  Paper,
  Button,
  CircularProgress,
  Backdrop
} from '@mui/material';
// components
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Scrollbar from '../Scrollbar';
import AlertsEmailTableHead from './AlertsEmailTableHead';
import { UpdateAlertMailSettingsAPICall } from './UpdateAlertMailSettingsAPICall';
import { GetEmailConfig } from './GetEmailConfig';
import { useGlobalState } from '../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../WarningComponents/SelectCustomerWarningDialog';


const TABLE_HEAD = [
  { id: 'select', label: 'Select', alignRight: false },
  { id: 'alertType', label: 'Alert Type', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
];

// ----------------------------------------------------------------------
const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

export default function AlertEmailConfigComp() {
  const classes = useStyles();

  const { globalState, setGlobalState } = useGlobalState();
  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);

  const [allRowCheckStatus, setAllRowCheckStatus] = useState(false);
  const [allDefaultEmailCheckStatus, setAllDefaultEmailCheckStatus] = useState(false);
  const [allSiteContactStatus, setAllSiteContactStatus] = useState(false);
  const [allCustomEmailCheckStatus, setAllCustomEmailCheckStatus] = useState(false);
  const [allFromEmailCheckStatus, setAllFromEmailCheckStatus] = useState(false);
  const [allEmails, setAllEmails] = useState("");
  const [allReplyEmails, setAllReplyEmails] = useState("");
  const [allFromEmail, setAllFromEmail] = useState("");
  const [allCCEmails, setAllCCEmails] = useState("");
  const [allBCCEmails, setAllBCCEmails] = useState("");

  const [infoRowCheckStatus, setInfoRowCheckStatus] = useState(false);
  const [infoDefaultEmailCheckStatus, setInfoDefaultEmailCheckStatus] = useState(false);
  const [infoSiteContactStatus, setInfoSiteContactStatus] = useState(false);
  const [infoCustomEmailCheckStatus, setInfoCustomEmailCheckStatus] = useState(false);
  const [infoEmails, setInfoEmails] = useState("");
  const [infoReplyEmails, setInfoReplyEmails] = useState("");
  const [infoFromEmail, setInfoFromEmail] = useState("");
  const [infoFromEmailCheckStatus, setInfoFromEmailCheckStatus] = useState(false);
  const [infoCCEmails, setInfoCCEmails] = useState("");
  const [infoBCCEmails, setInfoBCCEmails] = useState("");

  const [warnRowCheckStatus, setWarnRowCheckStatus] = useState(false);
  const [warnDefaultEmailCheckStatus, setWarnDefaultEmailCheckStatus] = useState(false);
  const [warnSiteContactStatus, setWarnSiteContactStatus] = useState(false);
  const [warnCustomEmailCheckStatus, setWarnCustomEmailCheckStatus] = useState(false);
  const [warnEmails, setWarnEmails] = useState("");
  const [warnReplyEmails, setWarnReplyEmails] = useState("");
  const [warnFromEmail, setWarnFromEmail] = useState("");
  const [warnFromMailCheckStatus, setWanFromMailCheckStatus] = useState(false);
  const [warnCCEmails, setWarnCCEmails] = useState("");
  const [warnBCCEmails, setWarnBCCEmails] = useState("");

  const [criticalRowCheckStatus, setCriticalRowCheckStatus] = useState(false);
  const [criticalDefaultEmailCheckStatus, setCriticalDefaultEmailCheckStatus] = useState(false);
  const [criticalSiteContactStatus, setCriticalSiteContactStatus] = useState(false);
  const [criticalCustomEmailCheckStatus, setCriticalCustomEmailCheckStatus] = useState(false);
  const [criticEmails, setCriticEmails] = useState("");
  const [criticReplyEmails, setCriticReplyEmails] = useState("");
  const [criticalFromEmail, setCriticalFromEmail] = useState("");
  const [criticalFromEmailCheckStatus, setCriticalFromEmailCheckStatus] = useState(false);
  const [criticalCCEmails, setCriticalCCEmails] = useState("");
  const [criticalBCCEmails, setCrtitcalBCCEmails] = useState("");

  const [updateSuccessStatus, setUpdateSuccessStatus] = useState(false);
  const [presentSettings, setPresentSettings] = useState([]);

  const [openBackdrop, setOpenBackdrop] = useState(true);

  const Alert = forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  })

  const handleAllClick = (event) => {

    if (event.target.id === 'allRowCheck') {
      if (allRowCheckStatus === true) {
        setAllRowCheckStatus(false)
      }
      else {
        setAllRowCheckStatus(true)
      }
    }

    if (event.target.id === 'allDefaultEmail') {
      if (allDefaultEmailCheckStatus === true) {
        setAllDefaultEmailCheckStatus(false)
      }
      else {
        setAllDefaultEmailCheckStatus(true)
      }
    }

    if (event.target.id === 'allSiteContactStatusId') {
      setAllSiteContactStatus(!allSiteContactStatus);
    }

    if (event.target.id === 'allFromEmail') {
      if (allFromEmailCheckStatus === true) {
        setAllFromEmailCheckStatus(false)
      }
      else {
        setAllFromEmailCheckStatus(true)
      }
    }

    if (event.target.id === 'allCustomEmailCheck') {
      if (allCustomEmailCheckStatus === true) {
        setAllCustomEmailCheckStatus(false)
      }
      else {
        setAllCustomEmailCheckStatus(true)
      }
    }

  };

  const handleInfoClick = (event) => {
    if (event.target.id === 'infoRowCheck') {
      if (infoRowCheckStatus === true) {
        setInfoRowCheckStatus(false)
      }
      else {
        setInfoRowCheckStatus(true)
      }
    }

    if (event.target.id === 'infoDefaultEmail') {
      if (infoDefaultEmailCheckStatus === true) {
        setInfoDefaultEmailCheckStatus(false)
      }
      else {
        setInfoDefaultEmailCheckStatus(true)
      }
    }

    if (event.target.id === 'infoSiteContactStatusId') {
      setInfoSiteContactStatus(!infoSiteContactStatus);
    }

    if (event.target.id === 'infoFromEmailId') {
      setInfoFromEmailCheckStatus(!infoFromEmailCheckStatus);
    }

    if (event.target.id === 'infoCustomEmailCheck') {
      if (infoCustomEmailCheckStatus === true) {
        setInfoCustomEmailCheckStatus(false)
      }
      else {
        setInfoCustomEmailCheckStatus(true)
      }
    }

  };

  const handleWarnClick = (event) => {

    if (event.target.id === 'warnRowCheck') {
      if (warnRowCheckStatus === true) {
        setWarnRowCheckStatus(false)
      }
      else {
        setWarnRowCheckStatus(true)
      }
    }

    if (event.target.id === 'warnDefaultEmail') {
      if (warnDefaultEmailCheckStatus === true) {
        setWarnDefaultEmailCheckStatus(false)
      }
      else {
        setWarnDefaultEmailCheckStatus(true)
      }
    }

    if (event.target.id === 'warnSiteContactStatusId') {
      setWarnSiteContactStatus(!warnSiteContactStatus);
    }

    if (event.target.id === 'warnFromMailId') {
      setWanFromMailCheckStatus(!warnFromMailCheckStatus)
    }

    if (event.target.id === 'warnCustomEmailCheck') {
      if (warnCustomEmailCheckStatus === true) {
        setWarnCustomEmailCheckStatus(false)
      }
      else {
        setWarnCustomEmailCheckStatus(true)
      }
    }

  };

  const handleCriticalClick = (event) => {

    if (event.target.id === 'criticalRowCheck') {
      if (criticalRowCheckStatus === true) {
        setCriticalRowCheckStatus(false)
      }
      else {
        setCriticalRowCheckStatus(true)
      }
    }

    if (event.target.id === 'criticalDefaultEmail') {
      if (criticalDefaultEmailCheckStatus === true) {
        setCriticalDefaultEmailCheckStatus(false)
      }
      else {
        setCriticalDefaultEmailCheckStatus(true)
      }
    }

    if (event.target.id === 'criticalSiteContactStatusId') {
      setCriticalSiteContactStatus(!criticalSiteContactStatus);
    }

    if (event.target.id === 'criticalFromEmailId') {
      setCriticalFromEmailCheckStatus(!criticalFromEmailCheckStatus);
    }

    if (event.target.id === 'criticalCustomEmailCheck') {
      if (criticalCustomEmailCheckStatus === true) {
        setCriticalCustomEmailCheckStatus(false)
      }
      else {
        setCriticalCustomEmailCheckStatus(true)
      }
    }

  };

  const _UpdateAlertMailSettingsAPICall = async (settingData, setUpdateSuccessStatus) => {
    await UpdateAlertMailSettingsAPICall(settingData, setUpdateSuccessStatus);
  }

  const handleSaveSettings = () => {
    const settingData = [];

    if (allRowCheckStatus) {
      const data = {};
      data.alert_type = 5;
      if (allDefaultEmailCheckStatus) {
        data.send_to_defaults = 1;
      }
      else {
        data.send_to_defaults = 0;
      }

      if (allSiteContactStatus) {
        data.send_to_site_contact = 1;
      }
      else {
        data.send_to_site_contact = 0;
      }

      if (allCustomEmailCheckStatus) {
        data.custom_mail_ids = document.getElementById('all_custom_emails').value;
        data.reply_to_mail_ids = document.getElementById('all_reply_to_emails').value;
        data.cc_mail_ids = document.getElementById('all_cc_emails').value
        data.bcc_mail_ids = document.getElementById('all_bcc_emails').value
      }
      else {
        data.custom_mail_ids = "";
        data.reply_to_mail_ids = "";
        data.cc_mail_ids = ""
        data.bcc_mail_ids = ""
      }

      if (allFromEmailCheckStatus) {
        data.from_email_id = document.getElementById('all_from_email').value;
      }
      else {
        data.from_email_id = "";
      }

      settingData.push(data);
    }
    else {
      if (infoRowCheckStatus) {
        const infoData = {};
        infoData.alert_type = 1;
        if (infoDefaultEmailCheckStatus) {
          infoData.send_to_defaults = 1;
        }
        else {
          infoData.send_to_defaults = 0;
        }

        if (infoSiteContactStatus) {
          infoData.send_to_site_contact = 1;
        }
        else {
          infoData.send_to_site_contact = 0;
        }

        if (infoCustomEmailCheckStatus) {
          infoData.custom_mail_ids = document.getElementById('info_custom_emails').value;
          infoData.reply_to_mail_ids = document.getElementById('info_reply_to_emails').value;
          infoData.cc_mail_ids = document.getElementById('info_cc_emails').value;
          infoData.bcc_mail_ids = document.getElementById('info_bcc_emails').value;
        }
        else {
          infoData.custom_mail_ids = "";
          infoData.reply_to_mail_ids = "";
          infoData.cc_mail_ids = "";
          infoData.bcc_mail_ids = "";
        }

        if (infoFromEmailCheckStatus) {
          infoData.from_email_id = document.getElementById('info_from_email').value;
        }
        else {
          infoData.from_email_id = "";
        }

        settingData.push(infoData);
      }

      if (warnRowCheckStatus) {
        const warnData = {};
        warnData.alert_type = 2;
        if (warnDefaultEmailCheckStatus) {
          warnData.send_to_defaults = 1;
        }
        else {
          warnData.send_to_defaults = 0;
        }

        if (warnSiteContactStatus) {
          warnData.send_to_site_contact = 1;
        }
        else {
          warnData.send_to_site_contact = 0;
        }

        if (warnCustomEmailCheckStatus) {
          warnData.custom_mail_ids = document.getElementById('warn_custom_emails').value;
          warnData.reply_to_mail_ids = document.getElementById('warn_reply_to_emails').value;
          warnData.cc_mail_ids = document.getElementById('warn_cc_emails').value;
          warnData.bcc_mail_ids = document.getElementById('warn_bcc_emails').value;
        }
        else {
          warnData.custom_mail_ids = "";
          warnData.reply_to_mail_ids = "";
          warnData.cc_mail_ids = "";
          warnData.bcc_mail_ids = "";
        }

        if (warnFromMailCheckStatus) {
          warnData.from_email_id = document.getElementById('warn_from_email').value;
        }
        else {
          warnData.from_email_id = "";
        }

        settingData.push(warnData);
      }

      if (criticalRowCheckStatus) {
        const criticalData = {};
        criticalData.alert_type = 3;
        if (criticalDefaultEmailCheckStatus) {
          criticalData.send_to_defaults = 1;
        } else {
          criticalData.send_to_defaults = 0;
        }

        if (criticalSiteContactStatus) {
          criticalData.send_to_site_contact = 1;
        }
        else {
          criticalData.send_to_site_contact = 0;
        }

        if (criticalCustomEmailCheckStatus) {
          criticalData.custom_mail_ids = document.getElementById('critical_custom_emails').value;
          criticalData.reply_to_mail_ids = document.getElementById('critic_reply_to_emails').value;
          criticalData.cc_mail_ids = document.getElementById('critical_cc_emails').value;
          criticalData.bcc_mail_ids = document.getElementById('critical_bcc_emails').value;
        }
        else {
          criticalData.custom_mail_ids = "";
          criticalData.reply_to_mail_ids = "";
          criticalData.cc_mail_ids = "";
          criticalData.bcc_mail_ids = "";
        }

        if (criticalFromEmailCheckStatus) {
          criticalData.from_email_id = document.getElementById('critical_from_email').value;
        }
        else {
          criticalData.from_email_id = "";
        }

        settingData.push(criticalData);
      }
    }

    _UpdateAlertMailSettingsAPICall(settingData, setUpdateSuccessStatus)
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateSuccessStatus(false);
  };

  const _GetPresentSettings = async () => {
    await GetEmailConfig(setPresentSettings, setOpenBackdrop);
  }

  const resetAll = () => {

    setAllRowCheckStatus(false)
    setAllDefaultEmailCheckStatus(false)
    setAllSiteContactStatus(false)
    setAllCustomEmailCheckStatus(false)
    setAllFromEmailCheckStatus(false)
    setAllEmails("")
    setAllReplyEmails("")
    setAllFromEmail("")
    setAllCCEmails("")
    setAllBCCEmails("")

    setInfoRowCheckStatus(false)
    setInfoDefaultEmailCheckStatus(false)
    setInfoSiteContactStatus(false)
    setInfoCustomEmailCheckStatus(false)
    setInfoEmails("")
    setInfoReplyEmails("")
    setInfoFromEmail("")
    setInfoFromEmailCheckStatus(false)
    setInfoCCEmails("")
    setInfoBCCEmails("")

    setWarnRowCheckStatus(false)
    setWarnDefaultEmailCheckStatus(false)
    setWarnSiteContactStatus(false)
    setWarnCustomEmailCheckStatus(false)
    setWarnEmails("")
    setWarnReplyEmails("")
    setWarnFromEmail("")
    setWanFromMailCheckStatus(false)
    setWarnCCEmails("")
    setWarnBCCEmails("")

    setCriticalRowCheckStatus(false)
    setCriticalDefaultEmailCheckStatus(false)
    setCriticalSiteContactStatus(false)
    setCriticalCustomEmailCheckStatus(false)
    setCriticEmails("")
    setCriticReplyEmails("")
    setCriticalFromEmail("")
    setCriticalFromEmailCheckStatus(false)
    setCriticalCCEmails("")
    setCrtitcalBCCEmails("")

    setUpdateSuccessStatus(false)
    setPresentSettings([])

  }

  useEffect(() => {
    resetAll()
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      setOpenBackdrop(false);
      return;
    }
    _GetPresentSettings();
  }, [globalState])

  useEffect(() => {
    if (presentSettings.length > 0) {
      presentSettings.forEach((settings) => {
        if (settings.alert_type === 5) {
          setAllRowCheckStatus(true)
          if (settings.send_to_default === 1) {
            setAllDefaultEmailCheckStatus(true);
          }
          if (settings.send_to_site_contact === 1) {
            setAllSiteContactStatus(true);
          }
          if (settings.custom_mail_ids !== "" || settings.reply_to_mail_ids !== "") {
            setAllCustomEmailCheckStatus(true);
            setAllEmails(settings.custom_mail_ids)
            setAllReplyEmails(settings.reply_to_mail_ids)
            setAllCCEmails(settings.cc_mail_ids);
            setAllBCCEmails(settings.bcc_mail_ids);
          }
          if (settings.from_email !== "") {
            setAllFromEmailCheckStatus(true);
            setAllFromEmail(settings.from_email);
          }
        }
        if (settings.alert_type === 1) {
          setInfoRowCheckStatus(true)
          if (settings.send_to_default === 1) {
            setInfoDefaultEmailCheckStatus(true);
          }
          if (settings.send_to_site_contact === 1) {
            setInfoSiteContactStatus(true);
          }
          if (settings.custom_mail_ids !== "" || settings.reply_to_mail_ids !== "") {
            setInfoCustomEmailCheckStatus(true);
            setInfoEmails(settings.custom_mail_ids)
            setInfoReplyEmails(settings.reply_to_mail_ids)
            setInfoCCEmails(settings.cc_mail_ids);
            setInfoBCCEmails(settings.bcc_mail_ids);
          }

          if (settings.from_email !== "") {
            setInfoFromEmailCheckStatus(true)
            setInfoFromEmail(settings.from_email)
          }
        }
        if (settings.alert_type === 2) {
          setWarnRowCheckStatus(true)
          if (settings.send_to_default === 1) {
            setWarnDefaultEmailCheckStatus(true);
          }
          if (settings.send_to_site_contact === 1) {
            setWarnSiteContactStatus(true);
          }
          if (settings.custom_mail_ids !== "" || settings.reply_to_mail_ids !== "") {
            setWarnCustomEmailCheckStatus(true);
            setWarnEmails(settings.custom_mail_ids)
            setWarnReplyEmails(settings.reply_to_mail_ids)
            setWarnCCEmails(settings.cc_mail_ids);
            setWarnBCCEmails(settings.bcc_mail_ids);
          }
          if (settings.from_email !== "") {
            setWanFromMailCheckStatus(true)
            setWarnFromEmail(settings.from_email)
          }
        }
        if (settings.alert_type === 3) {
          setCriticalRowCheckStatus(true)
          if (settings.send_to_default === 1) {
            setCriticalDefaultEmailCheckStatus(true);
          }
          if (settings.send_to_site_contact === 1) {
            setCriticalSiteContactStatus(true);
          }
          if (settings.custom_mail_ids !== "" || settings.reply_to_mail_ids !== "") {
            setCriticalCustomEmailCheckStatus(true);
            setCriticEmails(settings.custom_mail_ids)
            setCriticReplyEmails(settings.reply_to_mail_ids)
            setCriticalCCEmails(settings.cc_mail_ids);
            setCrtitcalBCCEmails(settings.bcc_mail_ids);
          }

          if (settings.from_email !== "") {
            setCriticalFromEmailCheckStatus(true)
            setCriticalFromEmail(settings.from_email)
          }

        }
      })
    }
  }, [presentSettings])

  const onWarningClose = () => {
    setOpenWarnDialog(false);
  }

  const handleAllEmailChange = (e) => {
    setAllEmails(e.target.value);
  }

  const handleAllFromEmailChange = (e) => {
    setAllFromEmail(e.target.value);
  }

  const handleInfoEmailChange = (e) => {
    setInfoEmails(e.target.value);
  }

  const handleWarnEmailChange = (e) => {
    setWarnEmails(e.target.value);
  }

  const handleCriticEmailChange = (e) => {
    setCriticEmails(e.target.value)
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
        <Typography position='absolute'>Loading Settings</Typography>
      </Backdrop>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <Grid container>
            <Grid item xs={9} sm={9} md={9} lg={9} sx={{ px: 1, py: 1 }}>
              <div className={classes.titleStyle} >
                <Typography variant='h4'>
                  Email Settings
                </Typography>
              </div>
            </Grid>

            {(globalState !== custId || mspStatus !== 1) && <Grid item xs={3} sm={3} md={3} lg={3} sx={{ px: 2, py: 1 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className={classes.titleStyle}>
                <Button variant="contained" startIcon={<SaveIcon />} onClick={handleSaveSettings}>
                  Save Settings
                </Button>
              </div>
            </Grid>}
          </Grid>

          {(globalState !== custId || mspStatus !== 1) &&
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <AlertsEmailTableHead
                    headLabel={TABLE_HEAD}
                  />
                  <TableBody>
                    <TableRow hover>
                      <TableCell align="left">
                        <Checkbox id="allRowCheck" checked={allRowCheckStatus} onChange={handleAllClick} />
                      </TableCell>
                      <TableCell align="left"><strong>All</strong></TableCell>
                      <TableCell align="left">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox id="allDefaultEmail" checked={allDefaultEmailCheckStatus} onChange={handleAllClick} />
                            }
                            label="Send to Link Vendor Email"
                            sx={{ my: 1 }}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox id="allSiteContactStatusId" checked={allSiteContactStatus} onChange={handleAllClick} />
                            }
                            label="Send to Site Contact Mail"
                            sx={{ my: 1 }}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox id="allFromEmail" checked={allFromEmailCheckStatus} onChange={handleAllClick} />
                            }
                            label="From Email"
                            sx={{ my: 1 }}
                          />
                          {allFromEmailCheckStatus && <TextField
                            id="all_from_email"
                            label="From Email"
                            variant="standard"
                            value={allFromEmail}
                            onChange={(handleAllFromEmailChange)}
                          />}

                          <FormControlLabel
                            control={
                              <Checkbox id="allCustomEmailCheck" checked={allCustomEmailCheckStatus} onChange={handleAllClick} name="gilad" />
                            }
                            label="Add Custom Emails & Reply to Emails"
                            sx={{ my: 1, paddingTop: "10px" }}
                          />
                          {allCustomEmailCheckStatus && <TextField
                            id="all_custom_emails"
                            label="Custom Emails Here..."
                            variant="standard"
                            value={allEmails}
                            onChange={handleAllEmailChange}
                          />}

                          {allCustomEmailCheckStatus && <TextField
                            id="all_cc_emails"
                            label="CC Emails Here..."
                            variant="standard"
                            value={allCCEmails}
                            onChange={(e)=>{setAllCCEmails(e.target.value)}}
                            sx={{ my: 2 }}
                          />}

                          {allCustomEmailCheckStatus && <TextField
                            id="all_bcc_emails"
                            label="BCC Emails Here..."
                            variant="standard"
                            value={allBCCEmails}
                            onChange={(e)=>setAllBCCEmails(e.target.value)}
                            sx={{ my: 2 }}
                          />}
                          
                          {allCustomEmailCheckStatus && <TextField
                            id="all_reply_to_emails"
                            label="Reply To Emails Here..."
                            variant="standard"
                            value={allReplyEmails}
                            onChange={(e) => { setAllReplyEmails(e.target.value) }}
                            sx={{ my: 2 }}
                          />}
                        </FormGroup>
                      </TableCell>
                      <TableCell align="left">All Events</TableCell>
                    </TableRow>

                    {!allRowCheckStatus && <TableRow hover>
                      <TableCell align="left">
                        <Checkbox id="infoRowCheck" checked={infoRowCheckStatus} onChange={handleInfoClick} />
                      </TableCell>
                      <TableCell align="left">Info</TableCell>
                      <TableCell align="left">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox id="infoDefaultEmail" checked={infoDefaultEmailCheckStatus} onChange={handleInfoClick} />
                            }
                            label="Default Link Vendor Email"
                            sx={{ my: 1 }}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox id="infoSiteContactStatusId" checked={infoSiteContactStatus} onChange={handleInfoClick} />
                            }
                            label="Send to Site Contact Mail"
                            sx={{ my: 1 }}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox id="infoFromEmailId" checked={infoFromEmailCheckStatus} onChange={handleInfoClick} />
                            }
                            label="From Email"
                            sx={{ my: 1 }}
                          />
                          {infoFromEmailCheckStatus && <TextField
                            id="info_from_email"
                            label="From Email"
                            variant="standard"
                            value={infoFromEmail}
                            onChange={(e) => { setInfoFromEmail(e.target.value) }}
                          />}
                          <FormControlLabel
                            control={
                              <Checkbox id="infoCustomEmailCheck" checked={infoCustomEmailCheckStatus} onChange={handleInfoClick} name="gilad" />
                            }
                            label="Add Custom Emails"
                            sx={{ my: 1 }}
                          />
                          {infoCustomEmailCheckStatus && <TextField
                            id="info_custom_emails"
                            label="Custom Emails Here..."
                            variant="standard"
                            value={infoEmails}
                            onChange={handleInfoEmailChange}
                          />}

                          {infoCustomEmailCheckStatus && <TextField
                            id="info_cc_emails"
                            label="CC Emails Here..."
                            variant="standard"
                            value={infoCCEmails}
                            onChange={(e)=>{setInfoCCEmails(e.target.value)}}
                            sx={{ my: 2 }}
                          />}

                          {infoCustomEmailCheckStatus && <TextField
                            id="info_bcc_emails"
                            label="BCC Emails Here..."
                            variant="standard"
                            value={infoBCCEmails}
                            onChange={(e)=>setInfoBCCEmails(e.target.value)}
                            sx={{ my: 2 }}
                          />}

                          {infoCustomEmailCheckStatus && <TextField
                            id="info_reply_to_emails"
                            label="Reply To Emails Here..."
                            variant="standard"
                            value={infoReplyEmails}
                            onChange={(e) => { setInfoReplyEmails(e.target.value) }}
                            sx={{ my: 2 }}
                          />}
                        </FormGroup>
                      </TableCell>
                      <TableCell align="left">Link/Site Restoration</TableCell>
                    </TableRow>}
                    {!allRowCheckStatus && <TableRow hover>
                      <TableCell align="left">
                        <Checkbox id="warnRowCheck" checked={warnRowCheckStatus} onChange={handleWarnClick} />
                      </TableCell>
                      <TableCell align="left">Warning</TableCell>
                      <TableCell align="left">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox id="warnDefaultEmail" checked={warnDefaultEmailCheckStatus} onChange={handleWarnClick} value="support@decathlon.com" />
                            }
                            label="Default Link Vendor Email"
                            sx={{ my: 1 }}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox id="warnSiteContactStatusId" checked={warnSiteContactStatus} onChange={handleWarnClick} />
                            }
                            label="Send to Site Contact Mail"
                            sx={{ my: 1 }}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox id="warnFromMailId" checked={warnFromMailCheckStatus} onChange={handleWarnClick} />
                            }
                            label="From Email"
                            sx={{ my: 1 }}
                          />
                          {warnFromMailCheckStatus && <TextField
                            id="warn_from_email"
                            label="From Email Id here..."
                            variant="standard"
                            value={warnFromEmail}
                            onChange={(e) => { setWarnFromEmail(e.target.value) }}
                          />}


                          <FormControlLabel
                            control={
                              <Checkbox id="warnCustomEmailCheck" checked={warnCustomEmailCheckStatus} onChange={handleWarnClick} name="gilad" />
                            }
                            label="Add Custom Emails"
                            sx={{ my: 1 }}
                          />
                          {warnCustomEmailCheckStatus && <TextField
                            id="warn_custom_emails"
                            label="Custom Emails Here..."
                            variant="standard"
                            value={warnEmails}
                            onChange={handleWarnEmailChange}
                          />}

                          {warnCustomEmailCheckStatus && <TextField
                            id="warn_cc_emails"
                            label="CC Emails Here..."
                            variant="standard"
                            value={warnCCEmails}
                            onChange={(e)=>{setWarnCCEmails(e.target.value)}}
                            sx={{ my: 2 }}
                          />}

                          {warnCustomEmailCheckStatus && <TextField
                            id="warn_bcc_emails"
                            label="BCC Emails Here..."
                            variant="standard"
                            value={warnBCCEmails}
                            onChange={(e)=>setWarnBCCEmails(e.target.value)}
                            sx={{ my: 2 }}
                          />}

                          {warnCustomEmailCheckStatus && <TextField
                            id="warn_reply_to_emails"
                            label="Reply To Emails Here..."
                            variant="standard"
                            value={warnReplyEmails}
                            onChange={(e) => { setWarnReplyEmails(e.target.value) }}
                            sx={{ my: 2 }}
                          />}
                        </FormGroup>
                      </TableCell>
                      <TableCell align="left">Link Degradation alerts</TableCell>
                    </TableRow>}

                    {!allRowCheckStatus && <TableRow hover>
                      <TableCell align="left">
                        <Checkbox id="criticalRowCheck" checked={criticalRowCheckStatus} onChange={handleCriticalClick} />
                      </TableCell>
                      <TableCell align="left">Critical</TableCell>
                      <TableCell align="left">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox id="criticalDefaultEmail" checked={criticalDefaultEmailCheckStatus} onChange={handleCriticalClick} value="support@decathlon.com" />
                            }
                            label="Default Link Vendor Email"
                            sx={{ my: 1 }}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox id="criticalSiteContactStatusId" checked={criticalSiteContactStatus} onChange={handleCriticalClick} />
                            }
                            label="Send to Site Contact Mail"
                            sx={{ my: 1 }}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox id="criticalFromEmailId" checked={criticalFromEmailCheckStatus} onChange={handleCriticalClick} />
                            }
                            label="From Email"
                            sx={{ my: 1 }}
                          />
                          {criticalFromEmailCheckStatus && <TextField
                            id="critical_from_email"
                            label="From Email"
                            variant="standard"
                            value={criticalFromEmail}
                            onChange={(e) => { setCriticalFromEmail(e.target.value) }}
                          />}

                          <FormControlLabel
                            control={
                              <Checkbox id="criticalCustomEmailCheck" checked={criticalCustomEmailCheckStatus} onChange={handleCriticalClick} name="gilad" />
                            }
                            label="Add Custom Emails"
                            sx={{ my: 1 }}
                          />
                          {criticalCustomEmailCheckStatus && <TextField
                            id="critical_custom_emails"
                            label="Custom Emails Here..."
                            variant="standard"
                            value={criticEmails}
                            onChange={handleCriticEmailChange}
                          />}

                          {criticalCustomEmailCheckStatus && <TextField
                            id="critical_cc_emails"
                            label="CC Emails Here..."
                            variant="standard"
                            value={criticalCCEmails}
                            onChange={(e)=>{setCriticalCCEmails(e.target.value)}}
                            sx={{ my: 2 }}
                          />}

                          {criticalCustomEmailCheckStatus && <TextField
                            id="critical_bcc_emails"
                            label="BCC Emails Here..."
                            variant="standard"
                            value={criticalBCCEmails}
                            onChange={(e)=>setCrtitcalBCCEmails(e.target.value)}
                            sx={{ my: 2 }}
                          />}

                          {criticalCustomEmailCheckStatus && <TextField
                            id="critic_reply_to_emails"
                            label="Reply To Emails Here..."
                            variant="standard"
                            value={criticReplyEmails}
                            onChange={(e) => { setCriticReplyEmails(e.target.value) }}
                            sx={{ my: 2 }}
                          />}
                        </FormGroup>
                      </TableCell>
                      <TableCell align="left">All the link and site down alerts</TableCell>
                    </TableRow>}
                  
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          }

          <Snackbar open={updateSuccessStatus} autoHideDuration={6000} onClose={handleSnackClose} key={'bottom + right'}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              Settings Uploaded Successfully
            </Alert>
          </Snackbar>
          {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} onWarningClose={onWarningClose} />}
        </Paper>
      </Grid>
    </>
  );
}
