import axios from "axios"

const UpdateCustomTagApiCall = async (data, updateddata, setUpdateStatus, setUpdateMessage) => {

    const path = "update_custom_tags.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
    
    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
        "device_data": {
            // "tag_id": tag_id,
            "old_name": data.tags,
            "new_name": updateddata.tags,
            "entity": updateddata.entity,

            "is_mandatory": updateddata.is_mandatory,
            "default_value": updateddata.default_value
        }
    });
    await axios.post(baseUrl, payload).then((response) => {
        console.log("response.data", response.data)
        if (response.data.code === 1) {
            setUpdateStatus(response.data.code);
            setUpdateMessage(response.data.message);
        } else {
            setUpdateStatus(response.data.code);
            setUpdateMessage(response.data.message);
        }
    })
}
export default UpdateCustomTagApiCall