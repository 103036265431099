import axios from 'axios';

// API CALL CODE HERE
const PauseLinkApiCall = async (ispWanId, ispPublicIP, pauseStatus, setOpen) => {

const pauseBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/pause_isp_link.php`;


  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId,
    "link_ip": ispPublicIP,
    "pause_status": pauseStatus
  });
  return axios.post(pauseBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setOpen(true);
    }
    else{
      console.log("Error in Pausing isp");
    }
  }).catch(()=>{
    console.log("Error in Pausing isp");
  })
}

export default PauseLinkApiCall;
