// @mui
import {
    Box,
    TextField,
    Card,
    Backdrop,
    CircularProgress,
    Paper,
    Typography,
    Grid,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import ReactApexChart from 'react-apexcharts';
import merge from 'lodash/merge';
import { useState, useEffect, useRef } from 'react';
import 'react-tabs/style/react-tabs.css';
import _ from "lodash";
// components
import SidewiseSubCards from './SidewiseSubCards';
import { BaseOptionChart } from '../../chart';
import { ISPCard1RestCallForCard, ISPCard1RestCallForGraphCard, ISPCard1BandWidthData, ISPCard1RestCallForGraphCardByDate, ISPCard1BandWidthDataByDate } from "../../ISPManagementTable/ISPManagementDashboard/ISPCard1RestCall";
import timeArray from '../../ISPManagementTable/ISPManagementDashboard/TimeData';
import { useGlobalState } from '../../../globalHooks/GlobalState';


/* eslint-disable eqeqeq */

const useStyles = makeStyles({
    boxPadding: {
        textDecoration: 'none',
    },
    boxItems: {
        display: 'flex',
        alignItems: 'center',
    },
    titleStyle: {
        padding: 24,
    },
});

const legendStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1, // space between items
};

const iconStyle = {
    fontSize: '1.5rem', // Adjust the icon size
};

export default function LocationSingleISPData(props) {
    const ispWanId = props.ispWanId;
    const classes = useStyles();
    const { globalState, setGlobalState } = useGlobalState();
    const [value, setValue] = useState(0);
    const [ispParams, setIspParams] = useState({});
    const [jitterData, setJitterData] = useState([]);
    const [latencyData, setLatencyData] = useState([]);
    const [packetLossData, setPacketLossData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [upDownSeriesData, setUpDownSeriesData] = useState([]);

    const [timeData, setTimeData] = useState([]);
    const [statusTimeData, setStatusTimeData] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(true);

    const [upStatus, setUpStatus] = useState()
    const [goodStatus, setGoodStatus] = useState()
    const [textColor, setTextColor] = useState("");

    const [inBandWidthData, setInBandWidthData] = useState([]);
    const [outBandWidthData, setOutBandWidthData] = useState([]);
    const [bandWidthTimeData, setBandWidthTimeData] = useState([]);

    const [selectedDate, setSelectedDate] = useState(null);
    const [formattedDate, setFormattedDate] = useState("");


    const intervalRef = useRef();

    const handleTimeChange = (event, newTimeValue) => {
        intervalRef.time = newTimeValue;
        setOpenBackdrop(true);
        _ISPCard1RestCallForGraphCard();
        _ISPCard1BandWidthData();
        _ISPCard1RestCallForCard();
        setSelectedDate(null)
    };

    const handleDateChange = (date) => {
        intervalRef.time = -1;
        setSelectedDate(date);
        if (date) {
            const formattedDate = format(date, 'yyyy-MM-dd');
            setFormattedDate(formattedDate);
        }
    };

    useEffect(() => {
        setOpenBackdrop(true);
        if (formattedDate !== "") {
            _ISPCard1RestCallForGraphCardByDate();
            _ISPCard1BandWidthDataByDate();
        }
    }, [formattedDate])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const _ISPCard1RestCallForGraphCardByDate = async () => {
        await ISPCard1RestCallForGraphCardByDate(ispWanId, formattedDate, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop);
    }

    const _ISPCard1BandWidthDataByDate = async () => {
        await ISPCard1BandWidthDataByDate(ispWanId, formattedDate, setInBandWidthData, setOutBandWidthData, setBandWidthTimeData);
    }

    const _ISPCard1RestCallForCard = async () => {
        await ISPCard1RestCallForCard(ispWanId, setIspParams, setUpStatus, setGoodStatus);
    }

    const _ISPCard1BandWidthData = async () => {
        await ISPCard1BandWidthData(ispWanId, intervalRef.time, setInBandWidthData, setOutBandWidthData, setBandWidthTimeData);
    }

    useEffect(() => {
        intervalRef.time = timeArray[0].value;
        _ISPCard1RestCallForCard();
        // setInterval(() => {
        //     _ISPCard1RestCallForCard();
        // }, 60 * 1000);
    }, []);


    const _ISPCard1RestCallForGraphCard = async () => {
        ISPCard1RestCallForGraphCard(ispWanId, intervalRef.time, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop);
    }

    useEffect(() => {
        _ISPCard1RestCallForGraphCard();
        _ISPCard1BandWidthData();
        _ISPCard1RestCallForCard();
        // setInterval(() => {
        //     _ISPCard1RestCallForGraphCard();
        //     _ISPCard1RestCallForCard();
        //     _ISPCard1BandWidthData();
        // }, 60 * 1000);
    }, [globalState]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const calculateStats = (data) => {
        if (!data || data.length === 0) {
            return { min: 0, max: 0, mean: 0 };
          }
        const min = Math.min(...data);
        const max = Math.max(...data);
        const mean = data.reduce((acc, curr) => acc + curr, 0) / data.length;
        return { min, max, mean };
    };

    const state0 = [
        {
            name: 'Latency',
            type: 'area',
            fill: 'gradient',
            data: latencyData,
        },
        {
            name: 'Jitter',
            type: 'line',
            fill: 'solid',
            data: jitterData,
        },
    ];

    const chartLabels = timeData;

    const chartOptions = merge(BaseOptionChart(), {
        plotOptions: { bar: { columnWidth: '16%' } },
        fill: { type: state0.map((i) => i.fill) },
        labels: chartLabels,
        xaxis: {
            type: "time",
            tickAmount: 12
        },
        yaxis: {
            title: {
                text: 'milliseconds(ms)'
            },
        },
        legend: {
            show: false, // Turn off the default legend
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)} ms`;
                    }
                    return y;
                },
            },
        },
    });

    const state1 = {
        series: [
            {
                name: 'Packet Loss',
                data: packetLossData,
            },
        ],
        options:
        {
            chart: {
                height: 350,
                type: 'area',
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                type: 'time',
                tickAmount: 12,
                categories: timeData,
            },
            yaxis: {
                title: {
                    text: 'Packet Loss(%)'
                },
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm',
                },
            },
            legend: {
                show: false, // Turn off the default legend
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%",
                            height: "100%"
                        },
                        legend: {
                            show: false
                        }
                    }
                }
            ]
        },
    };

    const state2 = {
        series: [{
            name: "Status",
            data: statusData
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'stepline',
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0
                },
            },
            xaxis: {
                type: 'time',
                tickAmount: 12,
                categories: statusTimeData,
            },
            yaxis: {
                tickAmount: 1,
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%",
                            height: "100%"
                        },
                        legend: {
                            show: false
                        }
                    }
                }
            ]
        },
    };

    const state3 = [
        {
            name: 'Download',
            type: 'line',
            fill: 'solid',
            data: inBandWidthData,
        }, {
            name: 'Upload',
            type: 'area',
            fill: 'gradient',
            data: outBandWidthData,
        },
    ];

    const barState = {
        series: upDownSeriesData,
        options: {
            chart: {
                type: 'bar',
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {}
                },
            },
            title: {
                text: '',
            },
            xaxis: {
                categories: [''],
                labels: {
                    show: false,
                },
            },
            yaxis: {
                title: {
                    text: undefined,
                },
                labels: {},
            },
            tooltip: {
                y: {
                    formatter: (val) => `${val} minutes`,
                },
                labels: {
                    show: false,
                },
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40,
                show: false,
            },
        },
    };

    const chartLabels3 = bandWidthTimeData;
    const chartOptions3 = merge(BaseOptionChart(), {
        fill: { type: state3.map((i) => i.fill) },
        labels: chartLabels3,
        xaxis: {
            type: 'time',
            tickAmount: 12
        },
        yaxis: {
            title: {
                text: "Utilization Mbps",
            },
        },
        legend: {
            show: false, // Turn off the default legend
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(4)} Mbps`;
                    }
                    return y;
                },
            },
        },
    });

    useEffect(() => {

        if (upStatus && goodStatus) {
            setTextColor("green");
        }
        else if (upStatus && !goodStatus) {
            setTextColor("#FFA500");
        }
        else {
            setTextColor("red");
        }
    }, [ispParams])

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
                <Typography position='absolute'><strong>Loading.....</strong></Typography>
            </Backdrop>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper elevation={15} sx={{ mt: 2 }}>
                    <div className={classes.titleStyle}>
                        <Typography>
                            {ispParams.vendor} : {ispParams.public_ip}
                        </Typography>
                    </div>

                    <SidewiseSubCards ispParams={ispParams} upStatus={upStatus} goodStatus={goodStatus} />
                    <div className={classes.titleStyle}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Select Time</FormLabel>
                        <Grid container>
                            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={intervalRef.time ? intervalRef.time : timeArray[0].value}
                                    onChange={handleTimeChange}
                                >

                                    {timeArray.map((data, index) =>
                                    (
                                        <FormControlLabel value={data.value} control={<Radio />} label={data.label} />
                                    )
                                    )}
                                    <FormControlLabel style={{ display: 'none' }} value={"-1"} control={<Radio />} label={"Custom Date"} disabled />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Select Date"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container spacing={3} sx={{ px: 2, py: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: 2, py: 2 }}>
                            <Card variant="outlined" elevation={15}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Latency/jitter" />
                                    <Tab label="Packet loss" />
                                    <Tab label="Up Time" />
                                    <Tab label="Utilization" />
                                </Tabs>
                                <TabPanel value={value} index={0} style={{ height: '420px' }}>
                                    <Box paddingX={2}>
                                        <ReactApexChart type="line" series={state0} options={chartOptions} height={350} />
                                        <Box sx={legendStyle}>
                                            <Box sx={legendStyle}>
                                                <FiberManualRecordIcon sx={{ ...iconStyle, color: 'blue' }} />
                                                <Typography>Latency (Min: {calculateStats(latencyData).min} ms, Max: {calculateStats(latencyData).max} ms, Avg: {calculateStats(latencyData).mean.toFixed(0)} ms)</Typography>
                                            </Box>

                                            <Box sx={legendStyle}>
                                                <FiberManualRecordIcon sx={{ ...iconStyle, color: '#FFEA00' }} />
                                                <Typography>Jitter (Min: {calculateStats(jitterData).min} ms, Max: {calculateStats(jitterData).max} ms, Avg: {calculateStats(jitterData).mean.toFixed(0)} ms)</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={1} style={{ height: '420px' }}>
                                    <Box paddingX={2}>
                                        <ReactApexChart options={state1.options} series={state1.series} type="area" height={350} />
                                        <Box sx={legendStyle}>
                                            <Box sx={legendStyle}>
                                                <FiberManualRecordIcon sx={{ ...iconStyle, color: 'blue' }} />
                                                <Typography>Packet Loss (Min: {calculateStats(packetLossData).min} %, Max: {calculateStats(packetLossData).max} %, Avg: {calculateStats(packetLossData).mean.toFixed(0)} %)</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={2} style={{ height: '420px' }}>
                                    <Box paddingX={2}>
                                        <ReactApexChart options={barState.options} series={barState.series} type="bar" height={350} />
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={3} style={{ height: '420px' }}>
                                    <Box paddingX={2}>
                                        <ReactApexChart type="line" series={state3} options={chartOptions3} height={350} />
                                        <Box sx={legendStyle}>
                                            <Box sx={legendStyle}>
                                                <FiberManualRecordIcon sx={{ ...iconStyle, color: 'blue' }} />
                                                <Typography>Download (Min: {calculateStats(inBandWidthData).min} Mbps, Max: {calculateStats(inBandWidthData).max} Mbps, Avg: {calculateStats(inBandWidthData).mean.toFixed(0)} Mbps)</Typography>
                                            </Box>

                                            <Box sx={legendStyle}>
                                                <FiberManualRecordIcon sx={{ ...iconStyle, color: '#FFEA00' }} />
                                                <Typography>Upload (Min: {calculateStats(outBandWidthData).min} Mbps, Max: {calculateStats(outBandWidthData).max} Mbps, Avg: {calculateStats(outBandWidthData).mean.toFixed(0)} Mbps)</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </TabPanel>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
}
