import React, { useEffect, useState } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, TextField, FormHelperText, FormControlLabel, Divider, DialogContentText, FormLabel, Radio, RadioGroup, Snackbar, Alert } from '@mui/material';
import { useGlobalState } from '../../../globalHooks/GlobalState';
// import { EdgeDeviceCustumTagApiCall } from './EdgeDeviceCustumTagApiCall';
import UpdateCustomTagApiCall from './UpdateCustomTagApiCall';

// {openUpdateDialog && <UpdateCustomTag data={data} openUpdateDialog={openUpdateDialog} setOpenUpdateDialog={setOpenUpdateDialog} setUpdateStatus={setUpdateStatus} setUpdateMessage={setUpdateMessage} />}

export default function UpdateCustomTagDialog(props) {
    const {data,  openUpdateDialog,  setOpenUpdateDialog, setUpdateStatus, setUpdateMessage } = props;
    const { globalState, setGlobalState } = useGlobalState();
    const [isMandatory, setIsMandatory] = useState(data?.is_mandatory === "Yes" ? 2 : 3);
    const [Name, setName] = useState(data.tags || "");
    const [selectedEntity, setSelectedEntity] = useState(data.entity || "Device");
    const [defaultValue, setDefaultValue] = useState(data.default_value || "");
    const [isMandatoryHelperText, setIsMandatoryHelperText] = useState('');
    const [NameHelperText, setNameHelperText] = useState("");
    const [selectedEntityHelperText, setSelectedEntityHelperText] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState('');
    const [error, setError] = useState("");

    useEffect(() => {
        
        if (data) {
            setIsMandatory(data.is_mandatory === "Yes" ? 2 : 3);
            setName(data.tags || "");
            setSelectedEntity(data.entity || "Device");
            setDefaultValue(data.default_value || "");
        }
    }, [data, openUpdateDialog]);
    
    

    const handleChange = (e) => {
        const { id, value } = e.target;

        switch (id) {
            case "name-input":
                setName(value);
                if (value.trim() === "") {
                    setNameHelperText("Name is required");
                } else {
                    setNameHelperText("");
                }
                break;

            case "entity-select":
                setSelectedEntity(value);
                if (value) {
                    setError(false);
                    setSelectedEntityHelperText("");
                } else {
                    setError(true);
                    setSelectedEntityHelperText("Please select an entity");
                }
                break;

            default:
                break;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (Name === "") {
            setNameHelperText("Name is required");
            return;
        }

        if (selectedEntity === "") {
            setError(true);
            setSelectedEntityHelperText("Entity is required");
            return;
        }

        const updateddata = {
            tag_id: data.id, // assuming 'id' is the identifier for the tag
            entity: selectedEntity,
            tags: Name,
            is_mandatory: isMandatory === 2 ? "Yes" : "No",
            default_value: defaultValue || null,
        };
console.log("updatedData=",updateddata);
         await UpdateCustomTagApiCall(data, updateddata, setUpdateStatus, setUpdateMessage);
        setOpenUpdateDialog(false);
    };

    return (
        <>
            <Dialog
                open={ openUpdateDialog}
                onClose={() => {  setOpenUpdateDialog(false) }}
                fullWidth
                maxWidth="lg"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Update Custom Tag

                    <IconButton onClick={() => {  setOpenUpdateDialog(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <form>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1, px: 12 }}>
                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120 }} error={error}>
                                <InputLabel id="entity-select-label">Entity</InputLabel>
                                <Select
                                    labelId="entity-select-label"
                                    id="entity-select"
                                    value={selectedEntity} 
                                    style={{ width: "400px" }}
                                    label="Entity"
                                    onChange={handleChange}
                                    disabled
                                >
                                    <MenuItem value="Device">Device</MenuItem>
                                    <MenuItem value="Link">Link</MenuItem>
                                    <MenuItem value="Site">Site</MenuItem>
                                    <MenuItem value="Vendor">Vendor</MenuItem>
                                </Select>
                                {error && <Typography variant="caption" color="error">{selectedEntityHelperText}</Typography>}  
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <TextField
                                    id="name-input"
                                    label="Name"
                                    variant="outlined"
                                    value={Name}
                                    placeholder="Enter Name"
                                    onChange={handleChange}
                                    fullWidth
                                    style={{ width: "400px" }}
                                    helperText={NameHelperText}
                                    error={!!NameHelperText}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl component="fieldset" sx={{ m: 1, minWidth: 120, px: 1 }}>
                                <FormLabel component="legend">Is Mandatory</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="isMandatory"
                                    name="is-Mandatory"
                                    value={isMandatory}
                                    onChange={(e) => setIsMandatory(Number(e.target.value))}
                                >
                                    <FormControlLabel value={2} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={3} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <TextField
                                    id="Default-Value"
                                    label="Default Value (optional)"
                                    variant="outlined"
                                    value={defaultValue}
                                    placeholder="Enter Value"
                                    onChange={(e) => setDefaultValue(e.target.value)}
                                    fullWidth
                                    style={{ width: "400px" }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
                <DialogActions>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Update
                    </Button>
                    <Button onClick={() => {  setOpenUpdateDialog(false); }} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
