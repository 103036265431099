import * as React from 'react';
import {useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';

function createData(linkName, siteName, ipAddress, avgUptime, avgTimeToRestore, slaBreaches, businessHourImpact) {
  return {
    linkName,
    siteName,
    ipAddress,
    avgUptime,
    avgTimeToRestore,
    slaBreaches,
    businessHourImpact,
  };
}

const rows = [
  createData('TE010132', 'Amritsar City', '	103.17.96.22', '6d 20h 22m 45s', '1hr', 0, 'No Impact'),
  createData('TE010132', 'Amritsar City', '	103.17.96.22', '6d 20h 22m 45s', '1hr', 0, 'No Impact'),
  createData('TE010132', 'Amritsar City', '	103.17.96.22', '6d 20h 22m 45s', '1hr', 0, 'No Impact'),
  createData('TE010132', 'Amritsar City', '	103.17.96.22', '6d 20h 22m 45s', '1hr', 0, 'No Impact'),
  createData('TE010132', 'Amritsar City', '	103.17.96.22', '6d 20h 22m 45s', '1hr', 0, 'No Impact'),
  createData('TE010132', 'Amritsar City', '	103.17.96.22', '6d 20h 22m 45s', '1hr', 0, 'No Impact'),
  createData('TE010132', 'Amritsar City', '	103.17.96.22', '6d 20h 22m 45s', '1hr', 0, 'No Impact'),
  createData('TE010132', 'Amritsar City', '	103.17.96.22', '6d 20h 22m 45s', '1hr', 0, 'No Impact'),
  createData('TE010132', 'Amritsar City', '	103.17.96.22', '6d 20h 22m 45s', '1hr', 0, 'No Impact'),
  createData('TE010132', 'Amritsar City', '	103.17.96.22', '6d 20h 22m 45s', '1hr', 0, 'No Impact'),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  
  {
    id: 'vendorName',
    numeric: false,
    disablePadding: false,
    label: 'Vendor Name',
  },
  
  {
    id: 'linkName',
    numeric: false,
    disablePadding: false,
    label: 'Circuit ID',
  },
  {
    id: 'siteName',
    numeric: false,
    disablePadding: false,
    label: 'Site Name',
  },
  {
    id: 'ipAddress',
    numeric: false,
    disablePadding: false,
    label: 'IP Address',
  },
  {
    id: 'avgUptime',
    numeric: false,
    disablePadding: false,
    label: 'Avg Uptime',
  },
  {
    id: 'downTime',
    numeric: false,
    disablePadding: false,
    label: 'Down Time',
  },
  {
    id: 'slaBreaches',
    numeric: true,
    disablePadding: false,
    label: 'SLA Breaches',
  },
  {
    id: 'businessHourImpact',
    numeric: false,
    disablePadding: false,
    label: 'Business Hour Impact',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const [openWarnDialog, setOpenWarnDialog] = useState(false);

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: '#f0f0f0', }}>
        <TableCell sx={{ mx: 1 }}>
          Sl.No
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Links Report
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function HolisticLinkView(props) {
  const { globalState, setGlobalState } = useGlobalState();

  const { bestLinks } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // useEffect(() => {
  //   if (globalState === custId && mspStatus === 1) {
  //     setOpenWarnDialog(true);
  //     return;
  //   }
  // }, [globalState])
  const convertMinToHourMinutes = (minutes) => {
    const d = Math.round(Math.floor(minutes / (24 * 60)));
    const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
    const m = Math.round((minutes % (24 * 60)) % 60);
    // d = d < 10 ? `0${d}` : d;
    // h = h < 10 ? `0${h}` : h;
    // m = m < 10 ? `0${m}` : m;
    return `${d}days, ${h}hours, ${m}minutes`;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={bestLinks.length}
            />
            <TableBody>
              {stableSort(bestLinks, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell sx={{ mx: 1 }}>{index + 1}</TableCell>
                      <TableCell align="left">{row.vendor}</TableCell>
                      <TableCell align="left">{row.circuit_id}</TableCell>
                      <TableCell align="left">{row.location}</TableCell>
                      <TableCell align="left">{row.public_ip}</TableCell>
                      <TableCell align="left">{row.avgUptime}%</TableCell>
                      <TableCell align="left">{convertMinToHourMinutes(row.downTime)}</TableCell>
                      <TableCell align="left">{row.slaBreaches}</TableCell>
                      <TableCell align="left">{convertMinToHourMinutes(row.businessHourMetrics)}</TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
          </Table>
        </TableContainer>

      </Paper>
    </Box>
  );
}