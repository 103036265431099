import axios from 'axios';

const getCustomTagsApiCall = async (setRows, setFilteredRows, setCustomTag) => {
    const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_custom_tags.php`;

    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
    });

    try {
        const response = await axios.post(createBaseURL, payload);

        if (response.data.code === 1) {
            

            setRows(response.data.data);
            setFilteredRows(response.data.data);
            
            const data = response.data.data 
            if (Array.isArray(data)) {
                const customTag = data.map(item => ({
                    entity: item.entity,
                    tags: item.tags,
                    is_mandatory: item.is_mandatory,
                    default_value: item.default_value,
                }));

                setCustomTag(customTag);
            } else {
                
                setCustomTag([]);
            }
        } else {
            setRows([]);
            setFilteredRows([]);
            setCustomTag([]);
        }
    } catch (error) {
        console.error('Error fetching custom tags:', error);
        setRows([]);
        setFilteredRows([]);
        setCustomTag([]);
    }
};

export default getCustomTagsApiCall;
