import axios from "axios";

const UpdateUtilizationConfigApiCall = async (updateData, setUpdateUtilizationStatus, setUpdateUtilizationMessage) => {

    

    const path = localStorage.getItem("msp_status") === "1" ? "update_utilization_threshold_values_for_msp.php" : "update_utilization_threshold_values.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    
    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
        "data": {
        "location_id": updateData.locationId,        
        "critical_th_value": updateData.criticalThreshold, 
        "warning_th_value": updateData.warningThreshold  
    }
});

const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_cust_id": localStorage.getItem("cust_id"),
    "reg_cust_id": localStorage.getItem("reg_cust_id"),
    "data": {
        "location_id": updateData.locationId,        
        "critical_th_value": updateData.criticalThreshold, 
        "warning_th_value": updateData.warningThreshold  
    }

});

const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrl, payload).then((response) => {
        console.log("response.data", response.data)
        if (response.data.code === 1) {
            setUpdateUtilizationStatus(response.data.code);
            setUpdateUtilizationMessage(response.data.message);
        } else {
            setUpdateUtilizationStatus(response.data.code);
            setUpdateUtilizationMessage(response.data.message);
        }
    })
}
export default UpdateUtilizationConfigApiCall;