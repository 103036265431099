import React, { useEffect, useState } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, Box, TextField, FormHelperText, FormControlLabel, Divider, DialogContentText, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useGlobalState } from '../../../globalHooks/GlobalState';
// import Api call
import GetLocationsApiCall from './GetLocationsApiCall';
import { AddUtilizationApiCall } from './AddUtilizationApiCall';


export default function AddUtilizationConfigDialog(props) {
    const { openAddUtilization, setOpenAddUtilization, setAddUtilizationStatus, setAddUtilizationMessage } = props;

    const [error, setError] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [disableDropdown, setDisableDropdown] = useState(true);
    const [selectOptionHelperText, setSelectOptionHelperText] = useState("")
    const [dependent, setDependent] = useState([]);
    const [locations, setLocations] = useState([]);
    const [dependentHelperText, setDependentHelperText] = useState('');
    const [selectedLocationIds, setSelectedLocationIds] = useState([]);
    const [warning, setWarning] = useState("");
    const [warningHelperText, setWarningHelperText] = useState("")
    const [critical, setCritical] = useState("");
    const [criticalHelperText, setCriticalHelperText] = useState("")
    const [locationId, setLocationId] = useState([]);
    // const numericPattern = /^(\d+(\.\d+)?)$/;
    const numericPattern = /^(100(\.0{1,2})?|[0-9]?[0-9](\.[0-9]{1,2})?)$/;
 


    const handleChange = (event) => {
        const { value } = event.target;
        setDependent(value);
        if (selectedOption && selectedOption !== "All") {
            if (value.length === 0) {
                setError(true);
                setDependentHelperText(`Please select at least one ${selectedOption}`);
            } else {
                setError(false);
                setDependentHelperText('');
            }
        }


        if (selectedOption === "Location") {
            const selectedLocationIdsArray = locations
                .filter((location) => value.includes(location.location_name))
                .map((location) => location.location_id);

            setSelectedLocationIds(selectedLocationIdsArray);
        }
    }
    
    // useEffect(() => {
        
    //     if (selectedOption === 'Location') {
    //         GetLocationsApiCall((data) => {
    //             console.log("Fetched Locations:", data); // Log data
    //             setLocations(data);
    //         });
    //     }

    //     if (selectedOption !== 'Location') {
    //         console.log("herere",locations);
    //         const locationIds = locations.map(location => location.location_id)
    //         setSelectedLocationIds(locationIds)
    //     }

    // }, [selectedOption]);

    const validateNumber = (value) => numericPattern.test(value);

    const handleSubmit = async (event) => {

        if (selectedOption === "") {
            setError(true);
            setSelectOptionHelperText("Please select at least one option");
            return;
        }
        if (selectedOption && selectedOption !== "All" && dependent.length === 0) {
            setError(true);
            setDependentHelperText(`Please select at least one ${selectedOption}`);
            return;
        }
        if (!validateNumber(warning)) {
            setWarningHelperText("Warning Threshold must be a valid number");
            return;
        }

        // Validate Critical Threshold
        if (!validateNumber(critical)) {
            setCriticalHelperText("Critical Threshold must be a valid number");
            return;
        }
        //   const data = {
        //     selectedOption,
        //     dependent: selectedOption === "Location" ? selectedLocationIds : dependent,
        //     warningThreshold: warning,
        //     criticalThreshold: critical,
        // };
        // Prepare data to be sent in API call
        let locationIds;

        if (selectedOption === "All") {
            locationIds = locations.map(location => location.location_id);
            setSelectedLocationIds(locationIds)
        } else if (selectedOption === "Location") {
            locationIds = selectedLocationIds;
        } else {
            locationIds = dependent;
        }

        const data = {
            selectedOption,
            locationId: locationIds,
            warningThreshold: warning,
            criticalThreshold: critical,
        };

        console.log("data",data);
        
        await AddUtilizationApiCall(data, setAddUtilizationStatus, setAddUtilizationMessage);

        setOpenAddUtilization(false);
        setSelectedOption("");
        setCritical("");
        setWarning("")
    };

useEffect(() => {
    if (openAddUtilization) {
        GetLocationsApiCall((data) => {
            setLocations(data);
        });
    }
}, [openAddUtilization]); 

    return (
        <>
            <Dialog
                open={openAddUtilization}
                onClose={() => { setOpenAddUtilization(false) }}
                fullWidth
                maxWidth="lg"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Utilization Config

                    <IconButton onClick={() => { setOpenAddUtilization(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>


                <form >

                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{  px: 12 }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth error={error}>
                                <InputLabel id="UtilizationLocation">Select Option</InputLabel>
                                <Select
                                    labelId="UtilizationLocation"
                                    id="UtilizationLocation"
                                    value={selectedOption}
                                    onChange={(e) => {
                                        if (e.target.value !== "All") {
                                            setDisableDropdown(false)
                                        } else {
                                            setDisableDropdown(true)
                                        }
                                        setSelectedOption(e.target.value)
                                        if (e.target.value !== "") {
                                            setError(false);
                                            setSelectOptionHelperText('');
                                        }
                                    }}
                                    label="Select Option"
                                    style={{ width: "400px" }}
                                >
                                    <MenuItem value="All">All</MenuItem>

                                    <MenuItem value="Location">Location</MenuItem>
                                </Select>
                                {error && <Typography variant="caption" color="error">{selectOptionHelperText}</Typography>}
                            </FormControl>
                        </Grid>


                        <Grid item xs={6}>
                            <FormControl fullWidth variant="outlined" error={error}>
                                <InputLabel id="dependent-dropdown-label">
                                    {selectedOption ? `Select ${selectedOption}` : "Select Location"}
                                </InputLabel>
                                <Select
                                    labelId="dependent-dropdown-label"
                                    id="dependentDropdown"
                                    multiple
                                    value={dependent}
                                    onChange={handleChange}
                                    label={selectedOption ? `Select ${selectedOption}` : "List"}
                                    style={{ width: "400px" }}
                                    disabled={disableDropdown}

                                >
                                    {selectedOption === "Location" &&
                                        locations?.map((location) => (
                                            <MenuItem key={location?.location_id} value={location?.location_name}>
                                                {location?.location_name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                {error && <Typography variant="caption" color="error">{dependentHelperText}</Typography>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl component="fieldset" sx={{ m: 1, minWidth: 120 ,marginLeft: '-1px'}}>
                                <TextField
                                    id="Waring-Thresh"
                                    label="Warning Threshold"
                                    variant="outlined"
                                    value={warning}
                                    placeholder="Enter Warning Threshold(in %)"
                                    onChange={(e) => {
                                        setWarningHelperText("");
                                        setWarning(e.target.value)
                                    }
                                    }
                                    fullWidth
                                    style={{ width: "400px" }}
                                    helperText={warningHelperText}
                                    error={!!warningHelperText}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120,marginLeft: '-1px' }}>
                                <TextField
                                    id="Critical-Thresh"
                                    label="Critical Threshold"
                                    variant="outlined"
                                    value={critical}
                                    placeholder="Enter Critical Threshold(in %)"
                                    onChange={(e) => {
                                        setCriticalHelperText("");
                                        setCritical(e.target.value)
                                    }
                                    }
                                    fullWidth
                                    style={{ width: "400px" }}
                                    helperText={criticalHelperText}
                                    error={!!criticalHelperText}

                                />
                            </FormControl>
                        </Grid>

                    </Grid>

                </form>

                <DialogActions>
                    <Button onClick={() => { handleSubmit(); }} variant="contained" color="primary">
                        Add
                    </Button>
                    <Button onClick={() => { setOpenAddUtilization(false); }} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    )


}