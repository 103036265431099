import axios from 'axios';

// API CALL CODE HERE
const EditVendorApiCall = async (vendorId, vendorName, vendorAddress, vendorContact, helpDeskEmail, escalationLevel1Mail, escalationLevel2Mail, vendorCity, promisedUptime, resolutionTime, setEditVendorStatus, setEditVendorMessage) => {
  
  const path = localStorage.getItem("msp_status") === "1" ? "update_vendor_details_for_msp.php" : "update_vendor_details.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "vendor_data": {
      "vendor_id": vendorId,
      "vendor_name": vendorName,
      "vendor_address": vendorAddress,
      "vendor_contact": vendorContact,
      "helpdesk_email": helpDeskEmail,
      "escalation_level1_mail": escalationLevel1Mail,
      "escalation_level2_mail": escalationLevel2Mail,
      "vendor_city": vendorCity,
      "promised_uptime": promisedUptime,
      "resolution_time": resolutionTime
    }
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "vendor_data": {
      "vendor_id": vendorId,
      "vendor_name": vendorName,
      "vendor_address": vendorAddress,
      "vendor_contact": vendorContact,
      "helpdesk_email": helpDeskEmail,
      "escalation_level1_mail": escalationLevel1Mail,
      "escalation_level2_mail": escalationLevel2Mail,
      "vendor_city": vendorCity,
      "promised_uptime": promisedUptime,
      "resolution_time": resolutionTime
    }
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
        setEditVendorStatus(1);
        setEditVendorMessage(response.data.message)
    }
    else {
        setEditVendorStatus(-1);
        setEditVendorMessage(response.data.message)
    }
  }).catch(() => {
    setEditVendorStatus(-1);
  })
}

export default EditVendorApiCall;
