import axios from "axios"


function transformData(data) {
  const result = [];

  data.forEach(item => {
    const existing = result.find(r => r.location_id === item.location_id);

    if (existing) {
      existing.device_ip.push(item.device_ip);
    } else {
      const newItem = { ...item, device_ip: [item.device_ip] };
      result.push(newItem);
    }
  });

  return result;
}

const GetLocationsByClientApi = async (setLocations, setFilteredRows, buState) => {
  if (localStorage.getItem("user_map_status") === "1") {
    const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_locations_by_client_for_mapped_user.php`;
    const regPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("reg_cust_id"),
      "bu_id" :buState
    });
    return axios.post(mappedUrl, regPayload).then((response) => {
      if (response.data.code === 1) {
        const transformed = transformData(response.data.location_data);
        setLocations(transformed);
        setFilteredRows(transformed);
      }

    });
  }
  if (localStorage.getItem("user_map_status") === "-1") {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
      const url = `${process.env.REACT_APP_API_SERVER_URL}/get_locations_by_client_for_bu.php`;
      const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "bu_id": buState
      });

      return axios.post(url, payload).then((response) => {
        if (response.data.code === 1) {
          const transformed = transformData(response.data.location_data);
          setLocations(transformed);
          setFilteredRows(transformed);
        } else {
          setLocations([]);
          setFilteredRows([]);
        }
      })
    }
    if (localStorage.getItem("bu_status") === "-1" || buState === -1) {
      const path = localStorage.getItem("msp_status") === "1" ? "get_locations_by_client_for_msp.php" : "get_locations_by_client.php";
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id")
      });

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id")
      });
      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

      return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
          const transformed = transformData(response.data.location_data);
          setLocations(transformed);
          setFilteredRows(transformed);
        } else {
          setLocations([]);
          setFilteredRows([]);
        }
      })
    }
  }
}
export default GetLocationsByClientApi