import axios from 'axios';


export const ForgotPasswordRestAPICall = async (email, setErrorMessage) => {

const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/send_reset_link.php`;

  const payload = JSON.stringify({
    "email": email,
  });
  try {
    const response = await axios.post(baseUrl, payload);
    if (response.data.code === 1) {
      // localStorage.setItem("email", response.data.email);
      setErrorMessage("Reset Link Sent to mail. Please check")
      return response.data;
    }
    if (response.data.code === -1) {
      setErrorMessage("Attempt exceeded 3 times.. Try after 24Hrs/Contact Admin")
    }
    if (response.data.code === 0) {
      setErrorMessage("Reset Link Sent to mail. Please check")
    }
  } catch (error) {
    throw new Error(error.message || 'Forgot Password Failed: network related');
  }
}