import axios from 'axios';

const GetLocationsApiCall = async (setLocations) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_locations_by_client.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
  });

  try {
    const response = await axios.post(baseUrl, payload);
    console.log("API Response:", response.data);
    if (response.data.code === 1) {
      const locationMap = {};

      response.data.location_data.forEach((location) => {
      if (!locationMap[location.location_id]) {
        locationMap[location.location_id] = {
          location_id: location.location_id,
          location_name: location.location_name,
        };
      }
      setLocations(Object.values(locationMap))
    });
    } else {
      console.error("Failed to fetch locations:", response.data.message);
    }
  } catch (error) {
    console.error("Error in fetching locations:", error);
  }
};

export default GetLocationsApiCall;
