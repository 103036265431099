import axios from "axios"

const PauseLocationApiCall = async (locationId, locationName, pauseValue, setPauseStatus, setOpenPauseSnack, setSeverStatus) => {
    const path = localStorage.getItem("msp_status") === "1" ? "pause_location_for_msp.php" : "pause_location.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "location_id": locationId,
        "location_name": locationName,
        "pause_status": pauseValue
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "location_id": locationId,
        "location_name": locationName,
        "pause_status": pauseValue
    });
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setPauseStatus(1);
            setSeverStatus("success");
        }
        else {
            setPauseStatus(-1);
            setSeverStatus("error");
        }
        setOpenPauseSnack(true);
    })

}

export default PauseLocationApiCall