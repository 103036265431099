import React, { useState, useEffect } from 'react';
import {
    Divider,
    Typography,
    Grid
} from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FileBrowser, FileList, ChonkyActions, } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

import ReportFilesAPiCall from "./ReportFilesAPiCall"
import DownloadReportApiCall from './DownloadReportApiCall';

const FileSystemTreeView = ({ monthYear }) => {
    const [currentFiles, setCurrentFiles] = useState([]);
    const [directoryStack, setDirectoryStack] = useState([]);
    const [dirName, setDirName] = useState("");
    const [fileName, setFileName] = useState("")

    useEffect(() => {
        ReportFilesAPiCall(monthYear, setCurrentFiles);
        // Disable Chonky debug logs
        localStorage.debug = 'chonky:*,-chonky:debug';
    }, []);

    const handleFileAction = (action) => {
        if (action.id === ChonkyActions.OpenFiles.id) {
            const file = action.payload.targetFile;
            if (file && file.isDir) {
                setDirectoryStack(stack => [...stack, currentFiles]);
                setCurrentFiles(file.children || []);
                setDirName(file.name);
            }
            else {
                // console.log('Download');
                const path = `${dirName}/${file.name}`;
                console.log(path)        
                DownloadReportApiCall(path, file.name)
            }
        }
    };

    const handleBackClick = () => {
        if (directoryStack.length > 0) {
            const lastDirectory = directoryStack.pop();
            setCurrentFiles(lastDirectory);
        }
    };

    return (
        <div>
            <div style={{ height: 40 }}>
                {directoryStack.length > 0 && (
                    <Grid container>
                        <Grid item>
                            <IconButton onClick={handleBackClick} color='primary' aria-label="go back">
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} sx={{ px: 1, py: "12px" }}>
                            <Typography style={{ textAlign: 'left' }}>{dirName}</Typography>
                        </Grid>
                    </Grid>
                )}
            </div>
            <Divider />
            <div style={{ height: 400, paddingTop: "10px" }}>
                <FileBrowser
                    files={currentFiles}
                    iconComponent={ChonkyIconFA}
                    view={'grid'}
                    onFileAction={handleFileAction}

                >
                    <FileList />
                </FileBrowser>
            </div>
        </div>
    );
};

export default FileSystemTreeView;
